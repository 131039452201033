import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Mails from "@iso/redux/mail/reducer";
import Calendar from "@iso/redux/calendar/reducer";
import Box from "@iso/redux/box/reducer";
import Notes from "@iso/redux/notes/reducer";
import Todos from "@iso/redux/todos/reducer";
import Cards from "@iso/redux/card/reducer";
import Chat from "@iso/redux/chat/reducers";
import DynamicChartComponent from "@iso/redux/dynamicEchart/reducer";
import Ecommerce from "@iso/redux/ecommerce/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import Invoices from "@iso/redux/invoice/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import YoutubeSearch from "@iso/redux/youtubeSearch/reducers";
import Articles from "@iso/redux/articles/reducers";
import Investors from "@iso/redux/investors/reducers";
import scrumBoard from "@iso/redux/scrumBoard/reducer";
import drawer from "@iso/redux/drawer/reducer";
import modal from "@iso/redux/modal/reducer";
import profile from "@iso/redux/profile/reducer";
import githubSearch from "@iso/redux/githubSearch/reducers";
import quiz from "@iso/redux/quiz/reducer";
import Members from "@iso/redux/members/reducers";

// muttas

import Boats from "@iso/redux/boats/reducers";
import Contacts from "@iso/redux/contacts/reducers";
import Moorings from "@iso/redux/moorings/reducers";
import Journals from "@iso/redux/journals/reducers";
import Events from "@iso/redux/events/reducers";
import Ports from "@iso/redux/ports/reducer";

// gws

import Salesman from "@iso/redux/salesman/reducers";
import Ticket from "@iso/redux/ticket/reducers";
import Wallet from "@iso/redux/wallet/reducers";
import Expense from "@iso/redux/expense/reducers";
import Exchange from "@iso/redux/exchange/reducers";

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  Articles,
  Investors,
  scrumBoard,
  modal,
  drawer,
  profile,
  githubSearch,
  quiz,
  Members,
  Boats,
  Moorings,
  Journals,
  Events,
  Ports,
  // gws
  Salesman,
  Ticket,
  Wallet,
  Expense,
  Exchange,
});
