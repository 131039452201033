import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import actions from "./actions";
import omit from "lodash/omit";
import { rsf, db, database } from "@iso/lib/firebase/firebase";
import {
  convertCollectionsSnapshotToMap,
  deleteDocuments,
  addCollectionAndDocuments,
} from "@iso/lib/firebase/firebase.util";

/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/firestore
 */

const COLLECTION_NAME = "expenses"; // change your collection
const ORDER_BY = "id";
const ORDER = "desc";
let expensesCollections;
function* loadFromFirestore() {
  try {
    const collectionRef = db
      .collectionGroup(COLLECTION_NAME)
      .where("isDeleted", "==", false)
      .orderBy(ORDER_BY, ORDER);

    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    var data = yield call(convertCollectionsSnapshotToMap, snapshots);

    expensesCollections = db
      .collectionGroup(COLLECTION_NAME)
      .where("isDeleted", "==", false)
      .orderBy(ORDER_BY, ORDER);
    yield fork(updateExpenses);
    yield put(actions.loadFromFireStoreSuccess(data));
    console.log("Expenses loaded from Firestore");
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* updateExpenses() {
  const successActionCreator = (data) => {
    if (data.docChanges()) {
      console.log(data.docChanges());
      var type = "NO_CHANGE";
      var newIndex = null;
      try {
        type = data.docChanges()[0].type;
      } catch (error) {
        console.log("error", error);
        return { type: "NO_CHANGE" };
      }
      try {
        newIndex = data.docChanges()[0].newIndex;
      } catch (error) {
        console.log("error", error);
        return { type: "NO_CHANGE" };
      }

      const expense = data.docs[newIndex].data();
      console.log(expense);
      return {
        type: actions.FIRESTORE_SYNC_SAGA,
        payload: { expense },
      };
    }
  };
  yield call(rsf.firestore.syncCollection, expensesCollections, {
    successActionCreator,
  });
}

function* updateExpenseSaga({ payload }) {
  try {
    const collectionRef = db
      .collectionGroup(COLLECTION_NAME)
      .where("isDeleted", "==", false)
      .orderBy(ORDER_BY, ORDER);

    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    var data = yield call(convertCollectionsSnapshotToMap, snapshots);

    expensesCollections = db
      .collectionGroup(COLLECTION_NAME)
      .where("isDeleted", "==", false)
      .orderBy(ORDER_BY, ORDER);

    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case "delete":
        yield call(rsf.firestore.setDocument, data.path, {
          ...data,
          deleted: new Date().getTime(),
          isDeleted: true,
        });
        break;
      case "update":
        yield call(rsf.firestore.setDocument, data.path, {
          ...omit(data, ["key"]),
        });
        break;
      // default:
      //   const newID = database.newID();
      //   yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${newID}`, {
      //     ...data,
      //     created: new Date().getTime(),
      //     newid: newID,
      //   });
      //   break;
      default:
        yield call(rsf.firestore.addDocument, COLLECTION_NAME, data);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* resetFireStoreDocuments() {
  try {
    yield call(deleteDocuments, COLLECTION_NAME);
    //yield call(addCollectionAndDocuments, COLLECTION_NAME, fakeDataList);
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.RESET_FIRESTORE_DOCUMENTS, resetFireStoreDocuments),
    takeEvery(actions.FIRESTORE_SYNC_SAGA, updateExpenseSaga),
  ]);
}
