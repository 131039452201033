import { all } from "redux-saga/effects";
import authSagas from "@iso/redux/auth/saga";
import invoicesSagas from "@iso/redux/invoice/saga";
import mailSagas from "@iso/redux/mail/saga";
import notesSagas from "@iso/redux/notes/saga";
import todosSagas from "@iso/redux/todos/saga";
import ecommerceSaga from "@iso/redux/ecommerce/saga";
import cardsSagas from "@iso/redux/card/saga";
import chatSagas from "@iso/redux/chat/sagas";
import youtubeSearchSagas from "@iso/redux/youtubeSearch/sagas";
import githubSagas from "@iso/redux/githubSearch/sagas";
import articles from "@iso/redux/articles/sagas";
import investors from "@iso/redux/investors/sagas";
import scrumBoardSaga from "@iso/redux/scrumBoard/saga";
import profileSaga from "@iso/redux/profile/saga";
import quizSaga from "@iso/redux/quiz/saga";
import membersSaga from "@iso/redux/members/sagas";

// muttas

import boatsSaga from "@iso/redux/boats/sagas";
import contactSagas from "@iso/redux/contacts/sagas";
import mooringSagas from "@iso/redux/moorings/sagas";
import journalSagas from "@iso/redux/journals/sagas";
import eventSagas from "@iso/redux/events/sagas";
import portSaga from "@iso/redux/ports/saga";

//  gws

import salesmansSaga from "@iso/redux/salesman/sagas";
import ticketsSaga from "@iso/redux/ticket/sagas";
import walletsSaga from "@iso/redux/wallet/sagas";
import expensesSaga from "@iso/redux/expense/sagas";
import exchangesSaga from "@iso/redux/exchange/sagas";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    contactSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    ecommerceSaga(),
    cardsSagas(),
    invoicesSagas(),
    chatSagas(),
    youtubeSearchSagas(),
    githubSagas(),
    articles(),
    investors(),
    scrumBoardSaga(),
    profileSaga(),
    quizSaga(),
    membersSaga(),
    boatsSaga(),
    mooringSagas(),
    journalSagas(),
    eventSagas(),
    portSaga(),
    //
    salesmansSaga(),
    ticketsSaga(),
    walletsSaga(),
    expensesSaga(),
    exchangesSaga(),
  ]);
}
