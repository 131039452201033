const initialData = {
  tasks: {
    bonadea: {
      id: "bonadea",
      column_id: "gerence",
      title: "Bona Dea",
      description: "Take Out the garbage 1",
      labels: ["default", "processing", "warning", "success"],
      due_date: "2020-01-01",
      assignees: ["mark"],
      attachments: [],
      comments: [],
      todos: [],
      editing: false,
      created_at: "2019-01-01",
      updated_at: "",
    },
    gurmeyat: {
      id: "gurmeyat",
      column_id: "gerence",
      title: "Gurmeyat",
      description: "Let Fix The Task manager using Redux 2",
      labels: ["warning", "error"],
      due_date: "2019-01-01",
      assignees: [],
      attachments: [],
      comments: [],
      todos: [],
      editing: false,
      created_at: "2019-01-01",
      updated_at: "",
    },
    "task-3": {
      id: "task-3",
      column_id: "gerence",
      title: "There",
      description: "Need Some Study Time To Solve The Problem 3",
      labels: ["processing", "warning", "success"],
      due_date: "2019-01-01",
      assignees: ["neamat", "mim"],
      attachments: [],
      comments: [],
      todos: [],
      editing: false,
      created_at: "2019-01-01",
      updated_at: "",
    },
    "task-4": {
      id: "task-4",
      column_id: "gerence",
      title: "Nothing to do",
      description: "Let Learn Formik 4",
      labels: ["warning", "success"],
      due_date: "2019-01-01",
      assignees: [],
      attachments: [],
      comments: [],
      todos: [],
      editing: false,
      created_at: "2019-01-01",
      updated_at: "",
    },
    "task-5": {
      id: "task-5",
      column_id: "Tepecik",
      title: "Lets Fixed",
      description: "Come on ... 5",
      labels: ["success", "warning"],
      due_date: "2020-01-01",
      assignees: ["bob"],
      attachments: [],
      comments: [],
      todos: [],
      editing: false,
      created_at: "2019-01-01",
      updated_at: "",
    },
  },
  columns: {
    gerence: {
      id: "gerence",
      board_id: "muttasliman",
      task_orders: ["bonadea", "gurmeyat", "task-3", "task-4"],
      title: "Gerence",
      editing: false,
    },
    kaleonu: {
      id: "kaleonu",
      board_id: "gumbetliman",
      task_orders: [],
      title: "Kale Önü",
      editing: false,
    },
    tepecik: {
      id: "tepecik",
      board_id: "muttasliman",
      task_orders: ["task-5"],
      title: "Tepecik",
      editing: false,
    },
    tiskele: {
      id: "tiskele",
      board_id: "muttasliman",
      task_orders: ["task-5"],
      title: "T - İskele",
      editing: false,
    },
  },
  boards: {
    muttasliman: {
      id: "muttasliman",
      column_orders: ["gerence", "tepecik", "kaleonu", "tiskele"],
      title: "Muttaş Belediye Limanı",
      category: "Bodrum",
      progress: 50,
      thumb: "",
      open_to_members: false,
      open_to_company: false,
      estimated_time: "",
      editing: false,
      created_at: "2022-12-20",
      updated_at: "",
    },
    gumbetliman: {
      id: "gumbetliman",
      column_orders: ["kaleonu"],
      title: "Gümbet Belediye Limanı",
      category: "Gumbet",
      progress: 70,
      thumb: "",
      open_to_members: true,
      open_to_company: true,
      estimated_time: "",
      editing: false,
      created_at: "2022-12-20",
      updated_at: "",
    },
    bitezliman: {
      id: "bitezliman",
      column_orders: [],
      title: "Bitez Belediye Limanı",
      category: "Bitez",
      progress: 30,
      thumb: "",
      open_to_members: false,
      open_to_company: true,
      estimated_time: "",
      editing: false,
      created_at: "2022-12-20",
      updated_at: "",
    },
  },
  labels: {
    "label-1": {
      id: "label-1",
      title: "default",
      color: "red",
      created_at: "",
      updated_at: "",
      editing: false,
    },
    "label-2": {
      id: "label-2",
      title: "processing",
      color: "red",
      created_at: "",
      updated_at: "",
      editing: false,
    },
    "label-3": {
      id: "label-3",
      title: "warning",
      color: "red",
      created_at: "",
      updated_at: "",
      editing: false,
    },
    "label-4": {
      id: "label-4",
      title: "success",
      color: "red",
      created_at: "",
      updated_at: "",
      editing: false,
    },
    "label-5": {
      id: "label-5",
      title: "error",
      color: "red",
      created_at: "",
      updated_at: "",
      editing: false,
    },
  },
};

export default initialData;
