import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import actions from "./actions";
import omit from "lodash/omit";
import { rsf, db, database } from "@iso/lib/firebase/firebase";
import {
  convertCollectionsSnapshotToMap,
  deleteDocuments,
  addCollectionAndDocuments,
} from "@iso/lib/firebase/firebase.util";

/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/firestore
 */

const COLLECTION_NAME = "wallets"; // change your collection
const ORDER_BY = "id";
const ORDER = "desc";
let walletsCollections;
function* loadFromFirestore() {
  try {
    const collectionRef = db
      .collectionGroup(COLLECTION_NAME)
      .where("isDeleted", "==", false)
      .orderBy(ORDER_BY, ORDER);

    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    var data = yield call(convertCollectionsSnapshotToMap, snapshots);

    walletsCollections = db
      .collectionGroup(COLLECTION_NAME)
      .where("isDeleted", "==", false)
      .orderBy(ORDER_BY, ORDER);
    yield fork(updateWallets);
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* updateWallets() {
  const successActionCreator = (data) => {
    const { type, newIndex } = data.docChanges()[0];
    console.log(type);

    // const dataMoodified = type === "modified";
    // if (!dataMoodified) {
    //   return { type: "NO_CHANGE" };
    // }
    const wallet = data.docs[newIndex].data();
    console.log(wallet);
    return {
      type: actions.FIRESTORE_SYNC_SAGA,
      payload: { wallet },
    };
  };
  yield call(rsf.firestore.syncCollection, walletsCollections, {
    successActionCreator,
  });
}

function* updateWalletSaga({ payload }) {
  try {
    const collectionRef = db
      .collectionGroup(COLLECTION_NAME)
      .where("isDeleted", "==", false)
      .orderBy(ORDER_BY, ORDER);

    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    var data = yield call(convertCollectionsSnapshotToMap, snapshots);

    walletsCollections = db
      .collectionGroup(COLLECTION_NAME)
      .where("isDeleted", "==", false)
      .orderBy(ORDER_BY, ORDER);

    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case "delete":
        yield call(rsf.firestore.setDocument, data.path, {
          deleted: new Date().getTime(),
          isDeleted: true,
        });
        break;
      case "update":
        yield call(rsf.firestore.setDocument, data.path, {
          ...omit(data, ["key"]),
        });
        break;
      // default:
      //   const newID = database.newID();
      //   yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${newID}`, {
      //     ...data,
      //     created: new Date().getTime(),
      //     newid: newID,
      //   });
      //   break;
      default:
        yield call(rsf.firestore.addDocument, COLLECTION_NAME, data);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* resetFireStoreDocuments() {
  try {
    yield call(deleteDocuments, COLLECTION_NAME);
    //yield call(addCollectionAndDocuments, COLLECTION_NAME, fakeDataList);
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.RESET_FIRESTORE_DOCUMENTS, resetFireStoreDocuments),
    takeEvery(actions.FIRESTORE_SYNC_SAGA, updateWalletSaga),
  ]);
}
