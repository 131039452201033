export default {
  apiKey: "AIzaSyACt-mIctO86iYD1yKesNQoCfR1sD6ccdk",
  authDomain: "gumbetwatersports.firebaseapp.com",
  projectId: "gumbetwatersports",
  databaseURL: "https://gumbetwatersports-default-rtdb.firebaseio.com",
  storageBucket: "gumbetwatersports.appspot.com",
  messagingSenderId: "606170920906",
  appId: "1:606170920906:web:5695e040eb1abea9640133",
  measurementId: "G-SCPX4YGMLX",
};
