// saga.js
import { all, takeEvery, put, select } from "redux-saga/effects";
import portActions from "./actions";
import DemoData from "./data";
import { loadState, saveState } from "@iso/lib/helpers/localStorage";

const getPorts = (state) => state.Ports;

function* boardsRenderEffectSaga() {
  let boards;
  let columns;
  let tasks;
  if (localStorage.hasOwnProperty("port")) {
    const port = loadState("port");
    boards = port.boards;
    columns = port.columns;
    tasks = port.tasks;
  } else {
    boards = DemoData.boards;
    columns = DemoData.columns;
    tasks = DemoData.tasks;
  }
  saveState("port", { boards, columns, tasks });
  yield put(portActions.setBoardsData({ boards, columns, tasks }));
}

function* boardRenderEffectSaga({ payload }) {
  let port;
  let boards;
  let columns;
  let tasks;
  if (localStorage.hasOwnProperty("port")) {
    port = loadState("port");
    boards = port.boards;
    columns = port.columns;
    tasks = port.tasks;
  } else {
    port = DemoData;
    boards = DemoData.boards;
    columns = DemoData.columns;
    tasks = DemoData.tasks;
  }

  yield put(
    portActions.setBoardData({
      boards,
      columns,
      tasks,
    })
  );
}
function* createOrUpdateBoardEffectSaga(action) {
  let port = yield select(getPorts);
  const boards = {
    ...port.boards,
    [action.payload.id]: action.payload,
  };
  port = {
    columns: port.columns,
    tasks: port.tasks,
    boards: boards,
  };
  saveState("port", port);

  yield put(portActions.createOrUpdateBoard(boards));
}

function* deleteBoardEffectSaga(action) {
  let port = yield select(getPorts);
  const BOARDS = {
    ...port.boards,
  };
  delete BOARDS[action.payload];

  const boards = BOARDS;

  port = {
    ...port,
    boards,
  };
  saveState("port", port);

  yield put(portActions.deleteBoard(boards));
}

function* createOrUpdateColumnEffectSaga({ payload: { column, board_id } }) {
  let port = yield select(getPorts);
  console.log(column, board_id);
  const columns = {
    ...port.columns,
    [column.id]: {
      ...column,
      editing: false,
    },
  };
  let boards = port.boards;
  if (!column.editing) {
    boards = {
      ...port.boards,
      [board_id]: {
        ...port.boards[board_id],
        column_orders: [...port.boards[board_id].column_orders, column.id],
      },
    };
  }
  port = {
    ...port,
    columns,
    boards,
  };
  saveState("port", port);
  yield put(portActions.createOrUpdateColumn({ columns, boards }));
}

function* deleteColumnEffectSaga({ payload: { column_id, board_id } }) {
  let port = yield select(getPorts);
  const COLUMNS = {
    ...port.columns,
  };
  delete COLUMNS[column_id];

  const columns = COLUMNS;
  const boards = {
    ...port.boards,
    [board_id]: {
      ...port.boards[board_id],
      column_orders: port.boards[board_id].column_orders.filter(
        (order) => order !== column_id
      ),
    },
  };

  port = {
    ...port,
    columns,
    boards,
  };
  saveState("port", port);

  yield put(portActions.deleteColumn({ columns, boards }));
}

function* moveColumnEffectSaga({ payload: { board_id, column_orders } }) {
  let port = yield select(getPorts);
  const boards = {
    ...port.boards,
    [board_id]: {
      ...port.boards[board_id],
      column_orders: column_orders,
    },
  };
  port = {
    ...port,
    boards: boards,
  };
  saveState("port", port);

  yield put(portActions.setMovedColumn(boards));
}

function* createOrUpdateTaskEffectSaga(action) {
  let port = yield select(getPorts);
  const tasks = {
    ...port.tasks,
    [action.payload.id]: { ...action.payload, editing: false },
  };
  let columns = port.columns;
  if (!action.payload.editing) {
    columns = {
      ...port.columns,
      [action.payload.column_id]: {
        ...port.columns[action.payload.column_id],
        task_orders: [
          ...port.columns[action.payload.column_id].task_orders,
          action.payload.id,
        ],
      },
    };
  }

  port = {
    columns: columns,
    tasks: tasks,
    boards: port.boards,
  };
  saveState("port", port);

  yield put(portActions.createOrUpdateTask({ tasks, columns }));
}

function* deleteTaskEffectSaga({ payload: { task_id, column_id } }) {
  let port = yield select(getPorts);
  const tasks = Object.keys(port.tasks).reduce((object, key) => {
    if (key !== task_id) {
      object[key] = port.tasks[key];
    }
    return object;
  }, {});
  const columns = {
    ...port.columns,
    [column_id]: {
      ...port.columns[column_id],
      task_orders: port.columns[column_id].task_orders.filter(
        (order) => order !== task_id
      ),
    },
  };
  port = {
    columns: columns,
    tasks: tasks,
    boards: port.boards,
  };
  saveState("port", port);
  yield put(portActions.deleteTask({ columns, tasks }));
}

function* moveTaskEffectSaga({ payload: { tasks, columns } }) {
  let port = yield select(getPorts);
  port = {
    columns: columns,
    tasks: tasks,
    boards: port.boards,
  };
  saveState("port", port);
  yield put(portActions.setMovedTask({ columns, tasks }));
}

export default function* portSaga() {
  yield all([
    takeEvery(portActions.LOAD_BOARDS_DATA_SAGA, boardsRenderEffectSaga),
    takeEvery(portActions.LOAD_CURRENT_BOARD_DATA_SAGA, boardRenderEffectSaga),
    takeEvery(
      portActions.CREATE_OR_UPDATE_BOARD_WATCHER,
      createOrUpdateBoardEffectSaga
    ),
    takeEvery(portActions.DELETE_BOARD_WATCHER, deleteBoardEffectSaga),
    takeEvery(
      portActions.CREATE_OR_UPDATE_COLUMN_WATCHER,
      createOrUpdateColumnEffectSaga
    ),
    takeEvery(portActions.DELETE_COLUMN_WATCHER, deleteColumnEffectSaga),
    takeEvery(portActions.MOVE_COLUMN_WATCHER, moveColumnEffectSaga),
    takeEvery(
      portActions.CREATE_OR_UPDATE_TASK_WATCHER,
      createOrUpdateTaskEffectSaga
    ),
    takeEvery(portActions.DELETE_TASK_WATCHER, deleteTaskEffectSaga),
    takeEvery(portActions.MOVE_TASK_WATCHER, moveTaskEffectSaga),
  ]);
}
