let createdAt = new Date().getTime();
const tableData = JSON.parse(
  `[
      {
        "key": null,
        "id": "1",
        "devre": "119",
        "isim": "Abdullah",
        "soyisim": "Tüzün",
        "email": "abdullahtuzun1@hotmail.com",
        "bolge": "Torba",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Kuyumcu (Gümüş) + rehber",
        "telefon": "05322618045",
        "dogumgunu": "28.07.1968",
        "cemiyetno": "3509",
        "sicilno": "16436",
        "acildurumkisisi": "Eylül Naz Tüzün",
        "acildurumtelefon": "05342025606",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "2",
        "devre": "99",
        "isim": "Adnan",
        "soyisim": "Eroğlu",
        "email": "madnan.eroglu@gmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "Endüstri Müh.",
        "telefon": "05322625026",
        "dogumgunu": "30.01.1948",
        "cemiyetno": "4627",
        "sicilno": "8984",
        "acildurumkisisi": "Eşi Gülden",
        "acildurumtelefon": "05322612609",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "3",
        "devre": "92",
        "isim": "Ahmet",
        "soyisim": "Korulsan",
        "email": "kkorulsan@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Em. Gazeteci",
        "telefon": "05322643788",
        "dogumgunu": "03.01.1943",
        "cemiyetno": "1209",
        "sicilno": "11089",
        "acildurumkisisi": "Semra Korulsan",
        "acildurumtelefon": "05336951828",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "4",
        "devre": "106",
        "isim": "Ahmet",
        "soyisim": "Selimoğlu",
        "email": "asgsl1566@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "Emekli",
        "telefon": "05333663629",
        "dogumgunu": "28.01.1953",
        "cemiyetno": "3649",
        "sicilno": "5080",
        "acildurumkisisi": "Naci Akıncı",
        "acildurumtelefon": "05383634210",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "5",
        "devre": "110",
        "isim": "Ahmet",
        "soyisim": "Vaner",
        "email": "avaner@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05327712526",
        "dogumgunu": "02.08.1959",
        "cemiyetno": "5482",
        "sicilno": "11022",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "6",
        "devre": "111",
        "isim": "Ahmet",
        "soyisim": "Hamulu",
        "email": "ahmethamulu@gmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Em. Prof. Kalp ve damar cerrahı, işyeri hekimi (aktif)",
        "telefon": "05323544158",
        "dogumgunu": "01.01.1960",
        "cemiyetno": "6472",
        "sicilno": null,
        "acildurumkisisi": "Çağatay Önür",
        "acildurumtelefon": "05323161112",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "7",
        "devre": "129",
        "isim": "Ahu",
        "soyisim": "Sökmenoğlu",
        "email": "ahusokmenoglu@gmail.com",
        "bolge": "Kızılağaç",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "Mimar/Akademisyen",
        "telefon": "05323420885",
        "dogumgunu": "22.05.1978",
        "cemiyetno": "6484",
        "sicilno": "11092",
        "acildurumkisisi": "Eşi Ali Sohtorik",
        "acildurumtelefon": "05322832063",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "8",
        "devre": "93",
        "isim": "Akın",
        "soyisim": "Kuruner",
        "email": "akin.kuruner@isbank.net.tr",
        "bolge": "Bodrum",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05339693272",
        "dogumgunu": "03.10.1943",
        "cemiyetno": "3139",
        "sicilno": "8238",
        "acildurumkisisi": "Dilek Duru",
        "acildurumtelefon": "05312035742",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "9",
        "devre": "101",
        "isim": "Akın",
        "soyisim": "Algan",
        "email": "akinalgan@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Em.Büyükelçi",
        "telefon": "05389480499",
        "dogumgunu": "08.06.1949",
        "cemiyetno": null,
        "sicilno": "6263",
        "acildurumkisisi": "Eşi 106 Sibel",
        "acildurumtelefon": "05334430907",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "10",
        "devre": "115",
        "isim": "Alev",
        "soyisim": "Noyan Temizer",
        "email": "alevnoyan657@gmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05366545495",
        "dogumgunu": "23.11.1962",
        "cemiyetno": "1983",
        "sicilno": "15540",
        "acildurumkisisi": "Abisi Reha",
        "acildurumtelefon": "05497616630",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "11",
        "devre": "99",
        "isim": "Ali",
        "soyisim": "Güler",
        "email": "alig48@gmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Em. Ankara SBF mezunu",
        "telefon": "05322550404",
        "dogumgunu": "13.07.1948",
        "cemiyetno": "3094",
        "sicilno": null,
        "acildurumkisisi": "Eşi Tanju Güler",
        "acildurumtelefon": "05326129312",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "12",
        "devre": "99",
        "isim": "Ali",
        "soyisim": "Akınal",
        "email": "alibakinal@gmail.com",
        "bolge": "Türkbükü", 
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05322648783",
        "dogumgunu": "02.08.1948",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Burcu Abacı",
        "acildurumtelefon": "05324351618",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "13",
        "devre": "107",
        "isim": "Ali",
        "soyisim": "Platin",
        "email": "aliplatin@yahoo.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "Grafiker",
        "telefon": "05357484777",
        "dogumgunu": "18.01.1953",
        "cemiyetno": "1524",
        "sicilno": "4874",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "14",
        "devre": "112",
        "isim": "Ali",
        "soyisim": "Oğuz",
        "email": "dr.alioguz@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "KBB",
        "telefon": "05322323569",
        "dogumgunu": "15.04.1962",
        "cemiyetno": "2347",
        "sicilno": "7780",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "15",
        "devre": "113",
        "isim": "Ali",
        "soyisim": "Öğüdücü",
        "email": "ali.oguducu@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Girişimci",
        "telefon": "05333792324",
        "dogumgunu": "28.11.1962",
        "cemiyetno": "2144",
        "sicilno": "6770",
        "acildurumkisisi": "İrem Öğüdücü",
        "acildurumtelefon": "05493807772",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "16",
        "devre": "117",
        "isim": "Ali",
        "soyisim": "Ağaçkesen",
        "email": "akesen117@gmail.com",
        "bolge": "Dereköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05322960041",
        "dogumgunu": "18.05.1966",
        "cemiyetno": "2455",
        "sicilno": "9077",
        "acildurumkisisi": "Nafiz Altuğ",
        "acildurumtelefon": "05398788725",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "17",
        "devre": "106",
        "isim": "Ali Aydın",
        "soyisim": "Erkün",
        "email": "ayderkun@hotmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Mak. Müh. (İTÜ) +İşletme lisans üstü mba",
        "telefon": "05323215230",
        "dogumgunu": "09.02.1956",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Gülçin Tarhan",
        "acildurumtelefon": "05323220705",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "18",
        "devre": "110",
        "isim": "Ali Can",
        "soyisim": "Aksoy",
        "email": "canaksoy4@yahoo.fr",
        "bolge": "Ortakent",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "Emekli",
        "telefon": "05335167132",
        "dogumgunu": "",
        "cemiyetno": "",
        "sicilno": "",
        "acildurumkisisi": "Selin Rozanes",
        "acildurumtelefon": "05322180653",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "19",
        "devre": "93",
        "isim": "Ali Engin",
        "soyisim": "Oba",
        "email": "enginoba@hotmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Em. Büyükelçi- Prof(aktif)",
        "telefon": "05335203834",
        "dogumgunu": "28.04.1942",
        "cemiyetno": null,
        "sicilno": "13928",
        "acildurumkisisi": "Eşi İlknur",
        "acildurumtelefon": "05335203834",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "20",
        "devre": "114",
        "isim": "Alp",
        "soyisim": "Berk",
        "email": "alp.berk@ferromet.com.tr",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Mümessillik Dış Tic.",
        "telefon": "05322145013",
        "dogumgunu": "14.11.1963",
        "cemiyetno": "2362",
        "sicilno": "9719",
        "acildurumkisisi": "Funda Yoran",
        "acildurumtelefon": "05386293000",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "21",
        "devre": "119",
        "isim": "Alpay",
        "soyisim": "Dedeoğlu",
        "email": "alpaydedeoglu@gmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05444543850",
        "dogumgunu": "08.10.1967",
        "cemiyetno": "3513",
        "sicilno": "8044",
        "acildurumkisisi": "Eşi Ebru",
        "acildurumtelefon": "05302058496",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "22",
        "devre": "119",
        "isim": "Alper",
        "soyisim": "Üner",
        "email": "zazu1tr@yahoo.com",
        "bolge": "Adabükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Reklam/İletişim/Prodüksiyon",
        "telefon": "05327017774",
        "dogumgunu": "09.06.1968",
        "cemiyetno": null,
        "sicilno": "9695",
        "acildurumkisisi": "Babası 89 Oktay abi",
        "acildurumtelefon": "05322637498",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "23",
        "devre": "125",
        "isim": "Altuğ",
        "soyisim": "Oğuz",
        "email": "altugoguz@altobio.com.tr",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "Sanayici",
        "telefon": "05336886053",
        "dogumgunu": "18.05.1974",
        "cemiyetno": "5247",
        "sicilno": "9739",
        "acildurumkisisi": "Şebnem Oğuz",
        "acildurumtelefon": "05336886054",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "24",
        "devre": "93",
        "isim": "Armağan",
        "soyisim": "Kurra",
        "email": "babakurra@yahoo.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Y. Müh. Mimar",
        "telefon": "05352270523",
        "dogumgunu": "23.02.1942",
        "cemiyetno": "3066",
        "sicilno": "9149",
        "acildurumkisisi": "Eşi Selma",
        "acildurumtelefon": "05337732370",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "25",
        "devre": "115",
        "isim": "Aşkın",
        "soyisim": "Yurdakul",
        "email": "askinyurdakul@gmail.com",
        "bolge": "Bağla",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05494010001",
        "dogumgunu": "17.03.1964",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Oktay",
        "acildurumtelefon": "05302732736",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "26",
        "devre": "110",
        "isim": "Attila",
        "soyisim": "Suyolcu",
        "email": "attilasuyolcu@yahoo.com",
        "bolge": "Dereköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "İşletmeci",
        "telefon": "05334603964",
        "dogumgunu": "01.01.1959",
        "cemiyetno": "1407",
        "sicilno": "5519",
        "acildurumkisisi": "Eşi Şebnem",
        "acildurumtelefon": "05424720000",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "27",
        "devre": "122",
        "isim": "Aycan",
        "soyisim": "Yeniley",
        "email": "aycanege3@gmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "İç mimar",
        "telefon": "05334742557",
        "dogumgunu": "20.03.1972",
        "cemiyetno": "4822",
        "sicilno": "11646",
        "acildurumkisisi": "Edith",
        "acildurumtelefon": "05318115342",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "28",
        "devre": "115",
        "isim": "Banu",
        "soyisim": "Tuğsavul",
        "email": "banu.tugsavul@hotmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05323174770",
        "dogumgunu": "20.01.1965",
        "cemiyetno": "2001",
        "sicilno": null,
        "acildurumkisisi": "Pembegül İnamli",
        "acildurumtelefon": "053364318",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "29",
        "devre": "136",
        "isim": "Barış",
        "soyisim": "Erdem",
        "email": "bariserdem136@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05428319652",
        "dogumgunu": "27.02.1985",
        "cemiyetno": "7784",
        "sicilno": "16282",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "30",
        "devre": "108",
        "isim": "Berge",
        "soyisim": "Ulaş",
        "email": "berge@hotellinoistanbul.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Turizm-Otel İşletmecisi",
        "telefon": "05358508222",
        "dogumgunu": "02.12.1956",
        "cemiyetno": "2642",
        "sicilno": "6835",
        "acildurumkisisi": "Eşi Mine",
        "acildurumtelefon": "05357915519",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "31",
        "devre": "105",
        "isim": "Berhan",
        "soyisim": "Kaya",
        "email": "berhankaya@yahoo.com",
        "bolge": "Bağla",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05344213363",
        "dogumgunu": "11.01.1954",
        "cemiyetno": "3728",
        "sicilno": "13222",
        "acildurumkisisi": "Eşi Jaklin",
        "acildurumtelefon": "05445951962",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "32",
        "devre": "123",
        "isim": "Berk",
        "soyisim": "Kutengin",
        "email": "berk.kutengin@bnktextile.com",
        "bolge": "Kadıkalesi",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "Tekstil-ihracat",
        "telefon": "05524624107",
        "dogumgunu": "29.09.1972",
        "cemiyetno": "5245",
        "sicilno": "10103",
        "acildurumkisisi": "İrem Kutengin",
        "acildurumtelefon": "05309616773",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "33",
        "devre": "100",
        "isim": "Bilguday",
        "soyisim": "Günday",
        "email": "bilgudaygunday@superonline.com",
        "bolge": "Konacık",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Em. Albay",
        "telefon": "05326826282",
        "dogumgunu": "19.12.1949",
        "cemiyetno": "3979",
        "sicilno": "8193",
        "acildurumkisisi": "Eşi Meral",
        "acildurumtelefon": "05323213042",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "34",
        "devre": "112",
        "isim": "Birgül",
        "soyisim": "Karsan",
        "email": "birgulgur1@hotmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05333985323",
        "dogumgunu": "11.08.1961",
        "cemiyetno": "6502",
        "sicilno": "13183",
        "acildurumkisisi": "Eşi 109 Volkan Karsan",
        "acildurumtelefon": "",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "35",
        "devre": "108",
        "isim": "Burak",
        "soyisim": "Ertung",
        "email": "abertung@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05332769067",
        "dogumgunu": "24.10.1957",
        "cemiyetno": "1581",
        "sicilno": "6499",
        "acildurumkisisi": "Ayşe Ertung",
        "acildurumtelefon": "05336602547",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "36",
        "devre": "115",
        "isim": "Burak",
        "soyisim": "Arslan",
        "email": "burakarslan@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05324324647",
        "dogumgunu": "08.01.1965",
        "cemiyetno": "4790",
        "sicilno": null,
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "37",
        "devre": "93",
        "isim": "Burhan",
        "soyisim": "Ramazanoğulları",
        "email": "burhanramazanogullari@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Y. Müh. Mimar",
        "telefon": "05322111836",
        "dogumgunu": "10.05.1941",
        "cemiyetno": "4254",
        "sicilno": "12631",
        "acildurumkisisi": "Eşi Bilge",
        "acildurumtelefon": "05326510203",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "38",
        "devre": "114",
        "isim": "Burhan",
        "soyisim": "Ozan",
        "email": "Burhan.Ozan@dnvgl.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Gemi Müh.",
        "telefon": "05307160238",
        "dogumgunu": "29.05.1963",
        "cemiyetno": "1130",
        "sicilno": "14826",
        "acildurumkisisi": "Rengin Ozan",
        "acildurumtelefon": "05337442238",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "39",
        "devre": "99",
        "isim": "Bülent",
        "soyisim": "Pozam",
        "email": "tempodis@superonline.com",
        "bolge": "Konacık",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Avukat",
        "telefon": "05332674363",
        "dogumgunu": "19.03.1948",
        "cemiyetno": "4113",
        "sicilno": "7078",
        "acildurumkisisi": "Oğlu Tolga",
        "acildurumtelefon": "05399367118",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "40",
        "devre": "105",
        "isim": "Bülent",
        "soyisim": "Yalçınkaya",
        "email": "gsctr@yahoo.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "İşletmeci",
        "telefon": "05356610440",
        "dogumgunu": "11.02.1954",
        "cemiyetno": "1798",
        "sicilno": "9465",
        "acildurumkisisi": "Eşi Zühre",
        "acildurumtelefon": "05321545233",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "41",
        "devre": "107",
        "isim": "Bülent",
        "soyisim": "Keskin",
        "email": "bulkeskin@gmail.com",
        "bolge": "İzmir",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05077199334",
        "dogumgunu": "27.02.1956",
        "cemiyetno": "2655",
        "sicilno": null,
        "acildurumkisisi": "Eşi 115 Ufuk",
        "acildurumtelefon": "05332121955",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "42",
        "devre": "109",
        "isim": "Bülent",
        "soyisim": "Esen",
        "email": "bulentesen709@gmail.com",
        "bolge": "Kumbahçe",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05323419886",
        "dogumgunu": "06.05.1958",
        "cemiyetno": "1465",
        "sicilno": "8159",
        "acildurumkisisi": "Eşi Aynur Esen",
        "acildurumtelefon": "05324718595",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "43",
        "devre": "106",
        "isim": "Cahide",
        "soyisim": "Budak",
        "email": "cahide.budak@gmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05373969639",
        "dogumgunu": "05.02.1975",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Cahide Budak",
        "acildurumtelefon": "05355241697",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "44",
        "devre": "106",
        "isim": "Can",
        "soyisim": "Tengizman",
        "email": "can@tengizman.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "Emlak",
        "telefon": "05322510951",
        "dogumgunu": "08.01.1954",
        "cemiyetno": "5022",
        "sicilno": "6102",
        "acildurumkisisi": "Gülhan Kurter",
        "acildurumtelefon": "05324123036",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "45",
        "devre": "106",
        "isim": "Can",
        "soyisim": "Değirmenciler",
        "email": "c.degir@gmail.com",
        "bolge": "Konacık",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "",
        "telefon": "05362030229",
        "dogumgunu": "18.05.1954",
        "cemiyetno": "6585",
        "sicilno": "13866",
        "acildurumkisisi": "Eşi Tümay",
        "acildurumtelefon": "05324783504",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "46",
        "devre": "108",
        "isim": "Can",
        "soyisim": "Başar",
        "email": "cnbasar@yahoo.com",
        "bolge": "Torba",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "Sigortacı (Kızılağaç-Torba-Gölköy-Türkbükü-Gündoğanmoderatörü)",
        "telefon": "05322121610",
        "dogumgunu": "01.07.1957",
        "cemiyetno": "1337",
        "sicilno": "7666",
        "acildurumkisisi": "Gaye",
        "acildurumtelefon": "05322243878",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "47",
        "devre": "114",
        "isim": "Can",
        "soyisim": "Oral",
        "email": "canoral63@gmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Em. Turizm",
        "telefon": "05322127378",
        "dogumgunu": "01.11.1963",
        "cemiyetno": "1871",
        "sicilno": "10056",
        "acildurumkisisi": "Nur Sarper",
        "acildurumtelefon": "05323740078",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "48",
        "devre": "115",
        "isim": "Can",
        "soyisim": "Şakarcan",
        "email": "can.sakarcan@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05356694982",
        "dogumgunu": "25.08.1964",
        "cemiyetno": "2044",
        "sicilno": "9205",
        "acildurumkisisi": "Suat Şakarcan",
        "acildurumtelefon": "0533763644",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "49",
        "devre": "130",
        "isim": "Can",
        "soyisim": "Uçaner",
        "email": "ucaner@gmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Mühendis",
        "telefon": 5332103494,
        "dogumgunu": "26.06.1979",
        "cemiyetno": "6118",
        "sicilno": "12685",
        "acildurumkisisi": "İdil Ar Uçaner",
        "acildurumtelefon": "05362196515",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "50",
        "devre": "107",
        "isim": "Canan",
        "soyisim": "Gürcan",
        "email": "canangur@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05326160186",
        "dogumgunu": "04.07.1956",
        "cemiyetno": "3102",
        "sicilno": "9438",
        "acildurumkisisi": "Oğlu Can",
        "acildurumtelefon": "05327133970",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "51",
        "devre": "116",
        "isim": "Canel",
        "soyisim": "Karakaş",
        "email": "canelkarakas@gmail.com",
        "bolge": "Koyunbaba",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Tekstilci",
        "telefon": "05322923059",
        "dogumgunu": "27.06.1965",
        "cemiyetno": "2870",
        "sicilno": "7816",
        "acildurumkisisi": "Eşi 115 Fadim",
        "acildurumtelefon": "05326464692",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "52",
        "devre": "105",
        "isim": "Celal",
        "soyisim": "Gürcan",
        "email": "celalgurcan@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05322137574",
        "dogumgunu": "05.12.1953",
        "cemiyetno": "3103",
        "sicilno": "8396",
        "acildurumkisisi": "Oğlu Can",
        "acildurumtelefon": "05327133970",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "53",
        "devre": "93",
        "isim": "Celasin",
        "soyisim": "Egel",
        "email": "celasin@superonline.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Y. Mak. Müh.",
        "telefon": "05322117696",
        "dogumgunu": "22.09.1941",
        "cemiyetno": null,
        "sicilno": "11894",
        "acildurumkisisi": "Eşi Filiz",
        "acildurumtelefon": "05322113708",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "54",
        "devre": "142",
        "isim": "Cem",
        "soyisim": "Kocabasa",
        "email": "cemkocabasa@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05375969890",
        "dogumgunu": "22.09.1941",
        "cemiyetno": "7166",
        "sicilno": "16882",
        "acildurumkisisi": "Merve Kocabasa",
        "acildurumtelefon": "05346265877",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "55",
        "devre": "105",
        "isim": "Cem",
        "soyisim": "Kartal",
        "email": "cemkartal@gmail.com",
        "bolge": "Konacık",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "",
        "telefon": "05323122498",
        "dogumgunu": "28.02.1954",
        "cemiyetno": "5069",
        "sicilno": "9668",
        "acildurumkisisi": "Eşi Hale",
        "acildurumtelefon": "05322762912",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "56",
        "devre": "114",
        "isim": "Cem",
        "soyisim": "Osmanoğlu",
        "email": "osmanogluc@yahoo.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "Em. Bankacı",
        "telefon": "05326508200",
        "dogumgunu": "10.10.1963",
        "cemiyetno": "1911",
        "sicilno": "8676",
        "acildurumkisisi": "Haluk Baykal",
        "acildurumtelefon": "05323541929",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "57",
        "devre": "121",
        "isim": "Cem",
        "soyisim": "Göçmen",
        "email": "cem.cemgocmen@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Temcev Saha Md.",
        "telefon": "05335006795",
        "dogumgunu": "19.08.1971",
        "cemiyetno": "4586",
        "sicilno": "9486",
        "acildurumkisisi": "124 Selin Karabeyoğlu",
        "acildurumtelefon": "05432021216",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "58",
        "devre": "136",
        "isim": "Cem",
        "soyisim": "Özuğurlu",
        "email": "cozugurlu@gmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05357271846",
        "dogumgunu": "25.06.1985",
        "cemiyetno": "6524",
        "sicilno": null,
        "acildurumkisisi": "Eşi Müge",
        "acildurumtelefon": "05362716781",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "59",
        "devre": "98",
        "isim": "Cemal",
        "soyisim": "Burnaz",
        "email": "cemalburnaz@hotmail.com",
        "bolge": "Türkbükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05334483064",
        "dogumgunu": "01.01.1947",
        "cemiyetno": "1611",
        "sicilno": "4052",
        "acildurumkisisi": "Eşi Semra",
        "acildurumtelefon": "05357644676",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "60",
        "devre": "116",
        "isim": "Cenk",
        "soyisim": "Baykara",
        "email": "cenk117@yahoo.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Emlakçı",
        "telefon": "05326110701",
        "dogumgunu": "20.04.1965",
        "cemiyetno": "2970",
        "sicilno": "7819",
        "acildurumkisisi": "Eşi Ayşe",
        "acildurumtelefon": "05336378044",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "61",
        "devre": "102",
        "isim": "Çetin",
        "soyisim": "Yıldızay",
        "email": "cyildizay@yahoo.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "",
        "telefon": "05322613980",
        "dogumgunu": "04.06.1951",
        "cemiyetno": "2771",
        "sicilno": "9444",
        "acildurumkisisi": "Eşi Melek",
        "acildurumtelefon": "05422405340",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "62",
        "devre": "123",
        "isim": "Çınar",
        "soyisim": "Kurra",
        "email": "kurra776@gmail.com",
        "bolge": "Dereköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "Msc. Elk. Elektronik Müh.",
        "telefon": "05336273627",
        "dogumgunu": "26.12.1972",
        "cemiyetno": "4897",
        "sicilno": "9150",
        "acildurumkisisi": "Babası Armağan",
        "acildurumtelefon": "05352270523",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "63",
        "devre": "112",
        "isim": "Çiğdem",
        "soyisim": "Güven",
        "email": "acguven112@hotmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Em. Öğ. üyesi/Tekstil Tasarım",
        "telefon": "05322716783",
        "dogumgunu": "20.12.1959",
        "cemiyetno": "1562",
        "sicilno": "7756",
        "acildurumkisisi": "Halit Güven",
        "acildurumtelefon": "05322716773",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "64",
        "devre": "113",
        "isim": "Çiğdem",
        "soyisim": "Albayrak",
        "email": "cigdem.laratasarim@gmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05322167706",
        "dogumgunu": "01.01.1962",
        "cemiyetno": "1839",
        "sicilno": "13917",
        "acildurumkisisi": "Bilge Erkut",
        "acildurumtelefon": "05326935976",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "65",
        "devre": "109",
        "isim": "Demet",
        "soyisim": "Velicangil",
        "email": "demet.velicangil@gmail.com",
        "bolge": "Adabükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Em. Reklamcı",
        "telefon": "05324629485",
        "dogumgunu": "06.03.1958",
        "cemiyetno": "2975",
        "sicilno": "12165",
        "acildurumkisisi": "Ablası Figen",
        "acildurumtelefon": "05322736464",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "66",
        "devre": "115",
        "isim": "Deniz",
        "soyisim": "Özüçer",
        "email": "dnzkurtoglu@gmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05326145172",
        "dogumgunu": "19.01.1964",
        "cemiyetno": "5944",
        "sicilno": "11903",
        "acildurumkisisi": "Kızı Ayşe",
        "acildurumtelefon": "05055814779",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "67",
        "devre": "101",
        "isim": "Derya",
        "soyisim": "Fidan",
        "email": "derya1950@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05323128326",
        "dogumgunu": "26.07.1950",
        "cemiyetno": "5595",
        "sicilno": "13745",
        "acildurumkisisi": "Eşi Mine",
        "acildurumtelefon": "05332423333",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "68",
        "devre": "132",
        "isim": "Derya",
        "soyisim": "Taşdemir",
        "email": "deryatasdemir@hotmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Avukat / Arabulucu",
        "telefon": "05332144493",
        "dogumgunu": "13.05.1981",
        "cemiyetno": null,
        "sicilno": "16114",
        "acildurumkisisi": "Eşi Ali Emre",
        "acildurumtelefon": "05322016567",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "69",
        "devre": "111",
        "isim": "Dilek",
        "soyisim": "Anadol",
        "email": "dilek_165@yahoo.fr",
        "bolge": "Kadıkalesi",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "GSÜ Em. Gn. Sek.",
        "telefon": "05336070020",
        "dogumgunu": "16.02.1960",
        "cemiyetno": "1706",
        "sicilno": "8310",
        "acildurumkisisi": "Eşi Macit Umul",
        "acildurumtelefon": "05322442123",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "70",
        "devre": "93",
        "isim": "Dinç",
        "soyisim": "Erdün",
        "email": "eerdun1@yahoo.com",
        "bolge": "Gölköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "Em. Büyükelçi",
        "telefon": "05324771800",
        "dogumgunu": "18.03.1941",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "71",
        "devre": "82",
        "isim": "Doğan",
        "soyisim": "Sorguç",
        "email": "dogan.sorguc2@gmail.com",
        "bolge": "Gölköy",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Y.İnş.Müh.",
        "telefon": "05374214071",
        "dogumgunu": "15.04.1930",
        "cemiyetno": "6356",
        "sicilno": "5672",
        "acildurumkisisi": "Oğlu Sinan",
        "acildurumtelefon": "05332680808",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "72",
        "devre": "123",
        "isim": "Ebru",
        "soyisim": "Şahbaz",
        "email": "ebsahbaz@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05323711541",
        "dogumgunu": "10.04.1972",
        "cemiyetno": "4846",
        "sicilno": null,
        "acildurumkisisi": "Akın Şahbaz",
        "acildurumtelefon": "05322236234",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "73",
        "devre": "108",
        "isim": "Egemen Ersan",
        "soyisim": "Güner",
        "email": "e.guner@yahoo.fr",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "Turizmci",
        "telefon": "05342061276",
        "dogumgunu": "23.04.1957",
        "cemiyetno": "3083",
        "sicilno": "12681",
        "acildurumkisisi": "Hale Güner",
        "acildurumtelefon": "0033667180707",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "74",
        "devre": "101",
        "isim": "Ekrem",
        "soyisim": "İnözü",
        "email": "einozu@gmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05322127640",
        "dogumgunu": "01.07.1950",
        "cemiyetno": "6109",
        "sicilno": null,
        "acildurumkisisi": "05335481677",
        "acildurumtelefon": "05335481677",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "75",
        "devre": "130",
        "isim": "Elif",
        "soyisim": "Akar",
        "email": "elifmi@yahoo.com",
        "bolge": "Yahşi",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "",
        "telefon": "05323051071",
        "dogumgunu": "",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Mithat Çiftçioğlu +6592364506",
        "acildurumtelefon": "006592364506",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "76",
        "devre": "114",
        "isim": "Emine",
        "soyisim": "Sayu",
        "email": "esayu@hotmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05322158376",
        "dogumgunu": "03.08.1963",
        "cemiyetno": "1167",
        "sicilno": "7251",
        "acildurumkisisi": "Cahide Budak",
        "acildurumtelefon": "05373969639",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "77",
        "devre": "133",
        "isim": "Eran",
        "soyisim": "Hakim",
        "email": "hakimeran@yahoo.com",
        "bolge": "Mazı",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Gonca",
        "isbilgisi": "Yönetmen/Fotoğrafçı",
        "telefon": "05352194927",
        "dogumgunu": "27.08.1982",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Gizem Hakim",
        "acildurumtelefon": "05354715646",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "78",
        "devre": "109",
        "isim": "Ercan",
        "soyisim": "Ekin",
        "email": "ercanekin2000@yahoo.com",
        "bolge": "Koyunbaba",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Em. Fotoğrafçı",
        "telefon": "05336184908",
        "dogumgunu": "28.04.1958",
        "cemiyetno": "3214",
        "sicilno": "5341",
        "acildurumkisisi": "Fatoş Ekin",
        "acildurumtelefon": "05324552605",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "79",
        "devre": "101",
        "isim": "Erdal",
        "soyisim": "Ateş",
        "email": "erdalates1471@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05322772433",
        "dogumgunu": "17.02.1950",
        "cemiyetno": "3250",
        "sicilno": "8639",
        "acildurumkisisi": "Eşi Canan",
        "acildurumtelefon": "05063237871",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "80",
        "devre": "85",
        "isim": "Erdoğan",
        "soyisim": "Karakoyunlu",
        "email": "karakoyunlu@gev.org.tr",
        "bolge": "",
        "surekli": "Sürekli Değil",
        "sorumlu": "",
        "isbilgisi": "",
        "telefon": "",
        "dogumgunu": "28.05.1933",
        "cemiyetno": "1750",
        "sicilno": "4582",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "81",
        "devre": "100",
        "isim": "Erdoğan",
        "soyisim": "Şaylı",
        "email": "erdogansayli@yahoo.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Em. Otel md+market işl.+emlakçı",
        "telefon": "05324348108",
        "dogumgunu": "12.06.1949",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Filiz",
        "acildurumtelefon": "05374621710",
        "kangrubu": "B-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "82",
        "devre": "94",
        "isim": "Ergun",
        "soyisim": "Yığın",
        "email": "ergunygn@yahoo.com",
        "bolge": "Kumbahçe",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "Transmer Enerji Madencilik (güneş enerjisi)",
        "telefon": "05422857538",
        "dogumgunu": "18.08.1955",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Mete Yığın",
        "acildurumtelefon": "05322151548",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "83",
        "devre": "121",
        "isim": "Erhan",
        "soyisim": "Öztürk",
        "email": "erhan@greengo.com.tr",
        "bolge": "Bodrum",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05326566236",
        "dogumgunu": "23.01.1971",
        "cemiyetno": "4152",
        "sicilno": null,
        "acildurumkisisi": "Fatih Aytaç",
        "acildurumtelefon": "0549850280",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "84",
        "devre": "101",
        "isim": "Erkan",
        "soyisim": "Murat",
        "email": "ek.murat@gmail.com",
        "bolge": "Gökçebel",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Em. Turizmci",
        "telefon": "05423500079",
        "dogumgunu": "26.07.1950",
        "cemiyetno": "7678",
        "sicilno": null,
        "acildurumkisisi": "Behice Gökakın",
        "acildurumtelefon": "",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "85",
        "devre": "124",
        "isim": "Ersoy",
        "soyisim": "Cankulak",
        "email": "ersoycankulak@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Telekom/Teknoloji",
        "telefon": "05550001905",
        "dogumgunu": "04.04.1973",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Annesi Sevgi",
        "acildurumtelefon": "0535932111",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "86",
        "devre": "95",
        "isim": "Ertan",
        "soyisim": "Tuncel",
        "email": "ertantuncelgs@hotmail.com",
        "bolge": "Koyunbaba",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Ekonomist, em. Diş Teknisyeni,Yeminli Tercüman",
        "telefon": "05398829728",
        "dogumgunu": "07.01.1941",
        "cemiyetno": null,
        "sicilno": "6928",
        "acildurumkisisi": "Leyla Tuncel",
        "acildurumtelefon": "05322313662",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "87",
        "devre": "119",
        "isim": "Ertuğrul",
        "soyisim": "Çalak",
        "email": "ecalak@gmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05322725606",
        "dogumgunu": "30.06.1968",
        "cemiyetno": "3469",
        "sicilno": null,
        "acildurumkisisi": "Eşi Eylem",
        "acildurumtelefon": "05324879605",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "88",
        "devre": "105",
        "isim": "Eşref",
        "soyisim": "Hamamcıoğlu",
        "email": "esref.hamamcioglu@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05322144027",
        "dogumgunu": "07.03.1954",
        "cemiyetno": "2657",
        "sicilno": "7102",
        "acildurumkisisi": "Eşi Nil",
        "acildurumtelefon": "05322637785",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "89",
        "devre": "115",
        "isim": "Fadim",
        "soyisim": "Karakaş",
        "email": "fadimkarakas@gmail.com",
        "bolge": "Koyunbaba",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Em. Öğretmen",
        "telefon": "05326464692",
        "dogumgunu": "15.02.1964",
        "cemiyetno": "2974",
        "sicilno": "7817",
        "acildurumkisisi": "Eşi 116 Canel",
        "acildurumtelefon": "05322923059",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "90",
        "devre": "116",
        "isim": "Fahir",
        "soyisim": "Üzümcü",
        "email": "fahir@akademibric.org",
        "bolge": "Dereköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05322661284",
        "dogumgunu": "28.12.1965",
        "cemiyetno": "3652",
        "sicilno": "12259",
        "acildurumkisisi": "Hülya",
        "acildurumtelefon": "05352155657",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "91",
        "devre": "114",
        "isim": "Faruk",
        "soyisim": "Demir",
        "email": "farukdemirbp@gmail.com",
        "bolge": "Türkbükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Eğitmen",
        "telefon": "05333616065",
        "dogumgunu": "30.08.1963",
        "cemiyetno": "1903",
        "sicilno": "10473",
        "acildurumkisisi": "Sim Demir",
        "acildurumtelefon": "05324732818",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "92",
        "devre": "120",
        "isim": "Fatoş",
        "soyisim": "Altıntop Gürbüz",
        "email": "fatosgurbuz6970@outlook.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "Turizm/Otelcilik/Kuaför",
        "telefon": "05426264565",
        "dogumgunu": "16.06.1969",
        "cemiyetno": "3780",
        "sicilno": null,
        "acildurumkisisi": "Yakup Gürbüz tel:",
        "acildurumtelefon": "05426264565",
        "kangrubu": "AB -",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "93",
        "devre": "101",
        "isim": "Fethi",
        "soyisim": "Gürdöl",
        "email": "fethig@sistemamimarlik.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05322143374",
        "dogumgunu": "21.02.1951",
        "cemiyetno": "5411",
        "sicilno": "10363",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "94",
        "devre": "123",
        "isim": "Fidel",
        "soyisim": "Berber",
        "email": "fidel@b-b.com.tr",
        "bolge": "Yahşi",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "",
        "telefon": "05322437609",
        "dogumgunu": "07.10.1971",
        "cemiyetno": "5370",
        "sicilno": "11426",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "95",
        "devre": "113",
        "isim": "Figen",
        "soyisim": "İdemen",
        "email": "figen_idemen@hotmail.com",
        "bolge": "Türkbükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Elektrik Müh.",
        "telefon": "05325841337",
        "dogumgunu": "08.01.1965",
        "cemiyetno": "",
        "sicilno": "",
        "acildurumkisisi": "Murat İdemen",
        "acildurumtelefon": "05322842292",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "96",
        "devre": "119",
        "isim": "Gaye",
        "soyisim": "Avşar",
        "email": "gayeavsar@gmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05322112499",
        "dogumgunu": "31.05.1968",
        "cemiyetno": "3297",
        "sicilno": "17366",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "97",
        "devre": "119",
        "isim": "Gonca",
        "soyisim": "Sarış",
        "email": "goncasaris@gmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Gonca",
        "isbilgisi": "Eğitimci (Güvercinlik-Adabükü-Meşelik-Mumcular)",
        "telefon": "05326877685",
        "dogumgunu": "28.03.1968",
        "cemiyetno": "3872",
        "sicilno": "10728",
        "acildurumkisisi": "İrem Kasapoğlu",
        "acildurumtelefon": "05323765417",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "98",
        "devre": "113",
        "isim": "Gökalp",
        "soyisim": "Özdikicioğlu",
        "email": "gokalp@lakerida.com",
        "bolge": "Ortakent",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "Turizmci",
        "telefon": "05322810656",
        "dogumgunu": "01.01.1963",
        "cemiyetno": "1852",
        "sicilno": "6498",
        "acildurumkisisi": "Teoman Okyaltirik",
        "acildurumtelefon": "05422963788",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "99",
        "devre": "133",
        "isim": "Gökçe",
        "soyisim": "Devecioğlu",
        "email": "gokcedevecioglu@gmail.com",
        "bolge": "Kumbahçe",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "Reklam Yazarı",
        "telefon": "0535456982",
        "dogumgunu": "23.10.1982",
        "cemiyetno": "6143",
        "sicilno": null,
        "acildurumkisisi": "Kıvanç Gümüş",
        "acildurumtelefon": "05324551765",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "100",
        "devre": "126",
        "isim": "Gökhan",
        "soyisim": "Yarış",
        "email": "gokhanyaris@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05387957971",
        "dogumgunu": "29.10.1973",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Günüm 124 Yarış",
        "acildurumtelefon": "05336313646",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "101",
        "devre": "108",
        "isim": "Gül",
        "soyisim": "Seven Kırelli",
        "email": "gulk111@gmail.com",
        "bolge": "Türkbükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Turizm",
        "telefon": "05444804444",
        "dogumgunu": "08.03.1958",
        "cemiyetno": "1365",
        "sicilno": "12293",
        "acildurumkisisi": "Kızı Nil",
        "acildurumtelefon": "05447788484",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "102",
        "devre": "110",
        "isim": "Gül",
        "soyisim": "Necioğlu Atilla",
        "email": "gulgsl110@yahoo.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05372219530",
        "dogumgunu": "05.07.1959",
        "cemiyetno": "1687",
        "sicilno": null,
        "acildurumkisisi": "Kardeşi Dilek",
        "acildurumtelefon": "05322558532",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "103",
        "devre": "119",
        "isim": "Gül",
        "soyisim": "Aruca",
        "email": "gularuca@hotmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Emekli",
        "telefon": "05333228919",
        "dogumgunu": "12.07.1968",
        "cemiyetno": "3303",
        "sicilno": "10727",
        "acildurumkisisi": "Kızı Kuzey Topuz",
        "acildurumtelefon": "05396825025",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "104",
        "devre": "124",
        "isim": "Gülbin",
        "soyisim": "Demiriz",
        "email": "demirizgsl@yahoo.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "Endüstriel tasarım/İnşaat( Gümüşlük-Dereköy-Peksimet-Kadıkalesi Moderatörü",
        "telefon": "05336807369",
        "dogumgunu": "13.01.1973",
        "cemiyetno": "5453",
        "sicilno": null,
        "acildurumkisisi": "Günüm",
        "acildurumtelefon": "",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "105",
        "devre": "107",
        "isim": "Gülen",
        "soyisim": "Can",
        "email": "gulencan2007@hotmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05324726662",
        "dogumgunu": "16.08.1956",
        "cemiyetno": "1348",
        "sicilno": "15427",
        "acildurumkisisi": "Eşi Emre",
        "acildurumtelefon": "05322180808",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "106",
        "devre": "106",
        "isim": "Gülgün",
        "soyisim": "Dursen",
        "email": "gulgundursen@msn.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "0041787317269",
        "dogumgunu": "05.07.1955",
        "cemiyetno": "6492",
        "sicilno": null,
        "acildurumkisisi": "Cahide Budak",
        "acildurumtelefon": "05373969639",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "107",
        "devre": "116",
        "isim": "Gülmin",
        "soyisim": "Şarman",
        "email": "gulmin.s@conseptur.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "Toplantı, kongre, turizm",
        "telefon": "05324473304",
        "dogumgunu": "20.11.1965",
        "cemiyetno": null,
        "sicilno": "12651",
        "acildurumkisisi": "Eşi Tevfik",
        "acildurumtelefon": "05323366300",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "108",
        "devre": "91",
        "isim": "Gülseven",
        "soyisim": "Sonumut",
        "email": "sonumutgul@hotmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05422146712",
        "dogumgunu": "07.11.1940",
        "cemiyetno": "5225",
        "sicilno": "3694",
        "acildurumkisisi": "Oğlu Güldener",
        "acildurumtelefon": "0032475453994",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "109",
        "devre": "110",
        "isim": "Günaydın",
        "soyisim": "Özcebe",
        "email": "gunaydinozcebe@hotmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Em. Avukat",
        "telefon": "05326149588",
        "dogumgunu": "29.07.1959",
        "cemiyetno": "1477",
        "sicilno": "6808",
        "acildurumkisisi": "Handan Özcebe",
        "acildurumtelefon": "05322854483",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "110",
        "devre": "125",
        "isim": "Günberi",
        "soyisim": "Tuncer",
        "email": "gunberituncer@gmail.com",
        "bolge": "Ortakent",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "İletişim/PR",
        "telefon": "05322048594",
        "dogumgunu": "17.10.1974",
        "cemiyetno": "6811",
        "sicilno": "14756",
        "acildurumkisisi": "Eşi Şevket Dal",
        "acildurumtelefon": "05322141284",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "111",
        "devre": "94",
        "isim": "Gündüz",
        "soyisim": "Nalbantoğlu",
        "email": "gunduz@gunduztravel.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Gündüz Seyahat Acentası",
        "telefon": "054222339901",
        "dogumgunu": "14.05.1943",
        "cemiyetno": "2730",
        "sicilno": "6877",
        "acildurumkisisi": "Veysel",
        "acildurumtelefon": "05323976796",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "112",
        "devre": "101",
        "isim": "Gündüz",
        "soyisim": "Uğural",
        "email": "gunduz.ugural@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "Em. Mak. Müh.",
        "telefon": "05326161541",
        "dogumgunu": "13.06.1949",
        "cemiyetno": "1253",
        "sicilno": "7103",
        "acildurumkisisi": "Oğlu Yavuz",
        "acildurumtelefon": "05366368085",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "113",
        "devre": "133",
        "isim": "Güneş",
        "soyisim": "Çıgay",
        "email": "gunescigay@gmail.com",
        "bolge": "Kadıkalesi",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Çevirmen/Kitap-Dergi Editörü",
        "telefon": "05385585187",
        "dogumgunu": "17.06.1982",
        "cemiyetno": "6345",
        "sicilno": null,
        "acildurumkisisi": "Alev Çıgay",
        "acildurumtelefon": "05323470555",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "114",
        "devre": "94",
        "isim": "Güngör",
        "soyisim": "Tekçe",
        "email": "gungortekce@gungortekce.com",
        "bolge": "Gölköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "Şair-Yazar-Sosyolog-Em. TRT programcısı",
        "telefon": "05359281806",
        "dogumgunu": "17.09.1937",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Gülay",
        "acildurumtelefon": "",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "115",
        "devre": "113",
        "isim": "Güniz",
        "soyisim": "Bursalı",
        "email": "gunizbursali@yahoo.com.tr",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05333661073",
        "dogumgunu": "15.03.1962",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi 112 Yüksel Bursalı",
        "acildurumtelefon": "05326534554",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "116",
        "devre": "124",
        "isim": "Günüm",
        "soyisim": "Gürsoylar",
        "email": "gunumg@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Turgutreis-Kadıkalesi moderatörü",
        "telefon": "05336313646",
        "dogumgunu": "21.01.1973",
        "cemiyetno": "6559",
        "sicilno": null,
        "acildurumkisisi": "Gökhan Yarış",
        "acildurumtelefon": "05387957971",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "117",
        "devre": "123",
        "isim": "Güray",
        "soyisim": "Ertok",
        "email": "gertok@hotmail.com",
        "bolge": "Konacık",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Mimar",
        "telefon": "05323352218",
        "dogumgunu": "11.03.1972",
        "cemiyetno": "4911",
        "sicilno": "9190",
        "acildurumkisisi": "Eşi",
        "acildurumtelefon": "05326437426",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "118",
        "devre": "101",
        "isim": "Güven",
        "soyisim": "Andıç",
        "email": "guvenandic@hotmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05358260064",
        "dogumgunu": "13.01.1948",
        "cemiyetno": "6072",
        "sicilno": "9509",
        "acildurumkisisi": "Eşi Lale",
        "acildurumtelefon": "05323711464",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "119",
        "devre": "105",
        "isim": "Halil",
        "soyisim": "Akgül",
        "email": "hakgul1@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Yük.Makina. Müh.",
        "telefon": "05336014138",
        "dogumgunu": "26.05.1954",
        "cemiyetno": "7129",
        "sicilno": "4907",
        "acildurumkisisi": "Selen Akgül",
        "acildurumtelefon": "05324230932",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "120",
        "devre": "106",
        "isim": "Halit",
        "soyisim": "Güven",
        "email": "halit.guven@sim-sistem.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "",
        "telefon": "05322716773",
        "dogumgunu": "24.11.1955",
        "cemiyetno": "2503",
        "sicilno": "7074",
        "acildurumkisisi": "Çiğdem Güven (112)",
        "acildurumtelefon": "05322716773",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "121",
        "devre": "94",
        "isim": "Haluk",
        "soyisim": "Ogan",
        "email": "oganh@ymail.com",
        "bolge": "Torba",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05378597202",
        "dogumgunu": "07.10.1942",
        "cemiyetno": "5528",
        "sicilno": "11742",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "122",
        "devre": "98",
        "isim": "Haluk",
        "soyisim": "İşmen",
        "email": "mhi8150@gmail.com",
        "bolge": "Mumcular",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Y.İnş.Müh.",
        "telefon": "05322111198",
        "dogumgunu": "08.01.1950",
        "cemiyetno": "1250",
        "sicilno": "10365",
        "acildurumkisisi": "Eşi Selva",
        "acildurumtelefon": "05333527670",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "123",
        "devre": "104",
        "isim": "Haluk",
        "soyisim": "Öndoğan",
        "email": "haluk.ondogan@gmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Bilgi Teknolojileri Denetim Danışman",
        "telefon": "05326125029",
        "dogumgunu": "29.05.1954",
        "cemiyetno": "2794",
        "sicilno": "8634",
        "acildurumkisisi": "Eşi Füsun",
        "acildurumtelefon": "05336487702",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "124",
        "devre": "131",
        "isim": "Harun",
        "soyisim": "Karadeniz",
        "email": "harun@attachetours.com",
        "bolge": "Konacık",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Turizm",
        "telefon": "05065486982",
        "dogumgunu": "18.06.1980",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Seda",
        "acildurumtelefon": "05335646472",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "125",
        "devre": "110",
        "isim": "Hasan",
        "soyisim": "Ormanlar",
        "email": "hasan@griptr.com",
        "bolge": "Torba",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05322616009",
        "dogumgunu": "08.07.1959",
        "cemiyetno": "3674",
        "sicilno": "16965",
        "acildurumkisisi": "Pınar Göktürk",
        "acildurumtelefon": "05326528939",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "126",
        "devre": "109",
        "isim": "Hayim",
        "soyisim": "Babür",
        "email": "hayimbabur@gmail.com",
        "bolge": "Bağla",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05323110244",
        "dogumgunu": "26.01.1958",
        "cemiyetno": "5183",
        "sicilno": null,
        "acildurumkisisi": "Eşi Caroline",
        "acildurumtelefon": "05322572467",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "127",
        "devre": "115",
        "isim": "Hikmet",
        "soyisim": "Eren",
        "email": "hikmeteren@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05324660299",
        "dogumgunu": "29.05.1964",
        "cemiyetno": "2197",
        "sicilno": "7767",
        "acildurumkisisi": "Aydın Topdemir",
        "acildurumtelefon": "05322116957",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "128",
        "devre": "106",
        "isim": "Hülya",
        "soyisim": "Uğur Tanrıöver",
        "email": "hulyatanriover@gmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Sosyolog, akademisyen",
        "telefon": "05324864271",
        "dogumgunu": "29.12.1955",
        "cemiyetno": "5135",
        "sicilno": "12191",
        "acildurumkisisi": "Eşi Ömer",
        "acildurumtelefon": "05352136811",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "129",
        "devre": "111",
        "isim": "Hümeyra",
        "soyisim": "Durgut Kurmuş",
        "email": "humeyrakurmus@hotmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05423132354",
        "dogumgunu": "06.03.1960",
        "cemiyetno": "6808",
        "sicilno": null,
        "acildurumkisisi": "Uğur Kurmuş",
        "acildurumtelefon": "05323226015",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "130",
        "devre": "126",
        "isim": "Hüseyin",
        "soyisim": "Ceylan",
        "email": "ceylanhus@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Esnaf/Şarküteri/Trakya peynircilik",
        "telefon": "05320605301",
        "dogumgunu": "22.06.1975",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Annesi Sema",
        "acildurumtelefon": "05324821308",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "131",
        "devre": "100",
        "isim": "Hüseyin Nihat",
        "soyisim": "Birgül",
        "email": "hnbirgul@gmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "Makina Yük. Müh. (İTÜ)",
        "telefon": "05323213995",
        "dogumgunu": "15.06.1949",
        "cemiyetno": "3133",
        "sicilno": "4911",
        "acildurumkisisi": "Nüket Birgül",
        "acildurumtelefon": "05305857992",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "132",
        "devre": "124",
        "isim": "Ilgaz",
        "soyisim": "Şartkor",
        "email": "ilgazsartkor@gmail.com",
        "bolge": "Adabükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Peyzaj Mimarı/ Turkcell-Telekom İş Geliştirme Uz.",
        "telefon": "05322101211",
        "dogumgunu": "05.08.1973",
        "cemiyetno": "5201",
        "sicilno": null,
        "acildurumkisisi": "Suhan Cerrahoğlu",
        "acildurumtelefon": "05326939366",
        "kangrubu": "AB-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "133",
        "devre": "98",
        "isim": "İbrahim",
        "soyisim": "Özyürük",
        "email": "İbrahimozyuruk@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Kimya Mühendisi",
        "telefon": "05325824501",
        "dogumgunu": "17.04.1947",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Lale Özyürük",
        "acildurumtelefon": "05324158111",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "134",
        "devre": "109",
        "isim": "İsmail",
        "soyisim": "Yeter",
        "email": "ismailyeter@gmail.com",
        "bolge": "Dağbelen",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Yük. Mimar (İTÜ)",
        "telefon": "05322837093",
        "dogumgunu": "04.11.1957",
        "cemiyetno": "2600",
        "sicilno": "11908",
        "acildurumkisisi": "Eşi Pınar",
        "acildurumtelefon": "05353998435",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "135",
        "devre": "115",
        "isim": "İzzeddin",
        "soyisim": "Çalışlar",
        "email": "izzeddin@me.com",
        "bolge": "Kumbahçe",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05323446581",
        "dogumgunu": "02.07.1964",
        "cemiyetno": "2148",
        "sicilno": "9175",
        "acildurumkisisi": "Kardeşi Hasan",
        "acildurumtelefon": "05337127784",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "136",
        "devre": "142",
        "isim": "Kaan",
        "soyisim": "Artukoğlu",
        "email": "kaan-142@hotmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Acıbadem Tıp Merkezi İş Geliştirme Sorumlusu",
        "telefon": "05364158315",
        "dogumgunu": "16.11.19991",
        "cemiyetno": "7150",
        "sicilno": "15181",
        "acildurumkisisi": "Handan Yıldız",
        "acildurumtelefon": "05426205042",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "137",
        "devre": "125",
        "isim": "Kartal",
        "soyisim": "Kaya",
        "email": "kartal125@yahoo.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Rehber (Bitez moderatörü)",
        "telefon": "05326548875",
        "dogumgunu": "05.02.1975",
        "cemiyetno": "5689",
        "sicilno": "10534",
        "acildurumkisisi": "Kardeşi Başak",
        "acildurumtelefon": "05323323849",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "138",
        "devre": "94",
        "isim": "Kaya",
        "soyisim": "Tiryakioğlu",
        "email": "kaya.tiryakioglu@yahoo.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Em.Mak.Mühendisi",
        "telefon": "05325115597",
        "dogumgunu": "28.01.1943",
        "cemiyetno": "2079",
        "sicilno": "3836",
        "acildurumkisisi": "Onur Tiryakioğlu",
        "acildurumtelefon": "05305106474",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "139",
        "devre": "105",
        "isim": "Kemal",
        "soyisim": "Ergezen",
        "email": "kemal.ergezen@gmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Gonca",
        "isbilgisi": "Elektrik Müh. (İTÜ)",
        "telefon": "05325017738",
        "dogumgunu": "10.11.1954",
        "cemiyetno": "3035",
        "sicilno": "10078",
        "acildurumkisisi": "Eşi Gülten",
        "acildurumtelefon": "05327919297",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "140",
        "devre": "120",
        "isim": "Kerem",
        "soyisim": "Kemerli",
        "email": "kerem@kemerli.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "İnşaat Müh.",
        "telefon": "05322872220",
        "dogumgunu": "26.02.1969",
        "cemiyetno": "3804",
        "sicilno": "8439",
        "acildurumkisisi": "Akın Ketenci",
        "acildurumtelefon": "05337668517",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "141",
        "devre": "118",
        "isim": "Kıvılcım",
        "soyisim": "Boztepe",
        "email": "kboztepe@yahoo.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05327490467",
        "dogumgunu": "17.04.1967",
        "cemiyetno": "2938",
        "sicilno": "7759",
        "acildurumkisisi": "Kayınbirader Zafer",
        "acildurumtelefon": "05327415146",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "142",
        "devre": "108",
        "isim": "Lale",
        "soyisim": "Özkut",
        "email": "laleozkut@yahoo.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Ekonomist LGS koçu sayısal öğretmeni",
        "telefon": "05335755695",
        "dogumgunu": "23.07.1957",
        "cemiyetno": "1406",
        "sicilno": "7826",
        "acildurumkisisi": "Sevgi Karabulak",
        "acildurumtelefon": "05352809525",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "143",
        "devre": "104",
        "isim": "Lemi",
        "soyisim": "Tu-Men",
        "email": "lemitumen@yahoo.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "Yük. İnş. Müh.",
        "telefon": "05324654342",
        "dogumgunu": "02.12.1953",
        "cemiyetno": "4297",
        "sicilno": "7904",
        "acildurumkisisi": "Eşi Aylin",
        "acildurumtelefon": "05365178844",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "144",
        "devre": "108",
        "isim": "Levent",
        "soyisim": "Dönmez",
        "email": "leventdonmez2@gmail.com",
        "bolge": "Bağla",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05492671832",
        "dogumgunu": "08.09.1957",
        "cemiyetno": "4219",
        "sicilno": "9139",
        "acildurumkisisi": "Eşi Çiğdem",
        "acildurumtelefon": "05322962284",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "145",
        "devre": "107",
        "isim": "Macit",
        "soyisim": "Umul",
        "email": "umulm@hotmail.com",
        "bolge": "Kadıkalesi",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "Doktor KBB",
        "telefon": "05322442123",
        "dogumgunu": "17.03.1957",
        "cemiyetno": "4087",
        "sicilno": "8772",
        "acildurumkisisi": "Eşi 111 Dilek",
        "acildurumtelefon": "05336070020",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "146",
        "devre": "114",
        "isim": "Mahmut",
        "soyisim": "Kaşıkçı",
        "email": "mahmutkasikci@hotmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Hukukçu",
        "telefon": "05323147034",
        "dogumgunu": "30.05.1963",
        "cemiyetno": "5617",
        "sicilno": null,
        "acildurumkisisi": "114 Osman Elibol",
        "acildurumtelefon": "05326113225",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "147",
        "devre": "84",
        "isim": "Mehmet",
        "soyisim": "Kefeli",
        "email": "-",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "",
        "telefon": "05325447689",
        "dogumgunu": "03.01.1932",
        "cemiyetno": "1140",
        "sicilno": "3946",
        "acildurumkisisi": "Eşi Tülin",
        "acildurumtelefon": "05325447689",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "148",
        "devre": "99",
        "isim": "Mehmet",
        "soyisim": "Kozikoğlu",
        "email": "mehmetkozikoglu@gmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Em. Kimya Yük. Müh.",
        "telefon": "05322313718",
        "dogumgunu": "28.09.1948",
        "cemiyetno": "3097",
        "sicilno": "4312",
        "acildurumkisisi": "Ali Güler",
        "acildurumtelefon": "05322550404",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "149",
        "devre": "106",
        "isim": "Mehmet",
        "soyisim": "Özkal",
        "email": "mozkal@outlook.fr",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05342343692",
        "dogumgunu": "20.05.1955",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Selma",
        "acildurumtelefon": "05394866692",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "150",
        "devre": "109",
        "isim": "Mehmet",
        "soyisim": "Vardar",
        "email": "mvmehmetvardar@hotmail.com",
        "bolge": "Yakaköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05326389492",
        "dogumgunu": "11.01.1958",
        "cemiyetno": "1457",
        "sicilno": "6752",
        "acildurumkisisi": "108 Gülseren Kırdemir",
        "acildurumtelefon": "05326389491",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "151",
        "devre": "109",
        "isim": "Mehmet",
        "soyisim": "Seraç",
        "email": "seracmehmet@gmail.com",
        "bolge": "Geriş",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Emekli",
        "telefon": 5322771891,
        "dogumgunu": "03.04.1958",
        "cemiyetno": "1963",
        "sicilno": "7224",
        "acildurumkisisi": "Oğlu Sarp Seraç",
        "acildurumtelefon": "05078626842",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "152",
        "devre": "121",
        "isim": "Mehmet",
        "soyisim": "Üstün",
        "email": "mehmet.unikon@gmail.com",
        "bolge": "Ortakent",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "",
        "telefon": "05366841280",
        "dogumgunu": "03.04.1970",
        "cemiyetno": "5626",
        "sicilno": null,
        "acildurumkisisi": "Kızı Melis",
        "acildurumtelefon": "05395455500",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "153",
        "devre": "105",
        "isim": "Mehmet Avni",
        "soyisim": "Aksu",
        "email": "maksu434@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Em. Müh./Rehber/Acentacı",
        "telefon": "05323211274",
        "dogumgunu": "09.03.1954",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Nilgün",
        "acildurumtelefon": "05324643235",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "154",
        "devre": "94",
        "isim": "Mehmet Candan",
        "soyisim": "Akad",
        "email": "mcakad@gmail.com",
        "bolge": "Yahşi",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin131",
        "isbilgisi": "Öğretim görevlisi Kadir Has Üni. Hukuk prof.",
        "telefon": "0532 277 82 53",
        "dogumgunu": "19.01.1943",
        "cemiyetno": "3835",
        "sicilno": "4598",
        "acildurumkisisi": "Oğlu Sinan",
        "acildurumtelefon": "05323742054",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "155",
        "devre": "114",
        "isim": "Mehmet Sinan",
        "soyisim": "Çakım",
        "email": "sinancakim@gmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Turizm",
        "telefon": "05334377180",
        "dogumgunu": "01.02.1963",
        "cemiyetno": "1906",
        "sicilno": "7774",
        "acildurumkisisi": "Müjgan Ateş",
        "acildurumtelefon": "05325268830",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "156",
        "devre": "127",
        "isim": "Mehmet Sinan",
        "soyisim": "Akad",
        "email": "mesiak@gmail.com",
        "bolge": "Konacık",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Konacık Moderatörü",
        "telefon": "05323742054",
        "dogumgunu": "15.09.1976",
        "cemiyetno": "8051",
        "sicilno": "16020",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "157",
        "devre": "121",
        "isim": "Meltem",
        "soyisim": "Evrensel",
        "email": "evrensel.m@gmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05323419902",
        "dogumgunu": "28.02.1970",
        "cemiyetno": "4123",
        "sicilno": "14423",
        "acildurumkisisi": "Esin Tuğ",
        "acildurumtelefon": "05357898308",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "158",
        "devre": "98",
        "isim": "Merih",
        "soyisim": "Övül",
        "email": "merihovul@hotmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05333671406",
        "dogumgunu": "30.07.1947",
        "cemiyetno": "1271",
        "sicilno": "8411",
        "acildurumkisisi": "Belkıs Övül",
        "acildurumtelefon": "05337206340",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "159",
        "devre": "107",
        "isim": "Mert",
        "soyisim": "Ökter",
        "email": "mertokter@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Sistem analist programcı",
        "telefon": "05322438868",
        "dogumgunu": "07.12.1955",
        "cemiyetno": "2601",
        "sicilno": "8946",
        "acildurumkisisi": "Hicran Ökter",
        "acildurumtelefon": "05337650348",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "160",
        "devre": "114",
        "isim": "Mert",
        "soyisim": "Yücesan",
        "email": "mert.yucesan63@gmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Sigortacı",
        "telefon": "05322115992",
        "dogumgunu": "08.03.1963",
        "cemiyetno": "1913",
        "sicilno": "12158",
        "acildurumkisisi": "Sibel Yücesan",
        "acildurumtelefon": "05322618524",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "161",
        "devre": "124",
        "isim": "Merve",
        "soyisim": "Gürkaynak",
        "email": "mervegurkaynak@gmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Merve",
        "isbilgisi": "Kongre ve toplantı Yön. (Akyarlar-Bağla moderatörü)",
        "telefon": "05336587204",
        "dogumgunu": "28.08.1973",
        "cemiyetno": "5295",
        "sicilno": "12258",
        "acildurumkisisi": "İslam Eren",
        "acildurumtelefon": "05378190120",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "162",
        "devre": "109",
        "isim": "Metin",
        "soyisim": "Küçükercan",
        "email": "mkucukercan@gmail.com",
        "bolge": "Dağbelen",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Doktor",
        "telefon": "05323867280",
        "dogumgunu": "13.11.1957",
        "cemiyetno": "2685",
        "sicilno": "11907",
        "acildurumkisisi": "Eşi İnci",
        "acildurumtelefon": "05337474202",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "163",
        "devre": "94",
        "isim": "Muhittin",
        "soyisim": "Yamaç",
        "email": "mu.yamac43@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Em. Elektrik Yük. Müh.",
        "telefon": "05326138556",
        "dogumgunu": "03.10.1943",
        "cemiyetno": "1623",
        "sicilno": "6927",
        "acildurumkisisi": "Gündüz Nalbantoğlu",
        "acildurumtelefon": "",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "164",
        "devre": "101",
        "isim": "Murat",
        "soyisim": "Sağlav",
        "email": "msaglav@gmail.com",
        "bolge": "Kumbahçe",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05366030822",
        "dogumgunu": "09.12.1949",
        "cemiyetno": null,
        "sicilno": "10527",
        "acildurumkisisi": "Ben",
        "acildurumtelefon": "",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "165",
        "devre": "106",
        "isim": "Murat",
        "soyisim": "Altay",
        "email": "mmurataltay@yahoo.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "İnşaat Müh.",
        "telefon": "05333566632",
        "dogumgunu": "10.05.1955",
        "cemiyetno": "4351",
        "sicilno": "10120",
        "acildurumkisisi": "Eşi Nimet",
        "acildurumtelefon": "05324733060",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "166",
        "devre": "111",
        "isim": "Murat",
        "soyisim": "Doral",
        "email": "md30144@yahoo.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05059831296",
        "dogumgunu": "30.03.1960",
        "cemiyetno": "4329",
        "sicilno": "5547",
        "acildurumkisisi": "Sibel Doral",
        "acildurumtelefon": "05077488830",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "167",
        "devre": "121",
        "isim": "Murat",
        "soyisim": "Kılıç",
        "email": "muratkilic@impa.com.tr",
        "bolge": "Ortakent",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "",
        "telefon": "05326145244",
        "dogumgunu": "10.06.1970",
        "cemiyetno": "4136",
        "sicilno": "9342",
        "acildurumkisisi": "Eşi Sinem",
        "acildurumtelefon": "05322617108",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "168",
        "devre": "114",
        "isim": "Murat Evren",
        "soyisim": "İdemen",
        "email": "murat_idemen@hotmail.com",
        "bolge": "Türkbükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Ekonomist",
        "telefon": "05322842292",
        "dogumgunu": "10.06.1970",
        "cemiyetno": "5553",
        "sicilno": "10328",
        "acildurumkisisi": "Figen İdemen",
        "acildurumtelefon": "05325841337",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "169",
        "devre": "93",
        "isim": "Mustafa",
        "soyisim": "Göksel",
        "email": "gokselm@hotmail.com",
        "bolge": "Türkbükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Em. Yük. Elektrik Müh.",
        "telefon": "05359764422",
        "dogumgunu": "25.09.1942",
        "cemiyetno": "3186",
        "sicilno": null,
        "acildurumkisisi": "Eşi Sandy",
        "acildurumtelefon": "05305677422",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "170",
        "devre": "101",
        "isim": "Mustafa",
        "soyisim": "Madenli",
        "email": "mmadenli@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Scuba eğ.ant.+yacht kap.",
        "telefon": "05325727266",
        "dogumgunu": "01.01.1950",
        "cemiyetno": "4588",
        "sicilno": "7154",
        "acildurumkisisi": "Serap",
        "acildurumtelefon": "05336005886",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "171",
        "devre": "103",
        "isim": "Mustafa",
        "soyisim": "Mennan",
        "email": "mmennan@yahoo.fr",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05333165100",
        "dogumgunu": "15.02.1952",
        "cemiyetno": "5855",
        "sicilno": "8071",
        "acildurumkisisi": "Eşi Zeynep",
        "acildurumtelefon": "05322238015",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "172",
        "devre": "140",
        "isim": "Mustafa Alper",
        "soyisim": "Ergezen",
        "email": "alpergsl@gmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Gonca",
        "isbilgisi": "Elektrik/Elektronik Mühendisi",
        "telefon": "05358406883",
        "dogumgunu": "28.01.1989",
        "cemiyetno": "6958",
        "sicilno": "14600",
        "acildurumkisisi": "Babası 105 Kemal Ergezen",
        "acildurumtelefon": "",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "173",
        "devre": "98",
        "isim": "Naci",
        "soyisim": "Akıncı",
        "email": "akincinaci@yahoo.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Can",
        "isbilgisi": "Em. Büyükelçi",
        "telefon": "05383634210",
        "dogumgunu": "20.04.1948",
        "cemiyetno": null,
        "sicilno": "10019",
        "acildurumkisisi": "Zeynep Akıncı",
        "acildurumtelefon": "05394729555",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "174",
        "devre": "101",
        "isim": "Nadir",
        "soyisim": "Ilıca",
        "email": "ilicanadir@yahoo.com",
        "bolge": "Küçükbük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05523424742",
        "dogumgunu": "01.01.1950",
        "cemiyetno": "1310",
        "sicilno": "8107",
        "acildurumkisisi": "Eşi Nimet",
        "acildurumtelefon": "05323424742",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "175",
        "devre": "117",
        "isim": "Nafiz",
        "soyisim": "Altuğ",
        "email": "c.nafiz@hotmail.com",
        "bolge": "Dereköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05398788725",
        "dogumgunu": "01.01.1966",
        "cemiyetno": "5055",
        "sicilno": null,
        "acildurumkisisi": "Aynur Sözbir",
        "acildurumtelefon": "05304182585",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "176",
        "devre": "106",
        "isim": "Nail",
        "soyisim": "Yılmaz",
        "email": "nailyil64@gmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "Endüstri müh.",
        "telefon": "05322829351",
        "dogumgunu": "09.01.1955",
        "cemiyetno": "2800",
        "sicilno": "12264",
        "acildurumkisisi": "Şükrü Yılmaz",
        "acildurumtelefon": "05373031114",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "177",
        "devre": "121",
        "isim": "Necla",
        "soyisim": "Albayrak",
        "email": "aynadaki@hotmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Rehber/İspanyolca",
        "telefon": "05322818377",
        "dogumgunu": "",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Volkan Aksu",
        "acildurumtelefon": "05336832292",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "178",
        "devre": "100",
        "isim": "Nejat",
        "soyisim": "Bengil",
        "email": "nejat.bengil@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "İnşaat Taahhüt İşleri",
        "telefon": "05422344855",
        "dogumgunu": "25.06.1949",
        "cemiyetno": "3917",
        "sicilno": "9108",
        "acildurumkisisi": "Oğlu Boysan",
        "acildurumtelefon": "05333935363",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "179",
        "devre": "104",
        "isim": "Nejat",
        "soyisim": "Özcan",
        "email": "nejatozcan@hotmail.com",
        "bolge": "Datça",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05052316421",
        "dogumgunu": "03.05.1953",
        "cemiyetno": "6464",
        "sicilno": null,
        "acildurumkisisi": "Oğlu Mert",
        "acildurumtelefon": "05057461664",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "180",
        "devre": "119",
        "isim": "Nermin",
        "soyisim": "Cıbıroğlu",
        "email": "nermincelikoglu@yahoo.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05322131924",
        "dogumgunu": "22.04.1942",
        "cemiyetno": "5923",
        "sicilno": "14765",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "181",
        "devre": "116",
        "isim": "Nihal",
        "soyisim": "Özfırat",
        "email": "nihalozfirat@yahoo.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Psikolog/Çift ve Aile Terapisti",
        "telefon": "05336687103",
        "dogumgunu": "28.04.1965",
        "cemiyetno": "2293",
        "sicilno": "7782",
        "acildurumkisisi": "Murat Özfırat",
        "acildurumtelefon": "05323210485",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "182",
        "devre": "106",
        "isim": "Nihat",
        "soyisim": "Karaköse",
        "email": "nihat.karakose@sonatdanismanlik.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Sonat Sigorta ve risk danış. YKÜ",
        "telefon": "05322852274",
        "dogumgunu": "11.02.1955",
        "cemiyetno": "3058",
        "sicilno": "4983",
        "acildurumkisisi": "Eşi Sonat",
        "acildurumtelefon": "05322914729",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "183",
        "devre": "110",
        "isim": "Nilüfer",
        "soyisim": "Akdoğanlar",
        "email": "nilufer_akdoganlar@yahoo.fr",
        "bolge": "Torba",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Emekli Sigortacı",
        "telefon": "05327646510",
        "dogumgunu": "17.10.1957",
        "cemiyetno": "1505",
        "sicilno": "18351",
        "acildurumkisisi": "Eşi Fikri",
        "acildurumtelefon": "05415719696",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "184",
        "devre": "118",
        "isim": "Nilüfer",
        "soyisim": "Cevher",
        "email": "nilufercevher9@gmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "",
        "telefon": "05326073636",
        "dogumgunu": "21.11.1967",
        "cemiyetno": "2918",
        "sicilno": "8540",
        "acildurumkisisi": "Eşi Serhat",
        "acildurumtelefon": "05324015335",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "185",
        "devre": "113",
        "isim": "Nimet",
        "soyisim": "Yardımcı",
        "email": "nimetyardimci@gmail.com",
        "bolge": "Yakaköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05424232855",
        "dogumgunu": "01.07.1962",
        "cemiyetno": "3995",
        "sicilno": "9700",
        "acildurumkisisi": "Seramik-Heykel",
        "acildurumtelefon": "",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "186",
        "devre": "126",
        "isim": "Oğuz",
        "soyisim": "Kırmızıyeşil",
        "email": "oguzkirmiziyesil@hotmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "",
        "telefon": "05322767111",
        "dogumgunu": "04.06.1975",
        "cemiyetno": "5361",
        "sicilno": "13871",
        "acildurumkisisi": "Zeren Kırmızıyeşil",
        "acildurumtelefon": "05324120595",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "187",
        "devre": "89",
        "isim": "Oktay",
        "soyisim": "Üner",
        "email": "-",
        "bolge": "Adabükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "",
        "telefon": "05322637498",
        "dogumgunu": "27.12.1938",
        "cemiyetno": "2099",
        "sicilno": "8055",
        "acildurumkisisi": "Oğlu Alp",
        "acildurumtelefon": "05327017774",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "188",
        "devre": "94",
        "isim": "Oktay",
        "soyisim": "Orhon",
        "email": "oktayorhon@gmail.com",
        "bolge": "Ortakent",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin131",
        "isbilgisi": "Y. Müh. Mimar",
        "telefon": "05322454941",
        "dogumgunu": "07.04.1943",
        "cemiyetno": "2612",
        "sicilno": "8391",
        "acildurumkisisi": "Eşi İmre",
        "acildurumtelefon": "05323714530",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "189",
        "devre": "113",
        "isim": "Oktay",
        "soyisim": "Ünaldı",
        "email": "piqwiz@gmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05333393770",
        "dogumgunu": "15.11.1961",
        "cemiyetno": "2143",
        "sicilno": null,
        "acildurumkisisi": "Ablası Şule",
        "acildurumtelefon": "05324660195",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "190",
        "devre": "117",
        "isim": "Orbay",
        "soyisim": "Sayu",
        "email": "orbaysayu@gmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05377305155",
        "dogumgunu": "26.12.1966",
        "cemiyetno": null,
        "sicilno": "7744",
        "acildurumkisisi": "Ablası Emine",
        "acildurumtelefon": "05322158376",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "191",
        "devre": "90",
        "isim": "Oryal",
        "soyisim": "Güventürk",
        "email": "oryal@oryal.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05424375125",
        "dogumgunu": "14.07.1939",
        "cemiyetno": "1105",
        "sicilno": "7597",
        "acildurumkisisi": "Meral",
        "acildurumtelefon": "05422157846",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "192",
        "devre": "131",
        "isim": "Ozan",
        "soyisim": "Sabuncuoğlu",
        "email": "ozansabuncuoglu131@gmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05336848182",
        "dogumgunu": "03.09.1980",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Nilay",
        "acildurumtelefon": "05357028271",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "193",
        "devre": "121",
        "isim": "Ozan",
        "soyisim": "Eşiyok",
        "email": "ozan@sonproduction.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Menajer+Organizatör+İşletmeci",
        "telefon": "05326757285",
        "dogumgunu": "30.10.1970",
        "cemiyetno": "4150",
        "sicilno": "8524",
        "acildurumkisisi": "Olga Eşiyok",
        "acildurumtelefon": "05304910837",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "194",
        "devre": "105",
        "isim": "Ömer",
        "soyisim": "Yazgan",
        "email": "omaryazgan@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05362503706",
        "dogumgunu": "22.08.1952",
        "cemiyetno": "2809",
        "sicilno": null,
        "acildurumkisisi": "Eşi Yasemin",
        "acildurumtelefon": "",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "195",
        "devre": "106",
        "isim": "Ömer",
        "soyisim": "Kızıltan",
        "email": "yesom60@gmail.com",
        "bolge": "Yahşi",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "",
        "telefon": "05324343080",
        "dogumgunu": "24.11.1954",
        "cemiyetno": "7582",
        "sicilno": "6887",
        "acildurumkisisi": "Eşi Yeşim",
        "acildurumtelefon": "05324438449",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "196",
        "devre": "106",
        "isim": "Ömer",
        "soyisim": "Devecioğlu",
        "email": "devecioglu.omer@gmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Em. Muhasebe Md.",
        "telefon": "05325953634",
        "dogumgunu": "05.11.1955",
        "cemiyetno": "1450",
        "sicilno": "8156",
        "acildurumkisisi": "Aysun Devecioğlu",
        "acildurumtelefon": "05426121210",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "197",
        "devre": "101",
        "isim": "Ömer Salih",
        "soyisim": "Ülgen",
        "email": "omer_ulgen@yahoo.com",
        "bolge": "Bağla",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05422153031",
        "dogumgunu": "10.06.1950",
        "cemiyetno": "4463",
        "sicilno": "8958",
        "acildurumkisisi": "Leyla Ülgen",
        "acildurumtelefon": "05378301939",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "198",
        "devre": "134",
        "isim": "Öncü",
        "soyisim": "Öztekin",
        "email": "oncuoztekin@gmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "Yazılım + Acentacı (Bodrum moderatörü)",
        "telefon": "05322065438",
        "dogumgunu": "27.02.1984",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Muzaffer Doğru",
        "acildurumtelefon": "05425231230",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "199",
        "devre": "116",
        "isim": "Pınar",
        "soyisim": "Özgen Elaldı",
        "email": "pinar.ozgen@hotmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05323182575",
        "dogumgunu": "07.05.1965",
        "cemiyetno": "3019",
        "sicilno": null,
        "acildurumkisisi": "Eşi Oktay",
        "acildurumtelefon": "05333385202",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "200",
        "devre": "106",
        "isim": "Raif",
        "soyisim": "Eşkinat",
        "email": "raifeskinat@gmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Endüstri Müh.",
        "telefon": "05323642055",
        "dogumgunu": "28.08.1955",
        "cemiyetno": null,
        "sicilno": "12154",
        "acildurumkisisi": "Neziha",
        "acildurumtelefon": "05305893370",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "201",
        "devre": "104",
        "isim": "Rasim",
        "soyisim": "Güvendi",
        "email": "rasim@guvendi.eu",
        "bolge": "Datça",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05380915398",
        "dogumgunu": "21.07.1953",
        "cemiyetno": "1323",
        "sicilno": null,
        "acildurumkisisi": ".",
        "acildurumtelefon": "00336003347",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "202",
        "devre": "106",
        "isim": "Refik",
        "soyisim": "Çeltikci",
        "email": "refikceltikci@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Avukat",
        "telefon": "05322664096",
        "dogumgunu": "13.01.1955",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Kumre Çeltikci",
        "acildurumtelefon": "05322968034",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "203",
        "devre": "120",
        "isim": "Remzi",
        "soyisim": "Sanver",
        "email": "mremzisanver@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Öğretim Üyesi",
        "telefon": "05305042303",
        "dogumgunu": "07.06.1970",
        "cemiyetno": "3808",
        "sicilno": "9160",
        "acildurumkisisi": "Eşi İpek Sanver",
        "acildurumtelefon": "",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "204",
        "devre": "109",
        "isim": "Rıza",
        "soyisim": "Erdeğirmenci",
        "email": "rizaerd@gmail.com",
        "bolge": "Türkbükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "İktisatçı",
        "telefon": "05323243723",
        "dogumgunu": "21.05.1958",
        "cemiyetno": "1349",
        "sicilno": "7246",
        "acildurumkisisi": "İsmail Yeter",
        "acildurumtelefon": "",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "205",
        "devre": "101",
        "isim": "Sait",
        "soyisim": "Köseoğlu",
        "email": "sait.koseoglu@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Finans uzmanı -MBA- Danışman",
        "telefon": "05493328191",
        "dogumgunu": "06.07.1950",
        "cemiyetno": "1274",
        "sicilno": "4640",
        "acildurumkisisi": "Kızı Ayşe Esra",
        "acildurumtelefon": "05353124834",
        "kangrubu": "A-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "206",
        "devre": "101",
        "isim": "Salih Ercüment",
        "soyisim": "Türktan",
        "email": "eturktan@yahoo.com",
        "bolge": "Küçükbük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "",
        "telefon": "05322221134",
        "dogumgunu": "",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Gülden",
        "acildurumtelefon": "05324414321",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "207",
        "devre": "101",
        "isim": "Savaş",
        "soyisim": "Güleç",
        "email": "gssavas@gmail.com",
        "bolge": "Konacık",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Müteahhit",
        "telefon": "05426521285",
        "dogumgunu": "08.02.1951",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Senem Güleç",
        "acildurumtelefon": "05333550809",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "208",
        "devre": "97",
        "isim": "Sedat",
        "soyisim": "Alioğlu",
        "email": "alioglu@alioglu-as.com",
        "bolge": "İstanbul",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05322127325",
        "dogumgunu": "27.09.1946",
        "cemiyetno": "2775",
        "sicilno": "4412",
        "acildurumkisisi": "Gülbaba Otel",
        "acildurumtelefon": "Gülbaba Otel",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "209",
        "devre": "124",
        "isim": "Selin",
        "soyisim": "Karabeyoğlu",
        "email": "selinkarabey.skl@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Günüm",
        "isbilgisi": "Ev hanımı",
        "telefon": "05432021216",
        "dogumgunu": "26.06.1973",
        "cemiyetno": "5804",
        "sicilno": "10029",
        "acildurumkisisi": "122 Cem Göçmen",
        "acildurumtelefon": "05335006795",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "210",
        "devre": "111",
        "isim": "Selva",
        "soyisim": "Tokcan",
        "email": "tunada2006@gmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05322537749",
        "dogumgunu": "18.01.1960",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi",
        "acildurumtelefon": "05302011447",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "211",
        "devre": "95",
        "isim": "Semih",
        "soyisim": "Denker",
        "email": "semihdenker@yahoo.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05337238377",
        "dogumgunu": "16.03.1945",
        "cemiyetno": "1247",
        "sicilno": "4483",
        "acildurumkisisi": "Eşi Özden",
        "acildurumtelefon": "05334588121",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "212",
        "devre": "93",
        "isim": "Sencar",
        "soyisim": "Özsoy",
        "email": "sencarozsoy@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Em. Büyükelçi",
        "telefon": "05339666047",
        "dogumgunu": "02.11.1942",
        "cemiyetno": "5077",
        "sicilno": "8367",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "213",
        "devre": "119",
        "isim": "Serap",
        "soyisim": "Batum",
        "email": "serapbatum@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Sanat tarihçisi",
        "telefon": "05322180912",
        "dogumgunu": "10.07.1968",
        "cemiyetno": "3283",
        "sicilno": "8091",
        "acildurumkisisi": "Ali Ağaçkesen",
        "acildurumtelefon": "05322960041",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "214",
        "devre": "107",
        "isim": "Serdar",
        "soyisim": "Abraz",
        "email": "serdarabraz@gmail.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Elektronik Y. Müh.",
        "telefon": "05332902288",
        "dogumgunu": "18.08.1955",
        "cemiyetno": "4639",
        "sicilno": "11394",
        "acildurumkisisi": "Esin Abraz",
        "acildurumtelefon": "05322533929",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "215",
        "devre": "118",
        "isim": "Serhat",
        "soyisim": "Cevher",
        "email": "serhatcevher@hotmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "",
        "telefon": "05324015335",
        "dogumgunu": "16.08.1967",
        "cemiyetno": "2967",
        "sicilno": "8539",
        "acildurumkisisi": "Eşi Nilüfer",
        "acildurumtelefon": "05326073636",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "216",
        "devre": "114",
        "isim": "Sevinç",
        "soyisim": "Özbek Dervent",
        "email": "sevincozbek1@yahoo.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Nöropsikolog-öğretim üyesi",
        "telefon": "05052780578",
        "dogumgunu": "02.10.1962",
        "cemiyetno": "1153",
        "sicilno": "7741",
        "acildurumkisisi": "Oğlu Can Özbek",
        "acildurumtelefon": "05052780579",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "217",
        "devre": "110",
        "isim": "Sezai",
        "soyisim": "Başar",
        "email": "sbasar@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05322511040",
        "dogumgunu": "06.05.1959",
        "cemiyetno": "1569",
        "sicilno": "6501",
        "acildurumkisisi": "Eşi Nilgün",
        "acildurumtelefon": "05333533791",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "218",
        "devre": "106",
        "isim": "Sibel",
        "soyisim": "Algan",
        "email": "zsalgan@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Em. Büyükelçi",
        "telefon": "05334430907",
        "dogumgunu": "25.09.1955",
        "cemiyetno": "",
        "sicilno": null,
        "acildurumkisisi": "Eşi Akın",
        "acildurumtelefon": "05389480499",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "219",
        "devre": "99",
        "isim": "Sinan",
        "soyisim": "Kocapınar",
        "email": "sinankocapinar@gmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "Em. Turizmci",
        "telefon": "05322113111",
        "dogumgunu": "20.11.1947",
        "cemiyetno": "2785",
        "sicilno": "8880",
        "acildurumkisisi": "Fisun Akat",
        "acildurumtelefon": "05335494392",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "220",
        "devre": "114",
        "isim": "Sinan",
        "soyisim": "Özdamar",
        "email": "sinanozdamar@yahoo.fr",
        "bolge": "Bodrum",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05386379583",
        "dogumgunu": "04.11.1961",
        "cemiyetno": null,
        "sicilno": "9799",
        "acildurumkisisi": "Kız kardeşi 116 Dilsad",
        "acildurumtelefon": "05422178124",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "221",
        "devre": "110",
        "isim": "Suna",
        "soyisim": "Kartal",
        "email": "suniska2001@yahoo.com",
        "bolge": "Güvercinlik",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Gonca",
        "isbilgisi": "",
        "telefon": "05334264182",
        "dogumgunu": "30.10.1958",
        "cemiyetno": "7573",
        "sicilno": null,
        "acildurumkisisi": "Eşi Mehmet",
        "acildurumtelefon": "05452435839",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "222",
        "devre": "113",
        "isim": "Şebnem",
        "soyisim": "Karauçak",
        "email": "skaraucak@gmail.com",
        "bolge": "Dereköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05424720000",
        "dogumgunu": "10.03.1962",
        "cemiyetno": "1930",
        "sicilno": "6772",
        "acildurumkisisi": "Eşi Attila",
        "acildurumtelefon": "05334603964",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "223",
        "devre": "106",
        "isim": "Şeref",
        "soyisim": "Şenler",
        "email": "seref.senler@gmail.com",
        "bolge": "Gölköy",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Em. Perakende Yön.",
        "telefon": "05307107913",
        "dogumgunu": "11.04.1954",
        "cemiyetno": "3089",
        "sicilno": "9653",
        "acildurumkisisi": "Eşi Emine",
        "acildurumtelefon": "05322748147",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "224",
        "devre": "115",
        "isim": "Şule",
        "soyisim": "Atabey Şamlı",
        "email": "sulesamli@devilindetail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "",
        "telefon": "05323754945",
        "dogumgunu": "20.08.1964",
        "cemiyetno": "1994",
        "sicilno": "7739",
        "acildurumkisisi": "Eşi 115 Bülent",
        "acildurumtelefon": "",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "225",
        "devre": "102",
        "isim": "Şükrü",
        "soyisim": "Bekdik",
        "email": "gsbekman@gmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05064985757",
        "dogumgunu": "10.04.1951",
        "cemiyetno": "7936",
        "sicilno": "5134",
        "acildurumkisisi": "Rüştü Onural",
        "acildurumtelefon": "05326165056",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "226",
        "devre": "109",
        "isim": "Şükrü",
        "soyisim": "Ergün",
        "email": "sukru@jotun.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "Yönetici",
        "telefon": "05322136475",
        "dogumgunu": "14.05.1958",
        "cemiyetno": "1359",
        "sicilno": "6750",
        "acildurumkisisi": "Özlem Ergün",
        "acildurumtelefon": "05322524365",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "227",
        "devre": "111",
        "isim": "Taluy",
        "soyisim": "Özdeş",
        "email": "taluy13@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05323639692",
        "dogumgunu": "01.01.1960",
        "cemiyetno": "4049",
        "sicilno": null,
        "acildurumkisisi": "Eşi İsmail",
        "acildurumtelefon": "05322610760",
        "kangrubu": "0-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "228",
        "devre": "102",
        "isim": "Tarık",
        "soyisim": "Papuççuoğlu",
        "email": "tarikpapuccuoglu@gmail.com",
        "bolge": "Gölköy",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Tiyatro ve sinema sanatçısı",
        "telefon": "05323110386",
        "dogumgunu": "14.01.1949",
        "cemiyetno": "1314",
        "sicilno": "9494",
        "acildurumkisisi": "Eşi Gülgün Akansu",
        "acildurumtelefon": "05323215822",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "229",
        "devre": "116",
        "isim": "Taylan",
        "soyisim": "Oğuz",
        "email": "taylan66@gmail.com",
        "bolge": "Yahşi",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "Film sektörü/Ses stüdyosu",
        "telefon": "05324043818",
        "dogumgunu": "26.02.1966",
        "cemiyetno": "2186",
        "sicilno": null,
        "acildurumkisisi": "Eşi Didem",
        "acildurumtelefon": "05322371909",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "230",
        "devre": "107",
        "isim": "Tevfik",
        "soyisim": "Şarman",
        "email": "tevfiksarman@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "Toplantı, kongre, turizm",
        "telefon": "05323366300",
        "dogumgunu": "20.12.1955",
        "cemiyetno": "2361",
        "sicilno": "7743",
        "acildurumkisisi": "Eşi 116 Gülmin",
        "acildurumtelefon": "05324473304",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "231",
        "devre": "125",
        "isim": "Togay",
        "soyisim": "Şenalp",
        "email": "togaysenalp@gmail.com",
        "bolge": "Dereköy",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05453683177",
        "dogumgunu": "23.09.1974",
        "cemiyetno": "6829",
        "sicilno": null,
        "acildurumkisisi": "Eşi Esra",
        "acildurumtelefon": "05367648956",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "232",
        "devre": "124",
        "isim": "Tolgar",
        "soyisim": "Bıyıklı",
        "email": "biyiklitolgar@gmail.com",
        "bolge": "Adabükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Turizm acenta sahibi",
        "telefon": "05446920349",
        "dogumgunu": "20.08.1973",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Eşi Ceyda",
        "acildurumtelefon": "05325051596",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "233",
        "devre": "107",
        "isim": "Tufan",
        "soyisim": "Kaleli",
        "email": "kaleli107@yahoo.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05322941898",
        "dogumgunu": "26.05.1956",
        "cemiyetno": "1469",
        "sicilno": "7723",
        "acildurumkisisi": "Kızı Irmak",
        "acildurumtelefon": "05335788291",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "234",
        "devre": "101",
        "isim": "Tuğrul",
        "soyisim": "Saltukoğlu",
        "email": "turegsal@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05322370200",
        "dogumgunu": "20.07.1950",
        "cemiyetno": "2508",
        "sicilno": "8308",
        "acildurumkisisi": "Regina eşi",
        "acildurumtelefon": "05334109656",
        "kangrubu": "",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "235",
        "devre": "88",
        "isim": "Tuncay",
        "soyisim": "Yurtsever",
        "email": "amcaselin@gmail.com",
        "bolge": "Gökçebel",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Maliyeci-Ressam....",
        "telefon": "05367126145",
        "dogumgunu": "25.06.1935",
        "cemiyetno": "3569",
        "sicilno": null,
        "acildurumkisisi": "Kızı Selin",
        "acildurumtelefon": "05362033058",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "236",
        "devre": "109",
        "isim": "Tunç",
        "soyisim": "Yalçıntoklu",
        "email": "tunc.yalcintoklu@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Emekli Borsacı",
        "telefon": "0033677797484",
        "dogumgunu": "05.11.1957",
        "cemiyetno": "1343",
        "sicilno": null,
        "acildurumkisisi": "İsmail Yeter",
        "acildurumtelefon": "İsmail Yeter",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "237",
        "devre": "120",
        "isim": "Tunç",
        "soyisim": "Gökçeoğlu",
        "email": "tuncgok@yahoo.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05369724020",
        "dogumgunu": "05.07.1969",
        "cemiyetno": "4562",
        "sicilno": "14517",
        "acildurumkisisi": "Eşi Ayça",
        "acildurumtelefon": "05423818017",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "238",
        "devre": "80",
        "isim": "Turgut",
        "soyisim": "Karabekir",
        "email": "turgutk@gmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Y. Mimar",
        "telefon": "05327384200",
        "dogumgunu": "01.01.1928",
        "cemiyetno": null,
        "sicilno": "2600",
        "acildurumkisisi": "Ayhan Çaloğlu",
        "acildurumtelefon": "05358921018",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "239",
        "devre": "103",
        "isim": "Turgut",
        "soyisim": "Aydınoğlu",
        "email": "turgutaydinoglu@gmail.com",
        "bolge": "Adabükü",
        "surekli": "Sürekli Değil",
        "sorumlu": "Gonca",
        "isbilgisi": "Em. Emlakçı",
        "telefon": "05322675363",
        "dogumgunu": "04.01.1952",
        "cemiyetno": "3592",
        "sicilno": "14122",
        "acildurumkisisi": "Eşi Esra",
        "acildurumtelefon": "05326114404",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "240",
        "devre": "116",
        "isim": "Tüge Toprak",
        "soyisim": "Dukul",
        "email": "ttdukul@hotmail.com",
        "bolge": "Akyarlar",
        "surekli": "Sürekli Değil",
        "sorumlu": "Merve",
        "isbilgisi": "Turizm",
        "telefon": "05323217133",
        "dogumgunu": "18.11.1965",
        "cemiyetno": "2156",
        "sicilno": "9419",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "241",
        "devre": "101",
        "isim": "Ufuk",
        "soyisim": "Doğrusöz",
        "email": "ufuk.dogrusoz@gmail.com",
        "bolge": "Kadıkalesi",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "Prof. Mimar",
        "telefon": "05313655412",
        "dogumgunu": "04.06.1951",
        "cemiyetno": "1312",
        "sicilno": null,
        "acildurumkisisi": "Eşi Nevin",
        "acildurumtelefon": "",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "242",
        "devre": "92",
        "isim": "Uğur",
        "soyisim": "Gürtunca",
        "email": "ugurtunca@msn.com",
        "bolge": "Yahşi",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin131",
        "isbilgisi": "",
        "telefon": "05322835015",
        "dogumgunu": "23.04.1940",
        "cemiyetno": "4601",
        "sicilno": "8876",
        "acildurumkisisi": "Eşi İpek",
        "acildurumtelefon": "",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "243",
        "devre": "102",
        "isim": "Uğur",
        "soyisim": "Arıner",
        "email": "uariner@gmail.com",
        "bolge": "Turgutreis",
        "surekli": "Sürekli Değil",
        "sorumlu": "Günüm",
        "isbilgisi": "",
        "telefon": "05389859194",
        "dogumgunu": "09.09.1952",
        "cemiyetno": "8113",
        "sicilno": "13847",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "244",
        "devre": "93",
        "isim": "Uluç",
        "soyisim": "Özülker",
        "email": "ulucozulker@gmail.com",
        "bolge": "Gündoğan",
        "surekli": "Sürekli Değil",
        "sorumlu": "Can",
        "isbilgisi": "Em. Büyükelçi",
        "telefon": "05383757624",
        "dogumgunu": "11.03.1942",
        "cemiyetno": "7373",
        "sicilno": "15729",
        "acildurumkisisi": "",
        "acildurumtelefon": "",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "245",
        "devre": "130",
        "isim": "Ural",
        "soyisim": "Aküzüm",
        "email": "uralakuzum@gmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05322157498",
        "dogumgunu": "02.06.1979",
        "cemiyetno": "6051",
        "sicilno": "12281",
        "acildurumkisisi": "Eşi Işın",
        "acildurumtelefon": "05412298484",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "246",
        "devre": "123",
        "isim": "Utku",
        "soyisim": "Aksoy",
        "email": "utkuaksoy@hotmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Sinan",
        "isbilgisi": "Mak. Müh. Gn. Md.",
        "telefon": "05324658712",
        "dogumgunu": "19.03.1972",
        "cemiyetno": "4772",
        "sicilno": "9362",
        "acildurumkisisi": "Eşi Pelin",
        "acildurumtelefon": "05326619468",
        "kangrubu": "B-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "247",
        "devre": "104",
        "isim": "Vedat",
        "soyisim": "Akan",
        "email": "vedatakan@yahoo.fr",
        "bolge": "Meşelik",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Gonca",
        "isbilgisi": "Yüksek Mimar  ,ODTÜ , Emekli",
        "telefon": "05054125211",
        "dogumgunu": "05.08.1953",
        "cemiyetno": "5066",
        "sicilno": "4740",
        "acildurumkisisi": "Nilgün Akan",
        "acildurumtelefon": "05424145461",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "248",
        "devre": "103",
        "isim": "Velittin",
        "soyisim": "Oğuz",
        "email": "velittinoguz@ttmail.com",
        "bolge": "Bitez",
        "surekli": "Sürekli Değil",
        "sorumlu": "Sinan",
        "isbilgisi": "Prof.Dr. Göz Hastalıklar",
        "telefon": "05322361205",
        "dogumgunu": "02.12.1953",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Oya Oğuz",
        "acildurumtelefon": "05324761920",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "249",
        "devre": "109",
        "isim": "Volkan",
        "soyisim": "Karsan",
        "email": "karsanv@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Değil",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05322722619",
        "dogumgunu": "23.09.1958",
        "cemiyetno": "1434",
        "sicilno": "9781",
        "acildurumkisisi": "Eşi 112 Birgül Karsan",
        "acildurumtelefon": "05333985323",
        "kangrubu": "A+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "250",
        "devre": "115",
        "isim": "Yasemin",
        "soyisim": "Chicheportiche",
        "email": "yasemin521@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "Em.Turizm +Emlak(Yalıkavak-Koyunbaba-Dağbelen-Yakaköy Mod.)",
        "telefon": "05333469210",
        "dogumgunu": "09.05.1964",
        "cemiyetno": "4857",
        "sicilno": "12193",
        "acildurumkisisi": "Eşi Andre",
        "acildurumtelefon": "05332097012",
        "kangrubu": "B-",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "251",
        "devre": "131",
        "isim": "Yasemin",
        "soyisim": "Arşiray Tezuçan",
        "email": "ytezucan@gmail.com",
        "bolge": "Ortakent",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin131",
        "isbilgisi": "Vergi Danışmanı/Denetçi Ortakent-Yahşi moderatörü",
        "telefon": "05306419392",
        "dogumgunu": "31.12.1979",
        "cemiyetno": null,
        "sicilno": "14952",
        "acildurumkisisi": "Ekin Arşiray",
        "acildurumtelefon": "05335429739",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "252",
        "devre": "94",
        "isim": "Yavuz",
        "soyisim": "Sipahioğlu",
        "email": "yavsipahi@gmail.com",
        "bolge": "Yalıkavak",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Yasemin115",
        "isbilgisi": "",
        "telefon": "05325177600",
        "dogumgunu": "30.07.1942",
        "cemiyetno": "3581",
        "sicilno": "8771",
        "acildurumkisisi": "Kızı Diğde",
        "acildurumtelefon": "05324304395",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "253",
        "devre": "110",
        "isim": "Yeşim",
        "soyisim": "Ulus Demirel",
        "email": "yude59@gmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "",
        "telefon": "05324620769",
        "dogumgunu": "31.05.1959",
        "cemiyetno": "4613",
        "sicilno": "16734",
        "acildurumkisisi": "Cüneyt Demirel",
        "acildurumtelefon": "05323145153",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "254",
        "devre": "101",
        "isim": "Yusuf",
        "soyisim": "Başlamışlı",
        "email": "baslamisli@gmail.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Değil",
        "sorumlu": "Ozan",
        "isbilgisi": "Em. Bankacı",
        "telefon": "05522563084",
        "dogumgunu": "26.11.1949",
        "cemiyetno": "5594",
        "sicilno": "6779",
        "acildurumkisisi": "Kızı Füsun",
        "acildurumtelefon": "05326225605",
        "kangrubu": "AB+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "255",
        "devre": "117",
        "isim": "Zeynep",
        "soyisim": "Direk",
        "email": "zeynepdirek@icloud.com",
        "bolge": "Gümüşlük",
        "surekli": "Sürekli Bodrumda",
        "sorumlu": "Ozan",
        "isbilgisi": "Akademisyen",
        "telefon": "05318382758",
        "dogumgunu": "09.06.1966",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Fahir Üzümcü",
        "acildurumtelefon": "",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "256",
        "devre": "127",
        "isim": "Zeynep",
        "soyisim": "Tekin Kutlu",
        "email": "zeyneptekin@aytek.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05322144064",
        "dogumgunu": "15.07.1976",
        "cemiyetno": "7824",
        "sicilno": null,
        "acildurumkisisi": "Eşi Cömert Kutlu",
        "acildurumtelefon": "05333848402",
        "kangrubu": "B+",
        "created_at": ${createdAt},
        "deleted_at": null
      },
      {
        "key": null,
        "id": "257",
        "devre": "128",
        "isim": "Zeynep Canan",
        "soyisim": "Baba",
        "email": "zeynepcananbaba@gmail.com",
        "bolge": "Bodrum",
        "surekli": "Sürekli Değil",
        "sorumlu": "Öncü",
        "isbilgisi": "",
        "telefon": "05322708676",
        "dogumgunu": "22.02.1978",
        "cemiyetno": null,
        "sicilno": null,
        "acildurumkisisi": "Hülya Özatalay",
        "acildurumtelefon": "05324333220",
        "kangrubu": "0+",
        "created_at": ${createdAt},
        "deleted_at": null
      }
  ]`
);
const sortOption = {};
class fakeData2 {
  constructor(size) {
    this.size = size || 2000;
    this.datas = [];
    this.sortKey = null;
    this.sortDir = null;
  }
  dataModel(index) {
    return tableData[index];
  }
  getObjectAt(index) {
    if (index < 0 || index > this.size) {
      return undefined;
    }
    if (this.datas[index] === undefined) {
      this.datas[index] = this.dataModel(index);
    }
    return this.datas[index];
  }
  getAll() {
    if (this.datas.length < this.size) {
      for (let i = 0; i < this.size; i++) {
        this.getObjectAt(i);
      }
    }
    return this.datas.slice();
  }

  getSize() {
    return this.size;
  }
  getSortAsc(sortKey) {
    sortOption.sortKey = sortKey;
    sortOption.sortDir = 'ASC';
    return this.datas.sort(this.sort);
  }
  getSortDesc(sortKey) {
    sortOption.sortKey = sortKey;
    sortOption.sortDir = 'DESC';
    return this.datas.sort(this.sort);
  }
  sort(optionA, optionB) {
    const valueA = optionA[sortOption.sortKey].toUpperCase();
    const valueB = optionB[sortOption.sortKey].toUpperCase();
    let sortVal = 0;
    if (valueA > valueB) {
      sortVal = 1;
    }
    if (valueA < valueB) {
      sortVal = -1;
    }
    if (sortVal !== 0 && sortOption.sortDir === 'DESC') {
      return sortVal * -1;
    }
    return sortVal;
  }
}
export default fakeData2;
