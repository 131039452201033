import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  contacts: {},
  modalActive: false,
  contact: {
    key: null,
    id: new Date().getTime(),
    name: "",
    surname: "",
    email: "",
    emergencyCellphone: "",
    emergencyContact: "",
    fax: "",
    cellphone: "",
    tcID: "",
    residence: "",

    created: new Date().getTime(),
    createdBy: null,
    edited: null,
    editedBy: null,
    isedeleted: null, // soft delete,
    deleted: null,
    deletedBy: null,
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contacts: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        contact: payload.data == null ? initState.contact : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        contact: payload.data,
      };
    default:
      return state;
  }
}
