let createdAt = new Date().getTime();
const tableData = JSON.parse(
  `[
    {
      "key": null,
      "id": "1",
      "devre": "109",
      "isim": "Demet",
      "soyisim": "Velicangil",
      "email": "demet.velicangil@gmail.com",
      "telefon": "05324629485",
      "bolge": "Adabükü",
      "adres": null,
      "sorumlu": "Gonca",
      "isbilgisi": "Em. Reklamcı",
      "acildurumkisisi": "Ablası Figen",
      "acildurumtelefon": "5322736464",
      "kangrubu": "B+",
      "cemiyetno": "2975",
      "sicilno": "12165",
      "dogumgunu": null,
      "songuncelleyen": "Öncü",
      "created_at": ${createdAt},
      "deleted_at": null
    },
    {
	  "key": null,
	  "id": "2",
	  "devre": "99",
	  "isim": "Adnan",
	  "soyisim": "Eroğlu",
	  "email": "madnan.eroglu@gmail.com",
	  "Telefon": "05322625026",
	  "bolge": "Akyarlar",
	  "adres": null,
	  "sorumlu": "Merve",
	  "isbilgisi": "Endüstri Müh.",
	  "acildurumkisisi": "Eşi Gülden",
	  "acildurumtelefon": "5322612609",
	  "kangrubu": "0+",
	  "cemiyetno": "4627",
	  "sicilno": "8984",
	  "dogumgunu": null,
	  "songuncelleyen": "Öncü",
	  "created_at": ${createdAt},
	  "deleted_at": null
	},
	{
	  "key": null,
	  "id": "3",
	  "devre": "115",
	  "isim": "Alev",
	  "soyisim": "Noyan Temizer",
	  "email": "alevnoyan657@gmail.com",
	  "Telefon": "05366545495",
	  "bolge": "Akyarlar",
	  "adres": null,
	  "sorumlu": "Merve",
	  "isbilgisi": null,
	  "acildurumkisisi": "Abisi Reha",
	  "acildurumtelefon": "5497616630",
	  "kangrubu": "A+",
	  "cemiyetno": "1983",
	  "sicilno": "15540",
	  "dogumgunu": null,
	  "songuncelleyen": "Öncü",
	  "created_at": ${createdAt},
	  "deleted_at": null
	},
	{
	  "key": null,
	  "id": "4",
	  "devre": "113",
	  "isim": "Çiğdem",
	  "soyisim": "Albayrak",
	  "email": "cigdem.laratasarim@gmail.com",
	  "Telefon": "05322167706",
	  "bolge": "Akyarlar",
	  "adres": null,
	  "sorumlu": "Merve",
	  "isbilgisi": null,
	  "acildurumkisisi": "Bilge Erkut",
	  "acildurumtelefon": "5326935976",
	  "kangrubu": "A+",
	  "cemiyetno": "1839",
	  "sicilno": "13917",
	  "dogumgunu": null,
	  "songuncelleyen": "Öncü",
	  "created_at": ${createdAt},
	  "deleted_at": null
	},
	{
    "key": null,
    "id": "5",
    "devre": "108",
    "isim": "Egemen Ersan",
    "soyisim": "Güner",
    "email": "e.guner@yahoo.fr",
    "Telefon": "05342061276",
    "bolge": "Akyarlar",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": "Turizmci",
    "acildurumkisisi": "Hale Güner",
    "acildurumtelefon": "33667180707",
    "kangrubu": "B+",
    "cemiyetno": "3083",
    "sicilno": "12681",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "6",
    "devre": "100",
    "isim": "Hüseyin Nihat",
    "soyisim": "Birgül",
    "email": "hnbirgul@gmail.com",
    "Telefon": "05323213995",
    "bolge": "Akyarlar",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": "Makina Yük. Müh. (İTÜ)",
    "acildurumkisisi": "Nüket Birgül",
    "acildurumtelefon": "5305857992",
    "kangrubu": "A+",
    "cemiyetno": "3133",
    "sicilno": "4911",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "7",
    "devre": "121",
    "isim": "Meltem",
    "soyisim": "Evrensel",
    "email": "evrensel.m@gmail.com",
    "Telefon": "05323419902",
    "bolge": "Akyarlar",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": null,
    "acildurumkisisi": "Esin Tuğ",
    "acildurumtelefon": "5357898308",
    "kangrubu": "B+",
    "cemiyetno": "4123",
    "sicilno": "14423",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "8",
    "devre": "124",
    "isim": "Merve",
    "soyisim": "Gürkaynak",
    "email": "mervegurkaynak@gmail.com",
    "Telefon": "05336587204",
    "bolge": "Akyarlar",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": "Kongre ve toplantı Yön. (Akyarlar-Bağla moderatörü)",
    "acildurumkisisi": "İslam Eren",
    "acildurumtelefon": "5378190120",
    "kangrubu": "B+",
    "cemiyetno": "5295",
    "sicilno": "12258",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "9",
    "devre": "106",
    "isim": "Nail",
    "soyisim": "Yılmaz",
    "email": "nailyil64@gmail.com",
    "Telefon": "05322829351",
    "bolge": "Akyarlar",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": "Endüstri müh.",
    "acildurumkisisi": "Şükrü Yılmaz",
    "acildurumtelefon": "5373031114",
    "kangrubu": "A+",
    "cemiyetno": "2800",
    "sicilno": "12264",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "10",
    "devre": "81",
    "isim": "Samim",
    "soyisim": "Kor",
    "email": null,
    "Telefon": "05387415702",
    "bolge": "Akyarlar",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": null,
    "cemiyetno": null,
    "sicilno": "2640",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "11",
    "devre": "111",
    "isim": "Selva",
    "soyisim": "Tokcan",
    "email": "tunada2006@gmail.com",
    "Telefon": "05322537749",
    "bolge": "Akyarlar",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi",
    "acildurumtelefon": "5302011447",
    "kangrubu": "0-",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "12",
    "devre": "99",
    "isim": "Sinan",
    "soyisim": "Kocapınar",
    "email": "sinankocapinar@gmail.com",
    "Telefon": "05322113111",
    "bolge": "Akyarlar",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": "Em. Turizmci",
    "acildurumkisisi": "Fisun Akat",
    "acildurumtelefon": "5335494392",
    "kangrubu": "0+",
    "cemiyetno": "2785",
    "sicilno": "8880",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "13",
    "devre": "115",
    "isim": "Şule",
    "soyisim": "Atabey Şamlı",
    "email": "sulesamli@devilindetail.com",
    "Telefon": "05323754945",
    "bolge": "Akyarlar",
    "adres": "Karaincir",
    "sorumlu": "Merve",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "0-",
    "cemiyetno": "1994",
    "sicilno": "7739",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "14",
    "devre": "116",
    "isim": "Tüge Toprak",
    "soyisim": "Dukul",
    "email": "ttdukul@hotmail.com",
    "Telefon": "05323217133",
    "bolge": "Akyarlar",
    "adres": "Akçabük",
    "sorumlu": "Merve",
    "isbilgisi": "Turizm",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "2156",
    "sicilno": "9419",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "15",
    "devre": "104",
    "isim": "Lemi",
    "soyisim": "Tu-Men",
    "email": "lemitumen@yahoo.com",
    "Telefon": "05324654342",
    "bolge": "Akyarlar",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": "Yük. İnş. Müh.",
    "acildurumkisisi": "Eşi Aylin",
    "acildurumtelefon": "5365178844",
    "kangrubu": "A+",
    "cemiyetno": "4297",
    "sicilno": "7904",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "16",
    "devre": "115",
    "isim": "Aşkın",
    "soyisim": "Yurdakul",
    "email": "askinyurdakul@gmail.com",
    "Telefon": "05494010001",
    "bolge": "Bağla",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": null,
    "acildurumkisisi": "Oktay",
    "acildurumtelefon": "5302732736",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "17",
    "devre": "105",
    "isim": "Berhan",
    "soyisim": "Kaya",
    "email": "berhankaya@yahoo.com",
    "Telefon": "05344213363",
    "bolge": "Bağla",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Jaklin",
    "acildurumtelefon": "5445951962",
    "kangrubu": "A+",
    "cemiyetno": "3728",
    "sicilno": "13222",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "18",
    "devre": "109",
    "isim": "Hayim",
    "soyisim": "Babür",
    "email": "hayimbabur@gmail.com",
    "Telefon": "05323110244",
    "bolge": "Bağla",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Caroline",
    "acildurumtelefon": "5322572467",
    "kangrubu": "0+",
    "cemiyetno": "5183",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "19",
    "devre": "108",
    "isim": "Levent",
    "soyisim": "Dönmez",
    "email": "leventdonmez2@gmail.com",
    "Telefon": "05492671832",
    "bolge": "Bağla",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Çiğdem",
    "acildurumtelefon": "5322962284",
    "kangrubu": "A+",
    "cemiyetno": "4219",
    "sicilno": "9139",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "20",
    "devre": "101",
    "isim": "Ömer Salih",
    "soyisim": "Ülgen",
    "email": "omer_ulgen@yahoo.com",
    "Telefon": "05422153031",
    "bolge": "Bağla",
    "adres": null,
    "sorumlu": "Merve",
    "isbilgisi": null,
    "acildurumkisisi": "Leyla Ülgen",
    "acildurumtelefon": "5378301939",
    "kangrubu": "A+",
    "cemiyetno": "4463",
    "sicilno": "8958",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "21",
    "devre": "106",
    "isim": "Ali Aydın",
    "soyisim": "Erkün",
    "email": "ayderkun@hotmail.com",
    "Telefon": "05323215230",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Mak. Müh. (İTÜ) +İşletme lisans üstü mba",
    "acildurumkisisi": "Gülçin Tarhan",
    "acildurumtelefon": "5323220705",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "22",
    "devre": "93",
    "isim": "Armağan",
    "soyisim": "Kurra",
    "email": "babakurra@yahoo.com",
    "Telefon": "05352270523",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Y. Müh. Mimar",
    "acildurumkisisi": "Eşi Selma",
    "acildurumtelefon": "5337732370",
    "kangrubu": "0+",
    "cemiyetno": "3066",
    "sicilno": "9149",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "23",
    "devre": "114",
    "isim": "Can",
    "soyisim": "Oral",
    "email": "canoral63@gmail.com",
    "Telefon": "05322127378",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Em. Turizm",
    "acildurumkisisi": "Nur Sarper",
    "acildurumtelefon": "5323740078",
    "kangrubu": "A+",
    "cemiyetno": "1871",
    "sicilno": "10056",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "24",
    "devre": "102",
    "isim": "Çetin",
    "soyisim": "Yıldızay",
    "email": "cyildizay@yahoo.com",
    "Telefon": "05322613980",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Melek",
    "acildurumtelefon": "5422405340",
    "kangrubu": "A+",
    "cemiyetno": "2771",
    "sicilno": "9444",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "25",
    "devre": "132",
    "isim": "Derya",
    "soyisim": "Taşdemir",
    "email": "deryatasdemir@hotmail.com",
    "Telefon": "05332144493",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Avukat / Arabulucu",
    "acildurumkisisi": "Eşi Ali Emre",
    "acildurumtelefon": "5322016567",
    "kangrubu": "B+",
    "cemiyetno": null,
    "sicilno": "16114",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "26",
    "devre": "110",
    "isim": "Günaydın",
    "soyisim": "Özcebe",
    "email": "gunaydinozcebe@hotmail.com",
    "Telefon": "05326149588",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Em. Avukat",
    "acildurumkisisi": "Handan Özcebe",
    "acildurumtelefon": "5322854483",
    "kangrubu": "0+",
    "cemiyetno": "1477",
    "sicilno": "6808",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "27",
    "devre": "94",
    "isim": "Gündüz",
    "soyisim": "Nalbantoğlu",
    "email": "gunduz@gunduztravel.com",
    "Telefon": "054222339901",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Gündüz Seyahat Acentası",
    "acildurumkisisi": "Veysel",
    "acildurumtelefon": "5323976796",
    "kangrubu": "0-",
    "cemiyetno": "2730",
    "sicilno": "6877",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "28",
    "devre": "125",
    "isim": "Kartal",
    "soyisim": "Kaya",
    "email": "kartal125@yahoo.com",
    "Telefon": "05326548875",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Rehber (Bitez moderatörü)",
    "acildurumkisisi": "Kardeşi Başak",
    "acildurumtelefon": "5323323849",
    "kangrubu": "A+",
    "cemiyetno": "5689",
    "sicilno": "10534",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "29",
    "devre": "120",
    "isim": "Kerem",
    "soyisim": "Kemerli",
    "email": "kerem@kemerli.com",
    "Telefon": "05322872220",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "İnşaat Müh.",
    "acildurumkisisi": "Akın Ketenci",
    "acildurumtelefon": "5337668517",
    "kangrubu": "A+",
    "cemiyetno": "3804",
    "sicilno": "8439",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "30",
    "devre": "108",
    "isim": "Lale",
    "soyisim": "Özkut",
    "email": "laleozkut@yahoo.com",
    "Telefon": "05335755695",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Ekonomist LGS koçu sayısal öğretmeni",
    "acildurumkisisi": "Sevgi Karabulak",
    "acildurumtelefon": "5352809525",
    "kangrubu": "0+",
    "cemiyetno": "1406",
    "sicilno": "7826",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "31",
    "devre": "114",
    "isim": "Mahmut",
    "soyisim": "Kaşıkçı",
    "email": "mahmutkasikci@hotmail.com",
    "Telefon": "05323147034",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Hukukçu",
    "acildurumkisisi": "114 Osman Elibol",
    "acildurumtelefon": "5326113225",
    "kangrubu": null,
    "cemiyetno": "5617",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "32",
    "devre": "114",
    "isim": "Mehmet Sinan",
    "soyisim": "Çakım",
    "email": "sinancakim@gmail.com",
    "Telefon": "05334377180",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Turizm",
    "acildurumkisisi": "Müjgan Ateş",
    "acildurumtelefon": "5325268830",
    "kangrubu": "0+",
    "cemiyetno": "1906",
    "sicilno": "7774",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "33",
    "devre": "114",
    "isim": "Mert",
    "soyisim": "Yücesan",
    "email": "mert.yucesan63@gmail.com",
    "Telefon": "05322115992",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Sigortacı",
    "acildurumkisisi": "Sibel Yücesan",
    "acildurumtelefon": "5322618524",
    "kangrubu": "B+",
    "cemiyetno": "1913",
    "sicilno": "12158",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "34",
    "devre": "118",
    "isim": "Nilüfer",
    "soyisim": "Cevher",
    "email": "nilufercevher9@gmail.com",
    "Telefon": "05326073636",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Serhat",
    "acildurumtelefon": "5324015335",
    "kangrubu": "A+",
    "cemiyetno": "2918",
    "sicilno": "8540",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "35",
    "devre": "126",
    "isim": "Oğuz",
    "soyisim": "Kırmızıyeşil",
    "email": "oguzkirmiziyesil@hotmail.com",
    "Telefon": "05322767111",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": null,
    "acildurumkisisi": "Zeren Kırmızıyeşil",
    "acildurumtelefon": "5324120595",
    "kangrubu": "0+",
    "cemiyetno": "5361",
    "sicilno": "13871",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "36",
    "devre": "121",
    "isim": "Ozan",
    "soyisim": "Eşiyok",
    "email": "ozan@sonproduction.com",
    "Telefon": "05326757285",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Menajer+Organizatör+İşletmeci",
    "acildurumkisisi": "Olga Eşiyok",
    "acildurumtelefon": "5304910837",
    "kangrubu": "0+",
    "cemiyetno": "4150",
    "sicilno": "8524",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "37",
    "devre": "106",
    "isim": "Ömer",
    "soyisim": "Devecioğlu",
    "email": "devecioglu.omer@gmail.com",
    "Telefon": "05325953634",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Em. Muhasebe Md.",
    "acildurumkisisi": "Aysun Devecioğlu",
    "acildurumtelefon": "5426121210",
    "kangrubu": "0+",
    "cemiyetno": "1450",
    "sicilno": "8156",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "38",
    "devre": "106",
    "isim": "Raif",
    "soyisim": "Eşkinat",
    "email": "raifeskinat@gmail.com",
    "Telefon": "05323642055",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Endüstri Müh.",
    "acildurumkisisi": "Neziha",
    "acildurumtelefon": "5305893370",
    "kangrubu": null,
    "cemiyetno": null,
    "sicilno": "12154",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "39",
    "devre": "118",
    "isim": "Serhat",
    "soyisim": "Cevher",
    "email": "serhatcevher@hotmail.com",
    "Telefon": "05324015335",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Nilüfer",
    "acildurumtelefon": "5326073636",
    "kangrubu": "0+",
    "cemiyetno": "2967",
    "sicilno": "8539",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "40",
    "devre": "80",
    "isim": "Turgut",
    "soyisim": "Karabekir",
    "email": "turgutk@gmail.com",
    "Telefon": "05327384200",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Y. Mimar",
    "acildurumkisisi": "Ayhan Çaloğlu",
    "acildurumtelefon": "5358921018",
    "kangrubu": "B+",
    "cemiyetno": null,
    "sicilno": "2600",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "41",
    "devre": "123",
    "isim": "Utku",
    "soyisim": "Aksoy",
    "email": "utkuaksoy@hotmail.com",
    "Telefon": "05324658712",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Mak. Müh. Gn. Md.",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "B-",
    "cemiyetno": "4772",
    "sicilno": "9362",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "42",
    "devre": "103",
    "isim": "Velittin",
    "soyisim": "Oğuz",
    "email": "velittinoguz@ttmail.com",
    "Telefon": "05322361205",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Prof.Dr. Göz Hastalıklar",
    "acildurumkisisi": "Eşi Pelin",
    "acildurumtelefon": "5327737880",
    "kangrubu": "B+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "43",
    "devre": "111",
    "isim": "Ahmet",
    "soyisim": "Hamulu",
    "email": "ahmethamulu@gmail.com",
    "Telefon": "05323544158",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Em. Prof. Kalp ve damar cerrahı, işyeri hekimi (aktif)",
    "acildurumkisisi": "Oya Oğuz",
    "acildurumtelefon": "5324761920",
    "kangrubu": "A+",
    "cemiyetno": "6472",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "44",
    "devre": null,
    "isim": "Mehmet",
    "soyisim": "Kefeli",
    "email": null,
    "Telefon": "05325447689",
    "bolge": "Bitez",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": null,
    "acildurumkisisi": "Çağatay Önür",
    "acildurumtelefon": "5323161112",
    "kangrubu": null,
    "cemiyetno": "1140",
    "sicilno": "3946",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "45",
    "devre": "93",
    "isim": "Akın",
    "soyisim": "Kuruner",
    "email": "akin.kuruner@isbank.net.tr",
    "Telefon": "05339693272",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "B+",
    "cemiyetno": "3139",
    "sicilno": "8238",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "46",
    "devre": "122",
    "isim": "Aycan",
    "soyisim": "Yeniley",
    "email": "aycanege3@gmail.com",
    "Telefon": "05334742557",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": "İç mimar",
    "acildurumkisisi": "Dilek Duru",
    "acildurumtelefon": "5312035742",
    "kangrubu": "0+",
    "cemiyetno": "4822",
    "sicilno": "11646",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "47",
    "devre": "106",
    "isim": "Can",
    "soyisim": "Tengizman",
    "email": "can@tengizman.com",
    "Telefon": "05322510951",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": "Emlak",
    "acildurumkisisi": "Edith",
    "acildurumtelefon": "5318115342",
    "kangrubu": "0+",
    "cemiyetno": "5022",
    "sicilno": "6102",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "48",
    "devre": "114",
    "isim": "Cem",
    "soyisim": "Osmanoğlu",
    "email": "osmanogluc@yahoo.com",
    "Telefon": "05326508200",
    "bolge": "Bodrum",
    "adres": "Eskiçeşme",
    "sorumlu": "Öncü",
    "isbilgisi": "Em. Bankacı",
    "acildurumkisisi": "Gülhan Kurter",
    "acildurumtelefon": "5324123036",
    "kangrubu": "A+",
    "cemiyetno": "1911",
    "sicilno": "8676",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "49",
    "devre": "115",
    "isim": "Deniz",
    "soyisim": "Özüçer",
    "email": "dnzkurtoglu@gmail.com",
    "Telefon": "05326145172",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Haluk Baykal",
    "acildurumtelefon": "5323541929",
    "kangrubu": "0+",
    "cemiyetno": "5944",
    "sicilno": "11903",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "50",
    "devre": "121",
    "isim": "Erhan",
    "soyisim": "Öztürk",
    "email": "erhan@greengo.com.tr",
    "Telefon": "05326566236",
    "bolge": "Bodrum",
    "adres": "Yokuşbaşı",
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Kızı Ayşe",
    "acildurumtelefon": "5055814779",
    "kangrubu": "A+",
    "cemiyetno": "4152",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "51",
    "devre": "120",
    "isim": "Fatoş",
    "soyisim": "Altıntop Gürbüz",
    "email": "fatosgurbuz6970@outlook.com",
    "Telefon": "05426264565",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Fatih Aytaç",
    "acildurumtelefon": "549850280",
    "kangrubu": "AB -",
    "cemiyetno": "3780",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "52",
    "devre": "119",
    "isim": "Gaye",
    "soyisim": "Avşar",
    "email": "gayeavsar@gmail.com",
    "Telefon": "05322112499",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Yakup Gürbüz",
    "acildurumtelefon": "5426264565",
    "kangrubu": null,
    "cemiyetno": "3297",
    "sicilno": "17366",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "53",
    "devre": "110",
    "isim": "Gül",
    "soyisim": "Necioğlu Atilla",
    "email": "gulgsl110@yahoo.com",
    "Telefon": "05372219530",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "B+",
    "cemiyetno": "1687",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "54",
    "devre": "107",
    "isim": "Gülen",
    "soyisim": "Can",
    "email": "gulencan2007@hotmail.com",
    "Telefon": "05324726662",
    "bolge": "Bodrum",
    "adres": "Asarlık - Akvaryum",
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Kardeşi Dilek",
    "acildurumtelefon": "5322558532",
    "kangrubu": "0+",
    "cemiyetno": "1348",
    "sicilno": "15427",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "55",
    "devre": "98",
    "isim": "Merih",
    "soyisim": "Övül",
    "email": "merihovul@hotmail.com",
    "Telefon": "05333671406",
    "bolge": "Bodrum",
    "adres": "Yokuşbaşı",
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Emre",
    "acildurumtelefon": "5322180808",
    "kangrubu": "AB+",
    "cemiyetno": "1271",
    "sicilno": "8411",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "56",
    "devre": "113",
    "isim": "Oktay",
    "soyisim": "Ünaldı",
    "email": "piqwiz@gmail.com",
    "Telefon": "05333393770",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Belkıs Övül",
    "acildurumtelefon": "5337206340",
    "kangrubu": "0+",
    "cemiyetno": "2143",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "57",
    "devre": "134",
    "isim": "Öncü",
    "soyisim": "Öztekin",
    "email": "oncuoztekin@gmail.com",
    "Telefon": "05322065438",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": "Yazılım + Acentacı (Bodrum moderatörü)",
    "acildurumkisisi": "Ablası Şule",
    "acildurumtelefon": "5324660195",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "58",
    "devre": "114",
    "isim": "Sinan",
    "soyisim": "Özdamar",
    "email": "sinanozdamar@yahoo.fr",
    "Telefon": "05386379583",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Muzaffer Doğru",
    "acildurumtelefon": "5425231230",
    "kangrubu": "A+",
    "cemiyetno": null,
    "sicilno": "9799",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "59",
    "devre": "130",
    "isim": "Ural",
    "soyisim": "Aküzüm",
    "email": "uralakuzum@gmail.com",
    "Telefon": "05322157498",
    "bolge": "Bodrum",
    "adres": "Umurça",
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Kız kardeşi 116 Dilsad",
    "acildurumtelefon": "5422178124",
    "kangrubu": "0+",
    "cemiyetno": "6051",
    "sicilno": "12281",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "60",
    "devre": "127",
    "isim": "Zeynep",
    "soyisim": "Tekin Kutlu",
    "email": "zeyneptekin@aytek.com",
    "Telefon": "05322144064",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Işın",
    "acildurumtelefon": "5412298484",
    "kangrubu": "B+",
    "cemiyetno": "7824",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "61",
    "devre": "128",
    "isim": "Zeynep Canan",
    "soyisim": "Baba",
    "email": "zeynepcananbaba@gmail.com",
    "Telefon": "05322708676",
    "bolge": "Bodrum",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Cömert Kutlu",
    "acildurumtelefon": "5333848402",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "62",
    "devre": "101",
    "isim": "Ekrem",
    "soyisim": "İnözü",
    "email": "einozu@gmail.com",
    "Telefon": "05322127640",
    "bolge": "Bodrum Tekne",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Hülya Özatalay",
    "acildurumtelefon": "05324333220",
    "kangrubu": "B+",
    "cemiyetno": "6109",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "63",
    "devre": "109",
    "isim": "İsmail",
    "soyisim": "Yeter",
    "email": "ismailyeter@gmail.com",
    "Telefon": "05322837093",
    "bolge": "Dağbelen",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Yük. Mimar (İTÜ)",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "AB+",
    "cemiyetno": "2600",
    "sicilno": "11908",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "64",
    "devre": "109",
    "isim": "Metin",
    "soyisim": "Küçükercan",
    "email": "mkucukercan@gmail.com",
    "Telefon": "05323867280",
    "bolge": "Dağbelen",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Doktor",
    "acildurumkisisi": "Eşi Pınar",
    "acildurumtelefon": "5353998435",
    "kangrubu": "AB+",
    "cemiyetno": "2685",
    "sicilno": "11907",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "65",
    "devre": "104",
    "isim": "Nejat",
    "soyisim": "Özcan",
    "email": "nejatozcan@hotmail.com",
    "Telefon": "05052316421",
    "bolge": "Datça",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi İnci",
    "acildurumtelefon": "5337474202",
    "kangrubu": null,
    "cemiyetno": "6464",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "66",
    "devre": "104",
    "isim": "Rasim",
    "soyisim": "Güvendi",
    "email": "rasim@guvendi.eu",
    "Telefon": "05380915398",
    "bolge": "Datça",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Oğlu Mert",
    "acildurumtelefon": "5057461664",
    "kangrubu": "AB+",
    "cemiyetno": "1323",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "67",
    "devre": "117",
    "isim": "Ali",
    "soyisim": "Ağaçkesen",
    "email": "akesen117@gmail.com",
    "Telefon": "05322960041",
    "bolge": "Dereköy",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "B+",
    "cemiyetno": "2455",
    "sicilno": "9077",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "68",
    "devre": "110",
    "isim": "Attila",
    "soyisim": "Suyolcu",
    "email": "attilasuyolcu@yahoo.com",
    "Telefon": "05334603964",
    "bolge": "Dereköy",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": "İşletmeci",
    "acildurumkisisi": "Nafiz Altuğ",
    "acildurumtelefon": "5398788725",
    "kangrubu": "0+",
    "cemiyetno": "1407",
    "sicilno": "5519",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "69",
    "devre": "123",
    "isim": "Çınar",
    "soyisim": "Kurra",
    "email": "kurra776@gmail.com",
    "Telefon": "05336273627",
    "bolge": "Dereköy",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": "Msc. Elk. Elektronik Müh.",
    "acildurumkisisi": "Eşi Şebnem",
    "acildurumtelefon": "5424720000",
    "kangrubu": "0+",
    "cemiyetno": "4897",
    "sicilno": "9150",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "70",
    "devre": "116",
    "isim": "Fahir",
    "soyisim": "Üzümcü",
    "email": "fahir@akademibric.org",
    "Telefon": "05322661284",
    "bolge": "Dereköy",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Babası Armağan",
    "acildurumtelefon": "5352270523",
    "kangrubu": "A+",
    "cemiyetno": "3652",
    "sicilno": "12259",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "71",
    "devre": "117",
    "isim": "Nafiz",
    "soyisim": "Altuğ",
    "email": "c.nafiz@hotmail.com",
    "Telefon": "05398788725",
    "bolge": "Dereköy",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Hülya",
    "acildurumtelefon": "5352155657",
    "kangrubu": "0+",
    "cemiyetno": "5055",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "72",
    "devre": "113",
    "isim": "Şebnem",
    "soyisim": "Karauçak",
    "email": "skaraucak@gmail.com",
    "Telefon": "05424720000",
    "bolge": "Dereköy",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Aynur Sözbir",
    "acildurumtelefon": "5304182585",
    "kangrubu": "0+",
    "cemiyetno": "1930",
    "sicilno": "6772",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "73",
    "devre": "125",
    "isim": "Togay",
    "soyisim": "Şenalp",
    "email": "togaysenalp@gmail.com",
    "Telefon": "05453683177",
    "bolge": "Dereköy",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Attila",
    "acildurumtelefon": "5334603964",
    "kangrubu": "AB+",
    "cemiyetno": "6829",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "74",
    "devre": "101",
    "isim": "Erkan",
    "soyisim": "Murat",
    "email": "ek.murat@gmail.com",
    "Telefon": "05423500079",
    "bolge": "Gökçebel",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Em. Turizmci",
    "acildurumkisisi": "Eşi Esra",
    "acildurumtelefon": "5367648956",
    "kangrubu": "0+",
    "cemiyetno": "7678",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "75",
    "devre": "88",
    "isim": "Tuncay",
    "soyisim": "Yurtsever",
    "email": "amcaselin@gmail.com",
    "Telefon": "05367126145",
    "bolge": "Gökçebel",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Maliyeci-Ressam....",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "3569",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "76",
    "devre": "93",
    "isim": "Dinç",
    "soyisim": "Erdün",
    "email": "eerdun1@yahoo.com",
    "Telefon": "05324771800",
    "bolge": "Gölköy",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em. Büyükelçi",
    "acildurumkisisi": "Kızı Selin",
    "acildurumtelefon": "5362033058",
    "kangrubu": null,
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "77",
    "devre": "82",
    "isim": "Doğan",
    "soyisim": "Sorguç",
    "email": "dogan.sorguc2@gmail.com",
    "Telefon": "05374214071",
    "bolge": "Gölköy",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Y.İnş.Müh.",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "6356",
    "sicilno": "5672",
    "dogumgunu": "16 NİSAN",
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "78",
    "devre": "94",
    "isim": "Güngör",
    "soyisim": "Tekçe",
    "email": "gungortekce@gungortekce.com",
    "Telefon": "05359281806",
    "bolge": "Gölköy",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Şair-Yazar-Sosyolog-Em. TRT programcısı",
    "acildurumkisisi": "Oğlu Sinan",
    "acildurumtelefon": "5332680808",
    "kangrubu": null,
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "79",
    "devre": "106",
    "isim": "Şeref",
    "soyisim": "Şenler",
    "email": "seref.senler@gmail.com",
    "Telefon": "05307107913",
    "bolge": "Gölköy",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em. Perakende Yön.",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "3089",
    "sicilno": "9653",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "80",
    "devre": "102",
    "isim": "Tarık",
    "soyisim": "Papuççuoğlu",
    "email": "tarikpapuccuoglu@gmail.com",
    "Telefon": "05323110386",
    "bolge": "Gölköy",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Tiyatro ve sinema sanatçısı",
    "acildurumkisisi": "Eşi Emine",
    "acildurumtelefon": "5322748147",
    "kangrubu": "A+",
    "cemiyetno": "1314",
    "sicilno": "9494",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "81",
    "devre": "114",
    "isim": "Faruk",
    "soyisim": "Demir",
    "email": "farukdemirbp@gmail.com",
    "Telefon": "05333616065",
    "bolge": "Göltürkbükü",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Eğitmen",
    "acildurumkisisi": "Eşi Gülgün Akansu",
    "acildurumtelefon": "5323215822",
    "kangrubu": "B+",
    "cemiyetno": "1903",
    "sicilno": "10473",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "82",
    "devre": "113",
    "isim": "Figen",
    "soyisim": "İdemen",
    "email": "figen_idemen@hotmail.com",
    "Telefon": "05325841337",
    "bolge": "Göltürkbükü",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Elektrik Müh.",
    "acildurumkisisi": "Sim Demir",
    "acildurumtelefon": "5324732818",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "83",
    "devre": "108",
    "isim": "Gül",
    "soyisim": "Seven Kırelli",
    "email": "gulk111@gmail.com",
    "Telefon": "05444804444",
    "bolge": "Göltürkbükü",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Turizm",
    "acildurumkisisi": "Murat İdemen",
    "acildurumtelefon": "5322842292",
    "kangrubu": "0+",
    "cemiyetno": "1365",
    "sicilno": "12293",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "84",
    "devre": "114",
    "isim": "Murat Evren",
    "soyisim": "İdemen",
    "email": "murat_idemen@hotmail.com",
    "Telefon": "05322842292",
    "bolge": "Göltürkbükü",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Ekonomist",
    "acildurumkisisi": "Kızı Nil",
    "acildurumtelefon": "5447788484",
    "kangrubu": "0+",
    "cemiyetno": "5553",
    "sicilno": "10328",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "85",
    "devre": "107",
    "isim": "Ali",
    "soyisim": "Platin",
    "email": "aliplatin@yahoo.com",
    "Telefon": "05357484777",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": "Grafiker",
    "acildurumkisisi": "Figen İdemen",
    "acildurumtelefon": "5325841337",
    "kangrubu": "A-",
    "cemiyetno": "1524",
    "sicilno": "4874",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "86",
    "devre": "119",
    "isim": "Alpay",
    "soyisim": "Dedeoğlu",
    "email": "alpaydedeoglu@gmail.com",
    "Telefon": "05444543850",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "0-",
    "cemiyetno": "3513",
    "sicilno": "8044",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "87",
    "devre": "125",
    "isim": "Altuğ",
    "soyisim": "Oğuz",
    "email": "altugoguz@altobio.com.tr",
    "Telefon": "05336886053",
    "bolge": "Gümüşlük",
    "adres": "Karakaya",
    "sorumlu": "Ozan",
    "isbilgisi": "Sanayici",
    "acildurumkisisi": "Eşi Ebru",
    "acildurumtelefon": "5302058496",
    "kangrubu": "AB+",
    "cemiyetno": "5247",
    "sicilno": "9739",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "88",
    "devre": "106",
    "isim": "Cahide",
    "soyisim": "Budak",
    "email": "cahide.budak@gmail.com",
    "Telefon": "05373969639",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Şebnem Oğuz",
    "acildurumtelefon": "5336886054",
    "kangrubu": "A+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "89",
    "devre": "136",
    "isim": "Cem",
    "soyisim": "Özuğurlu",
    "email": "cozugurlu@gmail.com",
    "Telefon": "05357271846",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Cahit Budak",
    "acildurumtelefon": "5355241697",
    "kangrubu": "0+",
    "cemiyetno": "6524",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "90",
    "devre": "114",
    "isim": "Emine",
    "soyisim": "Sayu",
    "email": "esayu@hotmail.com",
    "Telefon": "05322158376",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Müge",
    "acildurumtelefon": "5362716781",
    "kangrubu": "A+",
    "cemiyetno": "1167",
    "sicilno": "7251",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "91",
    "devre": "124",
    "isim": "Gülbin",
    "soyisim": "Demiriz",
    "email": "demirizgsl@yahoo.com",
    "Telefon": "05336807369",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": "Endüstriel tasarım/İnşaat( Gümüşlük-Dereköy-Peksimet-Kadıkalesi Moderatörü",
    "acildurumkisisi": "Cahide Budak",
    "acildurumtelefon": "5373969639",
    "kangrubu": "B+",
    "cemiyetno": "5453",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "92",
    "devre": "106",
    "isim": "Gülgün",
    "soyisim": "Dursen",
    "email": "gulgundursen@msn.com",
    "Telefon": "0041787317269",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "0+",
    "cemiyetno": "6492",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "93",
    "devre": "90",
    "isim": "Oryal",
    "soyisim": "Güventürk",
    "email": "oryal@oryal.com",
    "Telefon": "05424375125",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Cahide Budak",
    "acildurumtelefon": "5373969639",
    "kangrubu": "A+",
    "cemiyetno": "1105",
    "sicilno": "7597",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "94",
    "devre": "131",
    "isim": "Ozan",
    "soyisim": "Sabuncuoğlu",
    "email": "ozansabuncuoglu131@gmail.com",
    "Telefon": "05336848182",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Meral",
    "acildurumtelefon": "5422157846",
    "kangrubu": "AB+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "95",
    "devre": "102",
    "isim": "Şükrü",
    "soyisim": "Bekdik",
    "email": "gsbekman@gmail.com",
    "Telefon": "05064985757",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Nilay",
    "acildurumtelefon": "5357028271",
    "kangrubu": "A+",
    "cemiyetno": "7936",
    "sicilno": "5134",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "96",
    "devre": "120",
    "isim": "Tunç",
    "soyisim": "Gökçeoğlu",
    "email": "tuncgok@yahoo.com",
    "Telefon": "05369724020",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Rüştü Onural",
    "acildurumtelefon": "5326165056",
    "kangrubu": "A+",
    "cemiyetno": "4562",
    "sicilno": "14517",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "97",
    "devre": "110",
    "isim": "Yeşim",
    "soyisim": "Ulus Demirel",
    "email": "yude59@gmail.com",
    "Telefon": "05324620769",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Ayça",
    "acildurumtelefon": "5423818017",
    "kangrubu": "B+",
    "cemiyetno": "4613",
    "sicilno": "16734",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "98",
    "devre": "101",
    "isim": "Yusuf",
    "soyisim": "Başlamışlı",
    "email": "baslamisli@gmail.com",
    "Telefon": "05522563084",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": "Em. Bankacı",
    "acildurumkisisi": "Cüneyt Demirel",
    "acildurumtelefon": "5323145153",
    "kangrubu": "AB+",
    "cemiyetno": "5594",
    "sicilno": "6779",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "99",
    "devre": null,
    "isim": "Zeynep",
    "soyisim": "Direk",
    "email": null,
    "Telefon": null,
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": null,
    "isbilgisi": null,
    "acildurumkisisi": "Kızı Füsun",
    "acildurumtelefon": "5326225605",
    "kangrubu": null,
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "100",
    "devre": "119",
    "isim": "Ertuğrul",
    "soyisim": "Çalak",
    "email": "ecalak@gmail.com",
    "Telefon": "05322725606",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "3469",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "101",
    "devre": "117",
    "isim": "Orbay",
    "soyisim": "Sayu",
    "email": "orbaysayu@gmail.com",
    "Telefon": "05377305155",
    "bolge": "Gümüşlük",
    "adres": null,
    "sorumlu": "Ozan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Eylem",
    "acildurumtelefon": "5324879605",
    "kangrubu": "B+",
    "cemiyetno": null,
    "sicilno": "7744",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "102",
    "devre": "106",
    "isim": "Ahmet",
    "soyisim": "Selimoğlu",
    "email": "asgsl1566@gmail.com",
    "Telefon": "05333663629",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Emekli",
    "acildurumkisisi": "Ablası Emine",
    "acildurumtelefon": "5322158376",
    "kangrubu": "0+",
    "cemiyetno": "3649",
    "sicilno": "5080",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "103",
    "devre": "101",
    "isim": "Akın",
    "soyisim": "Algan",
    "email": "akinalgan@gmail.com",
    "Telefon": "05389480499",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em.Büyükelçi",
    "acildurumkisisi": "Naci Akıncı",
    "acildurumtelefon": "5383634210",
    "kangrubu": null,
    "cemiyetno": null,
    "sicilno": "6263",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "104",
    "devre": "93",
    "isim": "Ali Engin",
    "soyisim": "Oba",
    "email": "enginoba@hotmail.com",
    "Telefon": "05335203834",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em. Büyükelçi- Prof(aktif)",
    "acildurumkisisi": "Eşi 106 Sibel",
    "acildurumtelefon": "5334430907",
    "kangrubu": "B+",
    "cemiyetno": null,
    "sicilno": "13928",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "105",
    "devre": "107",
    "isim": "Canan",
    "soyisim": "Gürcan",
    "email": "canangur@gmail.com",
    "Telefon": "05326160186",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi İlknur",
    "acildurumtelefon": "5335203834",
    "kangrubu": "A+",
    "cemiyetno": "3102",
    "sicilno": "9438",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "106",
    "devre": "105",
    "isim": "Celal",
    "soyisim": "Gürcan",
    "email": "celalgurcan@gmail.com",
    "Telefon": "05322137574",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Oğlu Can",
    "acildurumtelefon": "5327133970",
    "kangrubu": "A+",
    "cemiyetno": "3103",
    "sicilno": "8396",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "107",
    "devre": "93",
    "isim": "Celasin",
    "soyisim": "Egel",
    "email": "celasin@superonline.com",
    "Telefon": "05322117696",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Y. Mak. Müh.",
    "acildurumkisisi": "Oğlu Can",
    "acildurumtelefon": "5327133970",
    "kangrubu": "A+",
    "cemiyetno": null,
    "sicilno": "11894",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "108",
    "devre": "142",
    "isim": "Cem",
    "soyisim": "Kocabasa",
    "email": "cemkocabasa@gmail.com",
    "Telefon": "05375969890",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Filiz",
    "acildurumtelefon": "5322113708",
    "kangrubu": "A+",
    "cemiyetno": "7166",
    "sicilno": "16882",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "109",
    "devre": "101",
    "isim": "Derya",
    "soyisim": "Fidan",
    "email": "derya1950@gmail.com",
    "Telefon": "05323128326",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Merve Kocabasa",
    "acildurumtelefon": "5346265877",
    "kangrubu": "B+",
    "cemiyetno": "5595",
    "sicilno": "13745",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "110",
    "devre": "105",
    "isim": "Eşref",
    "soyisim": "Hamamcıoğlu",
    "email": "esref.hamamcioglu@gmail.com",
    "Telefon": "05322144027",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Mine",
    "acildurumtelefon": "5332423333",
    "kangrubu": "A+",
    "cemiyetno": "2657",
    "sicilno": "7102",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "111",
    "devre": "101",
    "isim": "Fethi",
    "soyisim": "Gürdöl",
    "email": "fethig@sistemamimarlik.com",
    "Telefon": "05322143374",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Nil",
    "acildurumtelefon": "5322637785",
    "kangrubu": "0+",
    "cemiyetno": "5411",
    "sicilno": "10363",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "112",
    "devre": "105",
    "isim": "Halil",
    "soyisim": "Akgül",
    "email": "hakgul1@gmail.com",
    "Telefon": "05336014138",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Yük.Makina. Müh.",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "B+",
    "cemiyetno": "7129",
    "sicilno": "4907",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "113",
    "devre": "98",
    "isim": "İbrahim",
    "soyisim": "Özyürük",
    "email": "İbrahimozyuruk@gmail.com",
    "Telefon": "05325824501",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Selen Akgül",
    "acildurumtelefon": "5324230932",
    "kangrubu": "B+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "114",
    "devre": "94",
    "isim": "Kaya",
    "soyisim": "Tiryakioğlu",
    "email": "kaya.tiryakioglu@yahoo.com",
    "Telefon": "05325115597",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em.Mak.Mühendisi",
    "acildurumkisisi": "Lale Özyürük",
    "acildurumtelefon": "5324158111",
    "kangrubu": "0+",
    "cemiyetno": "2079",
    "sicilno": "3836",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "115",
    "devre": "118",
    "isim": "Kıvılcım",
    "soyisim": "Boztepe",
    "email": "kboztepe@yahoo.com",
    "Telefon": "05327490467",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Onur Tiryakioğlu",
    "acildurumtelefon": "5305106474",
    "kangrubu": "0+",
    "cemiyetno": "2938",
    "sicilno": "7759",
    "dogumgunu": "17 NİSAN",
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "116",
    "devre": "106",
    "isim": "Mehmet",
    "soyisim": "Özkal",
    "email": "mozkal@outlook.fr",
    "Telefon": "05342343692",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Kayınbirader Zafer",
    "acildurumtelefon": "5327415146",
    "kangrubu": "B+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "117",
    "devre": "105",
    "isim": "Mehmet Avni",
    "soyisim": "Aksu",
    "email": "maksu434@gmail.com",
    "Telefon": "05323211274",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em. Müh./Rehber/Acentacı",
    "acildurumkisisi": "Eşi Selma",
    "acildurumtelefon": "5394866692",
    "kangrubu": "A+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "118",
    "devre": "98",
    "isim": "Naci",
    "soyisim": "Akıncı",
    "email": "akincinaci@yahoo.com",
    "Telefon": "05383634210",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em. Büyükelçi",
    "acildurumkisisi": "Eşi Nilgün",
    "acildurumtelefon": "5324643235",
    "kangrubu": "A+",
    "cemiyetno": null,
    "sicilno": "10019",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "119",
    "devre": "106",
    "isim": "Nihat",
    "soyisim": "Karaköse",
    "email": "nihat.karakose@sonatdanismanlik.com",
    "Telefon": "05322852274",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Sonat Sigorta ve risk danış. YKÜ",
    "acildurumkisisi": "Zeynep Akıncı",
    "acildurumtelefon": "5394729555",
    "kangrubu": "B+",
    "cemiyetno": "3058",
    "sicilno": "4983",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "120",
    "devre": "116",
    "isim": "Pınar",
    "soyisim": "Özgen Elaldı",
    "email": "pinar.ozgen@hotmail.com",
    "Telefon": "05323182575",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Sonat",
    "acildurumtelefon": "5322914729",
    "kangrubu": "A+",
    "cemiyetno": "3019",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "121",
    "devre": "106",
    "isim": "Refik",
    "soyisim": "Çeltikci",
    "email": "refikceltikci@gmail.com",
    "Telefon": "05322664096",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Avukat",
    "acildurumkisisi": "Eşi Oktay",
    "acildurumtelefon": "5333385202",
    "kangrubu": "A+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
    {
    "key": null,
    "id": "122",
    "devre": "93",
    "isim": "Sencar",
    "soyisim": "Özsoy",
    "email": "sencarozsoy@gmail.com",
    "Telefon": "05339666047",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em. Büyükelçi",
    "acildurumkisisi": "Eşi Kumre Çeltikci",
    "acildurumtelefon": "5322968034",
    "kangrubu": null,
    "cemiyetno": "5077",
    "sicilno": "8367",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "123",
    "devre": "93",
    "isim": "Uluç",
    "soyisim": "Özülker",
    "email": "ulucozulker@gmail.com",
    "Telefon": "05383757624",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em. Büyükelçi",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "7373",
    "sicilno": "15729",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "124",
    "devre": "101",
    "isim": "Güven",
    "soyisim": "Andıç",
    "email": "guvenandic@hotmail.com",
    "Telefon": "05358260064",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "B+",
    "cemiyetno": "6072",
    "sicilno": "9509",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "125",
    "devre": "106",
    "isim": "Sibel",
    "soyisim": "Algan",
    "email": "zsalgan@gmail.com",
    "Telefon": "05334430907",
    "bolge": "Gündoğan",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em. Büyükelçi",
    "acildurumkisisi": "Eşi Lale",
    "acildurumtelefon": "5323711464",
    "kangrubu": "AB+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "126",
    "devre": "99",
    "isim": "Ali",
    "soyisim": "Güler",
    "email": "alig48@gmail.com",
    "Telefon": "05322550404",
    "bolge": "Güvercinlik",
    "adres": "Adabükü",
    "sorumlu": "Gonca",
    "isbilgisi": "Em. Ankara SBF mezunu",
    "acildurumkisisi": "Eşi Akın",
    "acildurumtelefon": "5389480499",
    "kangrubu": "B+",
    "cemiyetno": "3094",
    "sicilno": null,
    "dogumgunu": "13 Temmuz",
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "127",
    "devre": "119",
    "isim": "Gonca",
    "soyisim": "Sarış",
    "email": "goncasaris@gmail.com",
    "Telefon": "05326877685",
    "bolge": "Güvercinlik",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": "Eğitimci (Güvercinlik-Adabükü-Meşelik-Mumcular)",
    "acildurumkisisi": "Eşi Tanju Güler",
    "acildurumtelefon": "5326129312",
    "kangrubu": "A+",
    "cemiyetno": "3872",
    "sicilno": "10728",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "128",
    "devre": "106",
    "isim": "Halit",
    "soyisim": "Güven",
    "email": "halit.guven@sim-sistem.com",
    "Telefon": "05322716773",
    "bolge": "Güvercinlik",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": null,
    "acildurumkisisi": "İrem Kasapoğlu",
    "acildurumtelefon": "5323765417",
    "kangrubu": "0+",
    "cemiyetno": "2503",
    "sicilno": "7074",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "129",
    "devre": "104",
    "isim": "Haluk",
    "soyisim": "Öndoğan",
    "email": "haluk.ondogan@gmail.com",
    "Telefon": "05326125029",
    "bolge": "Güvercinlik",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": "Bilgi Teknolojileri Denetim Danışman",
    "acildurumkisisi": "Çiğdem Güven (112)",
    "acildurumtelefon": "5322716773",
    "kangrubu": "A+",
    "cemiyetno": "2794",
    "sicilno": "8634",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "130",
    "devre": "106",
    "isim": "Hülya",
    "soyisim": "Uğur Tanrıöver",
    "email": "hulyatanriover@gmail.com",
    "Telefon": "05324864271",
    "bolge": "Güvercinlik",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": "Sosyolog, akademisyen",
    "acildurumkisisi": "Eşi Füsun",
    "acildurumtelefon": "5336487702",
    "kangrubu": "0-",
    "cemiyetno": "5135",
    "sicilno": "12191",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
   {
    "key": null,
    "id": "131",
    "devre": "105",
    "isim": "Kemal",
    "soyisim": "Ergezen",
    "email": "kemal.ergezen@gmail.com",
    "Telefon": "05325017738",
    "bolge": "Güvercinlik",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": "Elektrik Müh. (İTÜ)",
    "acildurumkisisi": "Eşi Ömer",
    "acildurumtelefon": "5352136811",
    "kangrubu": "0+",
    "cemiyetno": "3035",
    "sicilno": "10078",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "132",
    "devre": "99",
    "isim": "Mehmet",
    "soyisim": "Kozikoğlu",
    "email": "mehmetkozikoglu@gmail.com",
    "Telefon": "05322313718",
    "bolge": "Güvercinlik",
    "adres": "Adabükü",
    "sorumlu": "Gonca",
    "isbilgisi": "Em. Kimya Yük. Müh.",
    "acildurumkisisi": "Eşi Gülten",
    "acildurumtelefon": "5327919297",
    "kangrubu": "A-",
    "cemiyetno": "3097",
    "sicilno": "4312",
    "dogumgunu": "28 Eylül",
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "133",
    "devre": "106",
    "isim": "Murat",
    "soyisim": "Altay",
    "email": "mmurataltay@yahoo.com",
    "Telefon": "05333566632",
    "bolge": "Güvercinlik",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": "İnşaat Müh.",
    "acildurumkisisi": "Ali Güler",
    "acildurumtelefon": "5322550404",
    "kangrubu": "0-",
    "cemiyetno": "4351",
    "sicilno": "10120",
    "dogumgunu": "1O MAYIS",
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "134",
    "devre": "107",
    "isim": "Serdar",
    "soyisim": "Abraz",
    "email": "serdarabraz@gmail.com",
    "Telefon": "05332902288",
    "bolge": "Güvercinlik",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": "Elektronik Y. Müh.",
    "acildurumkisisi": "Eşi Nimet",
    "acildurumtelefon": "5324733060",
    "kangrubu": "B+",
    "cemiyetno": "4639",
    "sicilno": "11394",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "135",
    "devre": "110",
    "isim": "Suna",
    "soyisim": "Kartal",
    "email": "suniska2001@yahoo.com",
    "Telefon": "05334264182",
    "bolge": "Güvercinlik",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": null,
    "acildurumkisisi": "Esin Abraz",
    "acildurumtelefon": "5322533929",
    "kangrubu": "A+",
    "cemiyetno": "7573",
    "sicilno": null,
    "dogumgunu": "5 AĞUSTOS",
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "136",
    "devre": "119",
    "isim": "Barış",
    "soyisim": "Atay",
    "email": "baris.atay@gmail.com",
    "Telefon": "05322833727",
    "bolge": "İstanbul",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Turist Rehberi - Gayrimenkul Değerleme Uzmanı",
    "acildurumkisisi": "Eşi Mehmet",
    "acildurumtelefon": "5452435839",
    "kangrubu": "0+",
    "cemiyetno": "3510",
    "sicilno": "9192",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "137",
    "devre": "97",
    "isim": "Sedat",
    "soyisim": "Alioğlu",
    "email": "alioglu@alioglu-as.com",
    "Telefon": "05322127325",
    "bolge": "İstanbul",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": null,
    "cemiyetno": "2775",
    "sicilno": "4412",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "138",
    "devre": "107",
    "isim": "Bülent",
    "soyisim": "Keskin",
    "email": "bulkeskin@gmail.com",
    "Telefon": "05077199334",
    "bolge": "İzmir",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "2655",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "139",
    "devre": "123",
    "isim": "Berk",
    "soyisim": "Kutengin",
    "email": "berk.kutengin@bnktextile.com",
    "Telefon": "05524624107",
    "bolge": "Kadıkalesi",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Tekstil-ihracat",
    "acildurumkisisi": "Eşi 115 Ufuk",
    "acildurumtelefon": "5332121955",
    "kangrubu": "0-",
    "cemiyetno": "5245",
    "sicilno": "10103",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "140",
    "devre": "111",
    "isim": "Dilek",
    "soyisim": "Anadol",
    "email": "dilek_165@yahoo.fr",
    "Telefon": "05336070020",
    "bolge": "Kadıkalesi",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "GSÜ Em. Gn. Sek.",
    "acildurumkisisi": "İrem Kutengin",
    "acildurumtelefon": "5309616773",
    "kangrubu": "0+",
    "cemiyetno": "1706",
    "sicilno": "8310",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "141",
    "devre": "133",
    "isim": "Güneş",
    "soyisim": "Çıgay",
    "email": "gunescigay@gmail.com",
    "Telefon": "05385585187",
    "bolge": "Kadıkalesi",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Çevirmen/Kitap-Dergi Editörü",
    "acildurumkisisi": "Eşi Macit Umul",
    "acildurumtelefon": "5322442123",
    "kangrubu": "A+",
    "cemiyetno": "6345",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "142",
    "devre": "107",
    "isim": "Macit",
    "soyisim": "Umul",
    "email": "umulm@hotmail.com",
    "Telefon": "05322442123",
    "bolge": "Kadıkalesi",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Doktor KBB",
    "acildurumkisisi": "Alev Çıgay",
    "acildurumtelefon": "5323470555",
    "kangrubu": "0-",
    "cemiyetno": "4087",
    "sicilno": "8772",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "143",
    "devre": "101",
    "isim": "Ufuk",
    "soyisim": "Doğrusöz",
    "email": "ufuk.dogrusoz@gmail.com",
    "Telefon": "05313655412",
    "bolge": "Kadıkalesi",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Prof. Mimar",
    "acildurumkisisi": "Eşi 111 Dilek",
    "acildurumtelefon": "5336070020",
    "kangrubu": null,
    "cemiyetno": "1312",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "144",
    "devre": "129",
    "isim": "Ahu",
    "soyisim": "Sökmenoğlu",
    "email": "ahusokmenoglu@gmail.com",
    "Telefon": "05323420885",
    "bolge": "Kızılağaç",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Mimar/Akademisyen",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "6484",
    "sicilno": "11092",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "145",
    "devre": "100",
    "isim": "Bilguday",
    "soyisim": "Günday",
    "email": "bilgudaygunday@superonline.com",
    "Telefon": "05326826282",
    "bolge": "Konacık",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Em. Albay",
    "acildurumkisisi": "Eşi Ali Sohtorik",
    "acildurumtelefon": "5322832063",
    "kangrubu": null,
    "cemiyetno": "3979",
    "sicilno": "8193",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "146",
    "devre": "99",
    "isim": "Bülent",
    "soyisim": "Pozam",
    "email": "tempodis@superonline.com",
    "Telefon": "05332674363",
    "bolge": "Konacık",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Avukat",
    "acildurumkisisi": "Eşi Meral",
    "acildurumtelefon": "5323213042",
    "kangrubu": "B+",
    "cemiyetno": "4113",
    "sicilno": "7078",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "147",
    "devre": "106",
    "isim": "Can",
    "soyisim": "Değirmenciler",
    "email": "c.degir@gmail.com",
    "Telefon": "05362030229",
    "bolge": "Konacık",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": null,
    "acildurumkisisi": "Oğlu Tolga",
    "acildurumtelefon": "5399367118",
    "kangrubu": "A+",
    "cemiyetno": "6585",
    "sicilno": "13866",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "148",
    "devre": "105",
    "isim": "Cem",
    "soyisim": "Kartal",
    "email": "cemkartal@gmail.com",
    "Telefon": "05323122498",
    "bolge": "Konacık",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Tümay",
    "acildurumtelefon": "5324783504",
    "kangrubu": "A+",
    "cemiyetno": "5069",
    "sicilno": "9668",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "149",
    "devre": "123",
    "isim": "Güray",
    "soyisim": "Ertok",
    "email": "gertok@hotmail.com",
    "Telefon": "05323352218",
    "bolge": "Konacık",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Mimar",
    "acildurumkisisi": "Eşi Hale",
    "acildurumtelefon": "5322762912",
    "kangrubu": "0+",
    "cemiyetno": "4911",
    "sicilno": "9190",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "150",
    "devre": "131",
    "isim": "Harun",
    "soyisim": "Karadeniz",
    "email": "harun@attachetours.com",
    "Telefon": "05065486982",
    "bolge": "Konacık",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi",
    "acildurumtelefon": "5326437426",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "151",
    "devre": "127",
    "isim": "Mehmet",
    "soyisim": "Sinan Akad",
    "email": "mesiak@gmail.com",
    "Telefon": "05323742054",
    "bolge": "Konacık",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": "Konacık Moderatörü",
    "acildurumkisisi": "Eşi Seda",
    "acildurumtelefon": "05335646472",
    "kangrubu": null,
    "cemiyetno": "8051",
    "sicilno": "16020",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "152",
    "devre": "101",
    "isim": "Savaş",
    "soyisim": "Güleç",
    "email": "gssavas@gmail.com",
    "Telefon": "05426521285",
    "bolge": "Konacık",
    "adres": null,
    "sorumlu": "Sinan",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "153",
    "devre": "116",
    "isim": "Canel",
    "soyisim": "Karakaş",
    "email": "canelkarakas@gmail.com",
    "Telefon": "05322923059",
    "bolge": "Koyunbaba",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Tekstilci",
    "acildurumkisisi": "Senem Güleç",
    "acildurumtelefon": "5333550809",
    "kangrubu": "0+",
    "cemiyetno": "2870",
    "sicilno": "7816",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "154",
    "devre": "109",
    "isim": "Ercan",
    "soyisim": "Ekin",
    "email": "ercanekin2000@yahoo.com",
    "Telefon": "05336184908",
    "bolge": "Koyunbaba",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Em. Fotoğrafçı",
    "acildurumkisisi": "Eşi 115 Fadim",
    "acildurumtelefon": "5326464692",
    "kangrubu": "B+",
    "cemiyetno": "3214",
    "sicilno": "5341",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "155",
    "devre": "95",
    "isim": "Ertan",
    "soyisim": "Tuncel",
    "email": "ertantuncelgs@hotmail.com",
    "Telefon": "05398829728",
    "bolge": "Koyunbaba",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Ekonomist, em. Diş Teknisyeni,Yeminli Tercüman",
    "acildurumkisisi": "Fatoş Ekin",
    "acildurumtelefon": "5324552605",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": "6928",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "156",
    "devre": "115",
    "isim": "Fadim",
    "soyisim": "Karakaş",
    "email": "fadimkarakas@gmail.com",
    "Telefon": "05326464692",
    "bolge": "Koyunbaba",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Em. Öğretmen",
    "acildurumkisisi": "Leyla Tuncel",
    "acildurumtelefon": "5322313662",
    "kangrubu": "A+",
    "cemiyetno": "2974",
    "sicilno": "7817",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "157",
    "devre": "109",
    "isim": "Bülent",
    "soyisim": "Esen",
    "email": "bulentesen709@gmail.com",
    "Telefon": "05323419886",
    "bolge": "Kumbahçe",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi 116 Canel",
    "acildurumtelefon": "5322923059",
    "kangrubu": "B+",
    "cemiyetno": "1465",
    "sicilno": "8159",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "158",
    "devre": "94",
    "isim": "Ergun",
    "soyisim": "Yığın",
    "email": "ergunygn@yahoo.com",
    "Telefon": "05422857538",
    "bolge": "Kumbahçe",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": "Transmer Enerji Madencilik (güneş enerjisi)",
    "acildurumkisisi": "Eşi Aynur Esen",
    "acildurumtelefon": "5324718595",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "159",
    "devre": "133",
    "isim": "Gökçe",
    "soyisim": "Devecioğlu",
    "email": "gokcedevecioglu@gmail.com",
    "Telefon": "0535456982",
    "bolge": "Kumbahçe",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": "Reklam Yazarı",
    "acildurumkisisi": "Mete Yığın",
    "acildurumtelefon": "5322151548",
    "kangrubu": "A-",
    "cemiyetno": "6143",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "160",
    "devre": "115",
    "isim": "İzzeddin",
    "soyisim": "Çalışlar",
    "email": "izzeddin@me.com",
    "Telefon": "05323446581",
    "bolge": "Kumbahçe",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Kıvanç Gümüş",
    "acildurumtelefon": "5324551765",
    "kangrubu": "A+",
    "cemiyetno": "2148",
    "sicilno": "9175",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "161",
    "devre": "101",
    "isim": "Murat",
    "soyisim": "Sağlav",
    "email": "msaglav@gmail.com",
    "Telefon": "05366030822",
    "bolge": "Kumbahçe",
    "adres": null,
    "sorumlu": "Öncü",
    "isbilgisi": null,
    "acildurumkisisi": "Kardeşi Hasan",
    "acildurumtelefon": "5337127784",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": "10527",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "162",
    "devre": "101",
    "isim": "Nadir",
    "soyisim": "Ilıca",
    "email": "ilicanadir@yahoo.com",
    "Telefon": "05523424742",
    "bolge": "Küçükbük",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A-",
    "cemiyetno": "1310",
    "sicilno": "8107",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "163",
    "devre": "101",
    "isim": "Salih Ercüment",
    "soyisim": "Türktan",
    "email": "eturktan@yahoo.com",
    "Telefon": "05322221134",
    "bolge": "Küçükbük",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Nimet",
    "acildurumtelefon": "5323424742",
    "kangrubu": "B+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "164",
    "devre": "133",
    "isim": "Eran",
    "soyisim": "Hakim",
    "email": "hakimeran@yahoo.com",
    "Telefon": "05352194927",
    "bolge": "Mazı",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": "Yönetmen/Fotoğrafçı",
    "acildurumkisisi": "Eşi Gülden",
    "acildurumtelefon": "5324414321",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": "1 MAYIS",
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "165",
    "devre": "104",
    "isim": "Vedat",
    "soyisim": "Akan",
    "email": "vedatakan@yahoo.fr",
    "Telefon": "05054125211",
    "bolge": "Meşelik",
    "adres": "Adabükü",
    "sorumlu": "Gonca",
    "isbilgisi": "Yüksek Mimar  ,ODTÜ , Emekli",
    "acildurumkisisi": "Gizem Hakim",
    "acildurumtelefon": "5354715646",
    "kangrubu": "0+",
    "cemiyetno": "5066",
    "sicilno": "4740",
    "dogumgunu": "29 EKİM",
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "166",
    "devre": "98",
    "isim": "Haluk",
    "soyisim": "İşmen",
    "email": "mhi8150@gmail.com",
    "Telefon": "05322111198",
    "bolge": "Mumcular",
    "adres": null,
    "sorumlu": "Gonca",
    "isbilgisi": "Y.İnş.Müh.",
    "acildurumkisisi": "Nilgün Akan",
    "acildurumtelefon": "5424145461",
    "kangrubu": "A+",
    "cemiyetno": "1250",
    "sicilno": "10365",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "167",
    "devre": "113",
    "isim": "Gökalp",
    "soyisim": "Özdikicioğlu",
    "email": "gokalp@lakerida.com",
    "Telefon": "05322810656",
    "bolge": "Ortakent",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": "Turizmci",
    "acildurumkisisi": "Eşi Selva",
    "acildurumtelefon": "5333527670",
    "kangrubu": "A-",
    "cemiyetno": "1852",
    "sicilno": "6498",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "168",
    "devre": "125",
    "isim": "Günberi",
    "soyisim": "Tuncer",
    "email": "gunberituncer@gmail.com",
    "Telefon": "05322048594",
    "bolge": "Ortakent",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": "İletişim/PR",
    "acildurumkisisi": "Teoman Okyaltirik",
    "acildurumtelefon": "5422963788",
    "kangrubu": "A+",
    "cemiyetno": "6811",
    "sicilno": "14756",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "169",
    "devre": "121",
    "isim": "Mehmet",
    "soyisim": "Üstün",
    "email": "mehmet.unikon@gmail.com",
    "Telefon": "05366841280",
    "bolge": "Ortakent",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Şevket Dal",
    "acildurumtelefon": "5322141284",
    "kangrubu": "A+",
    "cemiyetno": "5626",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "170",
    "devre": "121",
    "isim": "Murat",
    "soyisim": "Kılıç",
    "email": "muratkilic@impa.com.tr",
    "Telefon": "05326145244",
    "bolge": "Ortakent",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": null,
    "acildurumkisisi": "Kızı Melis",
    "acildurumtelefon": "5395455500",
    "kangrubu": "B+",
    "cemiyetno": "4136",
    "sicilno": "9342",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "171",
    "devre": "94",
    "isim": "Oktay",
    "soyisim": "Orhon",
    "email": "oktayorhon@gmail.com",
    "Telefon": "05322454941",
    "bolge": "Ortakent",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": "Y. Müh. Mimar",
    "acildurumkisisi": "Eşi Sinem",
    "acildurumtelefon": "5322617108",
    "kangrubu": "A+",
    "cemiyetno": "2612",
    "sicilno": "8391",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "172",
    "devre": "131",
    "isim": "Yasemin",
    "soyisim": "Arşiray Tezuçan",
    "email": "ytezucan@gmail.com",
    "Telefon": "05306419392",
    "bolge": "Ortakent",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": "Vergi Danışmanı/Denetçi Ortakent-Yahşi moderatörü",
    "acildurumkisisi": "Eşi İmre",
    "acildurumtelefon": "5323714530",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": "14952",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "173",
    "devre": "119",
    "isim": "Abdullah",
    "soyisim": "Tüzün",
    "email": "abdullahtuzun1@hotmail.com",
    "Telefon": "05322618045",
    "bolge": "Torba",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Kuyumcu (Gümüş) + rehber",
    "acildurumkisisi": "Ekin Arşiray",
    "acildurumtelefon": "5335429739",
    "kangrubu": "B+",
    "cemiyetno": "3509",
    "sicilno": "16436",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "174",
    "devre": "108",
    "isim": "Can",
    "soyisim": "Başar",
    "email": "cnbasar@yahoo.com",
    "Telefon": "05322121610",
    "bolge": "Torba",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Sigortacı (Kızılağaç-Torba-Gölköy-Türkbükü-Gündoğanmoderatörü)",
    "acildurumkisisi": "Eylül Naz Tüzün",
    "acildurumtelefon": "5342025606",
    "kangrubu": "A+",
    "cemiyetno": "1337",
    "sicilno": "7666",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "175",
    "devre": "94",
    "isim": "Haluk",
    "soyisim": "Ogan",
    "email": "oganh@ymail.com",
    "Telefon": "05378597202",
    "bolge": "Torba",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Gaye",
    "acildurumtelefon": "5322243878",
    "kangrubu": "A+",
    "cemiyetno": "5528",
    "sicilno": "11742",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "176",
    "devre": "110",
    "isim": "Hasan",
    "soyisim": "Ormanlar",
    "email": "hasan@griptr.com",
    "Telefon": "05322616009",
    "bolge": "Torba",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "3674",
    "sicilno": "16965",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "177",
    "devre": "110",
    "isim": "Nilüfer",
    "soyisim": "Akdoğanlar",
    "email": "nilufer_akdoganlar@yahoo.fr",
    "Telefon": "05327646510",
    "bolge": "Torba",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Pınar Göktürk",
    "acildurumtelefon": "5326528939",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "178",
    "devre": "115",
    "isim": "Can",
    "soyisim": "Şakarcan",
    "email": "can.sakarcan@gmail.com",
    "Telefon": "05356694982",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Fikri",
    "acildurumtelefon": "5415719696",
    "kangrubu": "A+",
    "cemiyetno": "2044",
    "sicilno": "9205",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "179",
    "devre": "101",
    "isim": "Erdal",
    "soyisim": "Ateş",
    "email": "erdalates1471@gmail.com",
    "Telefon": "05322772433",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Suat Şakarcan",
    "acildurumtelefon": "533763644",
    "kangrubu": "AB+",
    "cemiyetno": "3250",
    "sicilno": "8639",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "180",
    "devre": "100",
    "isim": "Erdoğan",
    "soyisim": "Şaylı",
    "email": "erdogansayli@yahoo.com",
    "Telefon": "05324348108",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Em. Otel md+market işl.+emlakçı",
    "acildurumkisisi": "Eşi Canan",
    "acildurumtelefon": "5063237871",
    "kangrubu": "B-",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "181",
    "devre": "124",
    "isim": "Ersoy",
    "soyisim": "Cankulak",
    "email": "ersoycankulak@gmail.com",
    "Telefon": "05550001905",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Filiz",
    "acildurumtelefon": "5374621710",
    "kangrubu": "B+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "182",
    "devre": "126",
    "isim": "Gökhan",
    "soyisim": "Yarış",
    "email": "gokhanyaris@gmail.com",
    "Telefon": "05387957971",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Annesi Sevgi",
    "acildurumtelefon": "535932111",
    "kangrubu": "A-",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "183",
    "devre": "116",
    "isim": "Gülmin",
    "soyisim": "Şarman",
    "email": "gulmin.s@conseptur.com",
    "Telefon": "05324473304",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Toplantı, kongre, turizm",
    "acildurumkisisi": "Günüm 124 Yarış",
    "acildurumtelefon": "5336313646",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": "12651",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "184",
    "devre": "91",
    "isim": "Gülseven",
    "soyisim": "Sonumut",
    "email": "sonumutgul@hotmail.com",
    "Telefon": "05422146712",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Tevfik",
    "acildurumtelefon": "5323366300",
    "kangrubu": "A+",
    "cemiyetno": "5225",
    "sicilno": "3694",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "185",
    "devre": "101",
    "isim": "Gündüz",
    "soyisim": "Uğural",
    "email": "gunduz.ugural@gmail.com",
    "Telefon": "05326161541",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Em. Mak. Müh.",
    "acildurumkisisi": "Oğlu Güldener",
    "acildurumtelefon": "32475453994",
    "kangrubu": "A+",
    "cemiyetno": "1253",
    "sicilno": "7103",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "186",
    "devre": "113",
    "isim": "Güniz",
    "soyisim": "Bursalı",
    "email": "gunizbursali@yahoo.com.tr",
    "Telefon": "05333661073",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Oğlu Yavuz",
    "acildurumtelefon": "5366368085",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "187",
    "devre": "124",
    "isim": "Günüm",
    "soyisim": "Gürsoylar",
    "email": "gunumg@gmail.com",
    "Telefon": "05336313646",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Turgutreis-Kadıkalesi moderatörü",
    "acildurumkisisi": "Eşi 112 Yüksel Bursalı",
    "acildurumtelefon": "5326534554",
    "kangrubu": "AB+",
    "cemiyetno": "6559",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "188",
    "devre": "111",
    "isim": "Hümeyra",
    "soyisim": "Durgut Kurmuş",
    "email": "humeyrakurmus@hotmail.com",
    "Telefon": "05423132354",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Gökhan Yarış",
    "acildurumtelefon": "5387957971",
    "kangrubu": "A-",
    "cemiyetno": "6808",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "189",
    "devre": "142",
    "isim": "Kaan",
    "soyisim": "Artukoğlu",
    "email": "kaan-142@hotmail.com",
    "Telefon": "05364158315",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Acıbadem Tıp Merkezi İş Geliştirme Sorumlusu",
    "acildurumkisisi": "Uğur Kurmuş",
    "acildurumtelefon": "5323226015",
    "kangrubu": "A+",
    "cemiyetno": "7150",
    "sicilno": "15181",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "190",
    "devre": "111",
    "isim": "Murat",
    "soyisim": "Doral",
    "email": "md30144@yahoo.com",
    "Telefon": "05059831296",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Handan Yıldız",
    "acildurumtelefon": "5426205042",
    "kangrubu": "A+",
    "cemiyetno": "4329",
    "sicilno": "5547",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "191",
    "devre": "101",
    "isim": "Mustafa",
    "soyisim": "Madenli",
    "email": "mmadenli@gmail.com",
    "Telefon": "05325727266",
    "bolge": "Turgutreis",
    "adres": "Karabağ",
    "sorumlu": "Günüm",
    "isbilgisi": "Scuba eğ.ant.+yacht kap.",
    "acildurumkisisi": "Sibel Doral",
    "acildurumtelefon": "5077488830",
    "kangrubu": "0+",
    "cemiyetno": "4588",
    "sicilno": "7154",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "192",
    "devre": "100",
    "isim": "Nejat",
    "soyisim": "Bengil",
    "email": "nejat.bengil@gmail.com",
    "Telefon": "05422344855",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "İnşaat Taahhüt İşleri",
    "acildurumkisisi": "Serap",
    "acildurumtelefon": "5336005886",
    "kangrubu": "0-",
    "cemiyetno": "3917",
    "sicilno": "9108",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "193",
    "devre": "119",
    "isim": "Nermin",
    "soyisim": "Cıbıroğlu",
    "email": "nermincelikoglu@yahoo.com",
    "Telefon": "05322131924",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Oğlu Boysan",
    "acildurumtelefon": "5333935363",
    "kangrubu": "A+",
    "cemiyetno": "5923",
    "sicilno": "14765",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "194",
    "devre": "124",
    "isim": "Selin",
    "soyisim": "Karabeyoğlu",
    "email": "selinkarabey.skl@gmail.com",
    "Telefon": "05432021216",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Ev hanımı",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "5804",
    "sicilno": "10029",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "195",
    "devre": "107",
    "isim": "Tevfik",
    "soyisim": "Şarman",
    "email": "tevfiksarman@gmail.com",
    "Telefon": "05323366300",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Toplantı, kongre, turizm",
    "acildurumkisisi": "122 Cem Göçmen",
    "acildurumtelefon": "5335006795",
    "kangrubu": "B+",
    "cemiyetno": "2361",
    "sicilno": "7743",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "196",
    "devre": "107",
    "isim": "Tufan",
    "soyisim": "Kaleli",
    "email": "kaleli107@yahoo.com",
    "Telefon": "05322941898",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi 116 Gülmin",
    "acildurumtelefon": "5324473304",
    "kangrubu": "0+",
    "cemiyetno": "1469",
    "sicilno": "7723",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "197",
    "devre": "102",
    "isim": "Uğur",
    "soyisim": "Arıner",
    "email": "uariner@gmail.com",
    "Telefon": "05389859194",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Kızı Irmak",
    "acildurumtelefon": "5335788291",
    "kangrubu": "A+",
    "cemiyetno": "8113",
    "sicilno": "13847",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "198",
    "devre": "121",
    "isim": "Cem",
    "soyisim": "Göçmen",
    "email": "cem.cemgocmen@gmail.com",
    "Telefon": "05335006795",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": "Temcev Saha Md.",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "B+",
    "cemiyetno": "4586",
    "sicilno": "9486",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "199",
    "devre": "123",
    "isim": "Ebru",
    "soyisim": "Şahbaz",
    "email": "ebsahbaz@gmail.com",
    "Telefon": "05323711541",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "124 Selin Karabeyoğlu",
    "acildurumtelefon": "5432021216",
    "kangrubu": "0+",
    "cemiyetno": "4846",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "200",
    "devre": "126",
    "isim": "Hüseyin",
    "soyisim": "Ceylan",
    "email": "ceylanhus@gmail.com",
    "Telefon": "05320605301",
    "bolge": "Turgutreis",
    "adres": null,
    "sorumlu": "Günüm",
    "isbilgisi": null,
    "acildurumkisisi": "Akın Şahbaz",
    "acildurumtelefon": "5322236234",
    "kangrubu": "0+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "201",
    "devre": "99",
    "isim": "Ali",
    "soyisim": "Akınal",
    "email": "alibakinal@gmail.com",
    "Telefon": "05322648783",
    "bolge": "Türkbükü",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Annesi Sema",
    "acildurumtelefon": "5324821308",
    "kangrubu": "A+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "202",
    "devre": "98",
    "isim": "Cemal",
    "soyisim": "Burnaz",
    "email": "cemalburnaz@hotmail.com",
    "Telefon": "05334483064",
    "bolge": "Türkbükü",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Burcu Abacı",
    "acildurumtelefon": "5324351618",
    "kangrubu": "A+",
    "cemiyetno": "1611",
    "sicilno": "4052",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "203",
    "devre": "93",
    "isim": "Mustafa",
    "soyisim": "Göksel",
    "email": "gokselm@hotmail.com",
    "Telefon": "05359764422",
    "bolge": "Türkbükü",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "Em. Yük. Elektrik Müh.",
    "acildurumkisisi": "Eşi Semra",
    "acildurumtelefon": "5333916135",
    "kangrubu": "A+",
    "cemiyetno": "3186",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "204",
    "devre": "109",
    "isim": "Rıza",
    "soyisim": "Erdeğirmenci",
    "email": "rizaerd@gmail.com",
    "Telefon": "05323243723",
    "bolge": "Türkbükü",
    "adres": null,
    "sorumlu": "Can",
    "isbilgisi": "İktisatçı",
    "acildurumkisisi": "Eşi Sandy",
    "acildurumtelefon": "5305677422",
    "kangrubu": "A+",
    "cemiyetno": "1349",
    "sicilno": "7246",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "205",
    "devre": "130",
    "isim": "Elif",
    "soyisim": "Akay",
    "email": "elifmi@yahoo.com",
    "Telefon": "05323051071",
    "bolge": "Yahşi",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "206",
    "devre": "123",
    "isim": "Fidel",
    "soyisim": "Berber",
    "email": "fidel@b-b.com.tr",
    "Telefon": "05322437609",
    "bolge": "Yahşi",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Mithat Çiftçioğlu +6592364506",
    "acildurumtelefon": "+6592364506",
    "kangrubu": "B+",
    "cemiyetno": "5370",
    "sicilno": "11426",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "207",
    "devre": "94",
    "isim": "Mehmet Candan",
    "soyisim": "Akad",
    "email": "mcakad@gmail.com",
    "Telefon": "0532 277 82 53",
    "bolge": "Yahşi",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": "Öğretim görevlisi Kadir Has Üni. Hukuk prof.",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "3835",
    "sicilno": "4598",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "208",
    "devre": "106",
    "isim": "Ömer",
    "soyisim": "Kızıltan",
    "email": "yesom60@gmail.com",
    "Telefon": "05324343080",
    "bolge": "Yahşi",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": null,
    "acildurumkisisi": "Oğlu Sinan",
    "acildurumtelefon": "5323742054",
    "kangrubu": "A-",
    "cemiyetno": "7582",
    "sicilno": "6887",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "209",
    "devre": "92",
    "isim": "Uğur",
    "soyisim": "Gürtunca",
    "email": "ugurtunca@msn.com",
    "Telefon": "05322835015",
    "bolge": "Yahşi",
    "adres": null,
    "sorumlu": "YaseminT",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Yeşim",
    "acildurumtelefon": "5324438449",
    "kangrubu": "B+",
    "cemiyetno": "4601",
    "sicilno": "8876",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "210",
    "devre": "109",
    "isim": "Mehmet",
    "soyisim": "Vardar",
    "email": "mvmehmetvardar@hotmail.com",
    "Telefon": "05326389492",
    "bolge": "Yakaköy",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "0+",
    "cemiyetno": "1457",
    "sicilno": "6752",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "211",
    "devre": "113",
    "isim": "Nimet",
    "soyisim": "Yardımcı",
    "email": "nimetyardimci@gmail.com",
    "Telefon": "05424232855",
    "bolge": "Yakaköy",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "108 Gülseren Kırdemir",
    "acildurumtelefon": "5326389491",
    "kangrubu": "AB+",
    "cemiyetno": "3995",
    "sicilno": "9700",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "212",
    "devre": "110",
    "isim": "Ahmet",
    "soyisim": "Vaner",
    "email": "avaner@gmail.com",
    "Telefon": "05327712526",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "B+",
    "cemiyetno": "5482",
    "sicilno": "11022",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "213",
    "devre": "92",
    "isim": "Ahmet",
    "soyisim": "Korulsan",
    "email": "kkorulsan@gmail.com",
    "Telefon": "05322643788",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Em. Gazeteci",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "0+",
    "cemiyetno": "1209",
    "sicilno": "11089",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "214",
    "devre": "112",
    "isim": "Ali",
    "soyisim": "Oğuz",
    "email": "dr.alioguz@gmail.com",
    "Telefon": "05322323569",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "KBB",
    "acildurumkisisi": "Semra Korulsan",
    "acildurumtelefon": "5336951828",
    "kangrubu": "B+",
    "cemiyetno": "2347",
    "sicilno": "7780",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "215",
    "devre": "113",
    "isim": "Ali",
    "soyisim": "Öğüdücü",
    "email": "ali.oguducu@gmail.com",
    "Telefon": "05333792324",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Girişimci",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "0+",
    "cemiyetno": "2144",
    "sicilno": "6770",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "216",
    "devre": "115",
    "isim": "Banu",
    "soyisim": "Tuğsavul",
    "email": "banu.tugsavul@hotmail.com",
    "Telefon": "05323174770",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "İrem Öğüdücü",
    "acildurumtelefon": "5493807772",
    "kangrubu": "B+",
    "cemiyetno": "2001",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "217",
    "devre": "136",
    "isim": "Barış",
    "soyisim": "Erdem",
    "email": "bariserdem136@gmail.com",
    "Telefon": "05428319652",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Pembegül İnamli",
    "acildurumtelefon": "53364318",
    "kangrubu": null,
    "cemiyetno": "7784",
    "sicilno": "16282",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "218",
    "devre": "108",
    "isim": "Berge",
    "soyisim": "Ulaş",
    "email": "berge@hotellinoistanbul.com",
    "Telefon": "05358508222",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Turizm-Otel İşletmecisi",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": null,
    "cemiyetno": "2642",
    "sicilno": "6835",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "219",
    "devre": "112",
    "isim": "Birgül",
    "soyisim": "Karsan",
    "email": "birgulgur1@hotmail.com",
    "Telefon": "05333985323",
    "bolge": "Yalıkavak",
    "adres": "Dirmil",
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Mine",
    "acildurumtelefon": "5357915519",
    "kangrubu": "AB+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "220",
    "devre": "115",
    "isim": "Burak",
    "soyisim": "Arslan",
    "email": "burakarslan@gmail.com",
    "Telefon": "05324324647",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": null,
    "cemiyetno": "4790",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "221",
    "devre": "93",
    "isim": "Burhan",
    "soyisim": "Ramazanoğulları",
    "email": "burhanramazanogullari@gmail.com",
    "Telefon": "05322111836",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Y. Müh. Mimar",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "4254",
    "sicilno": "12631",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "222",
    "devre": "114",
    "isim": "Burhan",
    "soyisim": "Ozan",
    "email": "Burhan.Ozan@dnvgl.com",
    "Telefon": "05307160238",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Gemi Müh.",
    "acildurumkisisi": "Eşi Bilge",
    "acildurumtelefon": "5326510203",
    "kangrubu": "0+",
    "cemiyetno": "1130",
    "sicilno": "14826",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "223",
    "devre": "105",
    "isim": "Bülent",
    "soyisim": "Yalçınkaya",
    "email": "gsctr@yahoo.com",
    "Telefon": "05356610440",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "İşletmeci",
    "acildurumkisisi": "Rengin Ozan",
    "acildurumtelefon": "5337442238",
    "kangrubu": "A+",
    "cemiyetno": "1798",
    "sicilno": "9465",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "224",
    "devre": null,
    "isim": "Ferhan",
    "soyisim": "Şensoy",
    "email": null,
    "Telefon": null,
    "bolge": "Yalıkavak",
    "adres": "Geriş",
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Zühre",
    "acildurumtelefon": "5321545233",
    "kangrubu": null,
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "225",
    "devre": "115",
    "isim": "Hikmet",
    "soyisim": "Eren",
    "email": "hikmeteren@gmail.com",
    "Telefon": "05324660299",
    "bolge": "Yalıkavak",
    "adres": "Geriş",
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "0+",
    "cemiyetno": "2197",
    "sicilno": "7767",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "226",
    "devre": "107",
    "isim": "Mert",
    "soyisim": "Ökter",
    "email": "mertokter@gmail.com",
    "Telefon": "05322438868",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Sistem analist programcı",
    "acildurumkisisi": "Aydın Topdemir",
    "acildurumtelefon": "5322116957",
    "kangrubu": "0+",
    "cemiyetno": "2601",
    "sicilno": "8946",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "227",
    "devre": "94",
    "isim": "Muhittin",
    "soyisim": "Yamaç",
    "email": "mu.yamac43@gmail.com",
    "Telefon": "05326138556",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Em. Elektrik Yük. Müh.",
    "acildurumkisisi": "Hicran Ökter",
    "acildurumtelefon": "5337650348",
    "kangrubu": "A+",
    "cemiyetno": "1623",
    "sicilno": "6927",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "228",
    "devre": "103",
    "isim": "Mustafa",
    "soyisim": "Mennan",
    "email": "mmennan@yahoo.fr",
    "Telefon": "05333165100",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "B+",
    "cemiyetno": "5855",
    "sicilno": "8071",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "229",
    "devre": "121",
    "isim": "Necla",
    "soyisim": "Albayrak",
    "email": "aynadaki@hotmail.com",
    "Telefon": "05322818377",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Zeynep",
    "acildurumtelefon": "5322238015",
    "kangrubu": "A+",
    "cemiyetno": null,
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "230",
    "devre": "120",
    "isim": "Remzi",
    "soyisim": "Sanver",
    "email": "mremzisanver@gmail.com",
    "Telefon": "05305042303",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Öğretim Üyesi",
    "acildurumkisisi": "Volkan Aksu",
    "acildurumtelefon": "5336832292",
    "kangrubu": "B+",
    "cemiyetno": "3808",
    "sicilno": "9160",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "231",
    "devre": "101",
    "isim": "Sait",
    "soyisim": "Köseoğlu",
    "email": "sait.koseoglu@gmail.com",
    "Telefon": "05493328191",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Finans uzmanı -MBA- Danışman",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A-",
    "cemiyetno": "1274",
    "sicilno": "4640",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "232",
    "devre": "95",
    "isim": "Semih",
    "soyisim": "Denker",
    "email": "semihdenker@yahoo.com",
    "Telefon": "05337238377",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Kızı Ayşe Esra",
    "acildurumtelefon": "5353124834",
    "kangrubu": "A+",
    "cemiyetno": "1247",
    "sicilno": "4483",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "233",
    "devre": "110",
    "isim": "Sezai",
    "soyisim": "Başar",
    "email": "sbasar@gmail.com",
    "Telefon": "05322511040",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Özden",
    "acildurumtelefon": "5334588121",
    "kangrubu": "B+",
    "cemiyetno": "1569",
    "sicilno": "6501",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "234",
    "devre": "111",
    "isim": "Taluy",
    "soyisim": "Özdeş",
    "email": "taluy13@gmail.com",
    "Telefon": "05323639692",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Nilgün",
    "acildurumtelefon": "5333533791",
    "kangrubu": "0-",
    "cemiyetno": "4049",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "235",
    "devre": "101",
    "isim": "Tuğrul",
    "soyisim": "Saltukoğlu",
    "email": "turegsal@gmail.com",
    "Telefon": "05322370200",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi İsmail",
    "acildurumtelefon": "5322610760",
    "kangrubu": null,
    "cemiyetno": "2508",
    "sicilno": "8308",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "236",
    "devre": "109",
    "isim": "Tunç",
    "soyisim": "Yalçıntoklu",
    "email": "tunc.yalcintoklu@gmail.com",
    "Telefon": "0033677797484",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Emekli Borsacı",
    "acildurumkisisi": "Regina eşi",
    "acildurumtelefon": "5326976166",
    "kangrubu": "0+",
    "cemiyetno": "1343",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "237",
    "devre": "109",
    "isim": "Volkan",
    "soyisim": "Karsan",
    "email": "karsanv@gmail.com",
    "Telefon": "05322722619",
    "bolge": "Yalıkavak",
    "adres": "Dirmil",
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "1434",
    "sicilno": "9781",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "238",
    "devre": "115",
    "isim": "Yasemin",
    "soyisim": "Chicheportiche",
    "email": "yasemin521@gmail.com",
    "Telefon": "05333469210",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Em.Turizm +Emlak(Yalıkavak-Koyunbaba-Dağbelen-Yakaköy Mod.)",
    "acildurumkisisi": "Eşi 112 Birgül Karsan",
    "acildurumtelefon": "5333985323",
    "kangrubu": "B-",
    "cemiyetno": "4857",
    "sicilno": "12193",
    "dogumgunu": "9 MAYIS",
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "239",
    "devre": "94",
    "isim": "Yavuz",
    "soyisim": "Sipahioğlu",
    "email": "yavsipahi@gmail.com",
    "Telefon": "05325177600",
    "bolge": "Yalıkavak",
    "adres": "Geriş",
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Eşi Andre",
    "acildurumtelefon": "5332097012",
    "kangrubu": "B+",
    "cemiyetno": "3581",
    "sicilno": "8771",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "240",
    "devre": "114",
    "isim": "Alp",
    "soyisim": "Berk",
    "email": "alp.berk@ferromet.com.tr",
    "Telefon": "05322145013",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Mümessillik Dış Tic.",
    "acildurumkisisi": "Kızı Diğde",
    "acildurumtelefon": "5324304395",
    "kangrubu": "0+",
    "cemiyetno": "2362",
    "sicilno": "9719",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "241",
    "devre": "108",
    "isim": "Burak",
    "soyisim": "Ertung",
    "email": "abertung@gmail.com",
    "Telefon": "05332769067",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Funda Yoran",
    "acildurumtelefon": "5386293000",
    "kangrubu": "0-",
    "cemiyetno": "1581",
    "sicilno": "6499",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "242",
    "devre": "116",
    "isim": "Nihal",
    "soyisim": "Özfırat",
    "email": "nihalozfirat@yahoo.com",
    "Telefon": "05336687103",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Psikolog/Çift ve Aile Terapisti",
    "acildurumkisisi": "Ayşe Ertung",
    "acildurumtelefon": "5336602547",
    "kangrubu": "0-",
    "cemiyetno": "2293",
    "sicilno": "7782",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "243",
    "devre": "105",
    "isim": "Ömer",
    "soyisim": "Yazgan",
    "email": "omaryazgan@gmail.com",
    "Telefon": "05362503706",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": null,
    "acildurumkisisi": "Murat Özfırat",
    "acildurumtelefon": "5323210485",
    "kangrubu": "AB+",
    "cemiyetno": "2809",
    "sicilno": null,
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  },
  {
    "key": null,
    "id": "244",
    "devre": "114",
    "isim": "Sevinç",
    "soyisim": "Özbek Dervent",
    "email": "sevincozbek1@yahoo.com",
    "Telefon": "05052780578",
    "bolge": "Yalıkavak",
    "adres": null,
    "sorumlu": "YaseminC",
    "isbilgisi": "Nöropsikolog-öğretim üyesi",
    "acildurumkisisi": null,
    "acildurumtelefon": null,
    "kangrubu": "A+",
    "cemiyetno": "1153",
    "sicilno": "7741",
    "dogumgunu": null,
    "songuncelleyen": "Öncü",
    "created_at": ${createdAt},
    "deleted_at": null
  }
  ]`
);
const sortOption = {};
class fakeData {
  constructor(size) {
    this.size = size || 2000;
    this.datas = [];
    this.sortKey = null;
    this.sortDir = null;
  }
  dataModel(index) {
    return tableData[index];
  }
  getObjectAt(index) {
    if (index < 0 || index > this.size) {
      return undefined;
    }
    if (this.datas[index] === undefined) {
      this.datas[index] = this.dataModel(index);
    }
    return this.datas[index];
  }
  getAll() {
    if (this.datas.length < this.size) {
      for (let i = 0; i < this.size; i++) {
        this.getObjectAt(i);
      }
    }
    return this.datas.slice();
  }

  getSize() {
    return this.size;
  }
  getSortAsc(sortKey) {
    sortOption.sortKey = sortKey;
    sortOption.sortDir = 'ASC';
    return this.datas.sort(this.sort);
  }
  getSortDesc(sortKey) {
    sortOption.sortKey = sortKey;
    sortOption.sortDir = 'DESC';
    return this.datas.sort(this.sort);
  }
  sort(optionA, optionB) {
    const valueA = optionA[sortOption.sortKey].toUpperCase();
    const valueB = optionB[sortOption.sortKey].toUpperCase();
    let sortVal = 0;
    if (valueA > valueB) {
      sortVal = 1;
    }
    if (valueA < valueB) {
      sortVal = -1;
    }
    if (sortVal !== 0 && sortOption.sortDir === 'DESC') {
      return sortVal * -1;
    }
    return sortVal;
  }
}
export default fakeData;
