import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  boats: {},
  modalActive: false,
  boat: {
    key: null,
    id: new Date().getTime(),
    buildYear: "",
    flag: "Türk",
    harbor: "Bodrum",
    type: "Ticari",
    insuranceCompany: "",
    insuranceID: "",
    insuranceStart: null,
    insuranceEnd: null,

    draft: 0,
    width: 0,
    length: 0,
    material: "Ahşap",
    name: "",
    operatedBy: "",
    contactID: null,

    taxAdministration: "",
    taxIdentity: "",
    tcIdentity: "",

    created: new Date().getTime(),
    createdBy: null,
    edited: null,
    editedBy: null,
    isedeleted: null, // soft delete,
    deleted: null,
    deletedBy: null,
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        boats: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        boat: payload.data == null ? initState.boat : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        boat: payload.data,
      };
    default:
      return state;
  }
}
