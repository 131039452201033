import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  wallets: {},
  modalActive: false,
  wallet: {
    key: null,
    id: new Date().getTime(),
    author: "",
    authorID: "",
    station: "",
    timestamp: "",
    cashTl: 0,
    cashEuro: 0,
    cashPound: 0,
    cashDolar: 0,
    cardTl: 0,
    cardEuro: 0,
    cardPound: 0,
    cardDolar: 0,
    restTl: 0,
    restEuro: 0,
    restPound: 0,
    restDolar: 0,
    date: new Date().toLocaleDateString("tr-TR", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
    time: new Date().toLocaleTimeString("tr-TR"),
    created: new Date().getTime(),
    createdBy: null,
    edited: null,
    editedBy: null,
    isDeleted: false, // soft delete,
    deleted: null,
    deletedBy: null,
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wallets: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        wallet: payload.data == null ? initState.wallet : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        wallet: payload.data,
      };
    default:
      return state;
  }
}
