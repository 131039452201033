import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  events: {},
  modalActive: false,
  event: {
    key: null,
    id: new Date().getTime(),
    eventName: null,
    eventStart: null,
    eventEnd: null,
    eventType: null,
    eventArea: null,
    severity: null,
    relatedTo: null,
    note: "",
    information: "",

    created: null,
    createdBy: null,
    edited: null,
    editedBy: null,
    isedeleted: null, // soft delete,
    deleted: null,
    deletedBy: null,
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        events: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        event: payload.data == null ? initState.event : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        event: payload.data,
      };
    default:
      return state;
  }
}
